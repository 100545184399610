import React, { useState, useEffect } from "react";
// import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import "./login.css";
import {
    ValidateInput,
    ValidateEmail,
} from "../../components/helpers/validateInput";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {
    httpPostFormData,
    httpPut,
    httpPatch,
    httpGet,
    httpPost,
    httpDelete,
} from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
export default function Login(props) {
    useEffect(() => {
        handleSubmit();
    }, []);

    const handleSubmit = async (e) => {
        showLoader();

        let res = await httpPost(`auth/user/verify/${props.match.params.id}`);

        if (res) {
            if (res.er) {
                NotificationManager.error(res.er.message);
                return;
            }

            hideLoader();
            NotificationManager.success("Account verified successfully.");
            props.history.push("/");
        }
    };

    return (
        <div className="login-wrap-home">
            <div className="login-wrap-home-input">
                <h1 className="login-header-text">Verifying account</h1>
                <form className="login-form-wrap">
                    <div className="login-col-three-home"></div>
                </form>

                <div className="forgot-password-wrap-home">
                    <Link to="/forgot_password">Can’t log in?</Link>
                    <Link to="/create_account">Don’t have an account?</Link>
                </div>
            </div>
        </div>
    );
}