import { LoginServices } from "../adapters/AuthServices";
import { dispatcher, appState } from "../context/AuthContext";
import ReducerAction from "../context/ReducerAction";
import { setToken, setLocalItem, getLocalItem } from "../components/helpers/authService";
import { NotificationContainer, NotificationManager } from "react-notifications";
import AppHelpers from "./AppHelper";
import { LoaderAction } from "../components/helpers/loader";

let AuthFunctions = {};

AuthFunctions.register = async ({ email, password }, navigation) => {
  try {
  } catch (err) {
    console.log("errr", err?.response?.data);
    appState.loader = null;
    AppHelpers.updateAppState();
    LoaderAction(appState.loader);
    if (err?.response?.data.data) {
      NotificationManager.error(Object.values(err?.response?.data.data).join("  "));
    } else {
      NotificationManager.error(err?.response?.data.message);
    }

    return { er: err?.response?.data };
  }
};

AuthFunctions.loginUser = async ({ email, password }, setOnBording, setAuth, navigation) => {
  if (email === "" || password === "") {
    return NotificationManager.error("All fields are required");
  }
  LoaderAction(true);
  console.log({ email, password }, navigation);
  // appState.loader = ReducerAction.loader.signUp;
  // AppHelpers.updateAppState();

  try {
    let res = await LoginServices({ email, password });
    if (res) {
      appState.loader = null;
      appState.modals = null;
      AppHelpers.updateAppState();

      LoaderAction(appState.loader);

      setToken(res?.data?.data?.token);
      setLocalItem("user", res?.data?.data?.user);

      setLocalItem("company", res?.data?.data?.company);
      NotificationManager.success(`Welcome ${res?.data?.data?.company?.businessName}.`);

      if (!res?.data?.data.company.onBoardCount) {
        setOnBording(true);

        return navigation.push("/onboard");
      }
      setAuth(true);
      navigation.push("/home");
    } else {
      console.log("no daua");
    }
    return null;
  } catch (err) {
    console.log("errr", err?.response?.data);
    appState.loader = null;
    AppHelpers.updateAppState();
    LoaderAction(appState.loader);
    if (err?.response?.data.data) {
      NotificationManager.error(Object.values(err?.response?.data.data).join("  "));
    } else {
      NotificationManager.error(err?.response?.data.message);
    }

    return { er: err?.response?.data };
  }
};

AuthFunctions.reLoginUser = async ({ email, password }, navigation) => {
  // appState.loader = ReducerAction.loader.signUp;
  // AppHelpers.updateAppState();

  try {
    let res = await LoginServices({ email, password });
    console.log("ree", res?.data);
    if (res) {
      appState.loader = null;
      appState.modals = null;
      AppHelpers.updateAppState();

      LoaderAction(appState.loader);

      setToken(res?.data?.data?.token);
      setLocalItem("user", res?.data?.data?.user);

      setLocalItem("company", res?.data?.data?.company);
      NotificationManager.success(`You are logged back in ${res?.data?.data?.company?.businessName}. Please continue `);

      // setAuth(true);
      // navigation("/homedfds");
    } else {
      console.log("no daua");
    }
    return null;
  } catch (err) {
    appState.loader = null;
    AppHelpers.updateAppState();
    LoaderAction(appState.loader);
    return { er: err?.response?.data };
  }
};

AuthFunctions.logout = async (navigation) => {
  setToken("");
  setLocalItem("user", "");

  setLocalItem("company", "");
  navigation("/");
};

export default AuthFunctions;
