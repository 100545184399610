import style from "styled-components";
import AppColors from "../../../helpers/_colors";

export const PubWrapper = style.div`
// border: 1px solid red;
height: 100%;
.pub--btn-container {
  // border: 1px solid blue;
  margin: 60px 0;
  width: 100%;
  display: flex;
  justify-content: space-around; //TODO change this back to space-around
  .Pub--btn {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 40px;
      color: ${AppColors.mainColor};
      background: #fff;
      width: 15%;
      // margin-left: 70px;  //TODO make sure to remove this
      transition: width 1s;

      @media (max-width: 1440px) {
        width: 20%;
      }

      @media (max-width: 1115px) {
        width: 25%;
      }

      @media (max-width: 585px) {
        width: 35%;
      }

      border: 1px solid ${AppColors.bgColor};
      // box-shadow: 1px 1px 1px #888888;
      box-shadow: 0px 16px 24px rgba(238, 127, 13, 0.16), 0px 6px 12px rgba(238, 127, 13, 0.16);

      h2 {
        font-size: 16px;
        margin: 0;
      }

      p {
        color: #8692A6;
        font-size: 12px;
      }

      &:hover {
          box-shadow: 0px 18px 24px rgba(238, 127, 13, 0.16), 0px 18px 12px rgba(238, 127, 13, 0.16);
      }
  }
}

.pub-table-wrapper {
  width: 100%;
  transition: width 1s;
  
  @media (min-width: 1440px) {
    width: 90%;
    margin: 0 auto;
  }
  
}
  
`;

export const PubUploadWrapper = style.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .upload-pdf__container {
    // border: 1px solid green;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;

    .upload-pdf__container-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .upload-pdf__container-header-title {
        text-align: center;
        color: #000000;
        font-size: 28px;
        font-weight: 500;
      }

      .sub-title {
        margin-top: 10px;
        width: 70%;
        p {
          text-align: center;
          color: #000000bd;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .upload__box.loading {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px;

      p {
        font-size: 16px;
      }
    }

    .upload__box {
      margin-top: 15px;
      width: 50%;
      height: 300px;
      border: 1.5px solid #D9DBE9;
      box-sizing: border-box;
      border-radius: 12px;
      background: #FAFAFC;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 45px;

      .upload__box--btn {
        background: #EE7F0D;
        border-radius: 6px;
        width: 200px;
        // height: 60px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        input {
          top: 0;
          position: absolute;
          width: 200px;
          height: 100%;
          opacity: 0;
        }

        h2 {
          color: #fff;
          // font-weight: 500;
          margin: 0;
          font-size: 20px;
        }

        p {
          color: #FFFFFF;
          margin: 0;
          font-size: 14px;
        }
      }

      .upload__box--options {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        width: 80%;

        .upload__box--options-item {
          display: flex;
          align-items: center;
          cursor: pointer;

          h3 {
            margin: 0;
          }
        }
      }
    }
  }
`;
