import style from "styled-components";

export const SessionExpiredCss = style.div`
.inner-modal-wrap-mLogin {
    width: 400px;

    .submit-login-details-home {
        button {

            border: none;
            outline: none;
            &:focus {
                border: none;
                outline: none;
            }

        }
    }
  }
  
  .inner-modal-wrap-mLogin .inPutHomeWrapP {
    width: 100%;

    input {
        width: 100%;
        padding: 25px;
    }

    .toggleViewPassword {
        position: relative;
        width: 90%;
      }
      
      .toggleViewPassword i {
        position: absolute;
        top: -57px;
        right: -20px;
        font-size: 18px;
        cursor: pointer;
        color: #ee7f0d;
      }

      @media (max-width: 600px) {
        .inPutHomeWrapP {
          width: 100%;
        }
        .login-wrap-home-input {
          min-width: 90vw;
        }
      
        .login-col-three-home input {
          width: 100%;
        }
      
        .toggleViewPassword i {
          position: absolute;
          top: -57px;
          right: 0 !important;
          font-size: 18px;
          cursor: pointer;
          color: #ee7f0d;
        }
      }
  }
  
  .inner-modal-wrap-mLogin .login-wrap-home-input {
    min-width: 100%;
    
  }
  
  .inner-modal-wrap-mLogin .submit-login-details-home button {
    width: 100%;
  }
  
  .login-modal-header-text {
    font-size: 17px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 23px;
    font-weight: 600;
  }
  
  .logoutBtnLoginModal {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .logoutBtnLoginModal span {
    color: rgba(255, 0, 0, 0.76);
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
  }


.login-wrap-home-input {
    min-width: 480px;
    max-width: 90vw;
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 50px;
  }
  
  @media (max-width: 1005px) {
    .login-wrap-home-input {
      width: 90vw;
    }
    .login-col-three-home input {
      width: 90%;
    }
    .submit-login-details-home button {
      width: 90%;
    }
  
    .error-container {
      width: 85%;
    }
  }
  
    .login-wrap-home-input {
      min-width: 90vw;
    } 
  
`;
