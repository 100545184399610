import React, { useState, useEffect } from "react";
import "./index.css";
import { Modal } from "react-responsive-modal";
import uploadIcon from "../../assets/images/upload.png";
import {
  axiosCalls,
  axiosFormData,
  httpGet,
  httpPatch,
} from "../../components/helpers/httpMethods";
import { showLoader, hideLoader } from "../../components/helpers/loader";
import swal from "sweetalert";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import truncateWithEllipses from "../../helpers/truncate";

export default function ChangeOfNameUserRequest(props) {
  const [showModalBookingSummart1, setshowModalBookingSummart1] =
    useState(false);

  const [CONStage, setCONStage] = useState("started");
  const [startDate, setStartDate] = useState("");
  const [file, setFile] = useState("");
  const [CONUser, setConUser] = useState("");

  useEffect(() => {
    getUser();
  }, [showModalBookingSummart1]);

  const setOpenshowModalBookingSummart1Modal = () => {
    setshowModalBookingSummart1(!showModalBookingSummart1);
  };

  const decideSubmit = () => {
    if (CONStage === "started") {
      if (startDate === "") {
        return swal("Error", "Please set a start date.", "error");
      }
      return CONProccessStart();
    }

    if (startDate == "" || file == "") {
      return swal("Error", "All fields are required.", "error");
    }
    CONProccessEnd();
  };

  const CONProccessStart = async () => {
    let dData = {
      publishedDate: startDate,
    };
    let res = await axiosCalls(
      `/con/start_process/${props.match.params.id}`,
      "PATCH",
      dData
    );
    if (res) {
      if (res.er) {
        console.log("this is the error >>", res.er);
        if (res.er.data) {
          return NotificationManager.error(
            Object.values(res.er.data).join("   ")
          );
        }
        return NotificationManager.error(res.er.message);
      }
      setOpenshowModalBookingSummart1Modal();
      swal(
        "Success",
        "You have successfully started the process for the Change of Name service.",
        "success"
      );
    }
  };

  const CONProccessEnd = async () => {
    // showLoader();
    const willDisable = await swal({
      title: "Are you sure?",
      text: `Are you sure you want to complete this application?`,
      icon: "warning",
      dangerMode: true,
      buttons: true,
    });

    if (willDisable) {
      const data = new FormData();
      data.append("publishedDate", startDate);
      data.append("file", file[0]);
      const res = await axiosFormData(
        `/con/complete_process/${props.match.params.id}`,
        "PATCH",
        data
      );
      if (res) {
        if (res.er) {
          console.log("this is the error >>", res.er);
          if (res.er.data) {
            return NotificationManager.error(
              Object.values(res.er.data).join("   ")
            );
          }
          return NotificationManager.error(res.er.message);
        }
        setOpenshowModalBookingSummart1Modal();
        swal(
          "Success",
          "You have successfully completed the process for the Change of Name service.",
          "success"
        ).then(() => {
          props.history.push("/change_of_name");
        });
      }
    }
  };

  const getUser = async () => {
    showLoader();
    const res = await httpGet(`con/application_info/${props.match.params.id}`);
    if (res.code == 200) {
      hideLoader();
      setConUser(res.data.conApplication);
      console.log("this is the user", res.data);
      setStartDate(
        moment(res.data.conApplication.createdAt).format("yyy-mm-DD")
      );
      if (res.data.conApplication.status === "completed") {
        setCONStage("completed");
        setStartDate(res.data.conApplication.updatedAt);
      }
    }
    hideLoader();
  };

  return (
    <div style={{ paddingTop: "30px", paddingBottom: "40px" }}>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ fontSize: "19px", fontWeight: "600" }}>Status</h1>
          <p
            style={{
              background: "#E8FCF1",
              borderRadius: "2.51852px",
              padding: "10px",
              marginLeft: "10px",
              color:
                CONUser?.status?.toLowerCase() !== "pending"
                  ? "#17BF63"
                  : "red",
            }}
          >
            {CONUser?.status}
          </p>
          <div
            style={CONUser?.status === "completed" ? { display: "none" } : {}}
          >
            <button
              style={{
                background: "#E8FCF1",
                borderRadius: "2.51852px",
                padding: "10px",
                marginLeft: "10px",
                border: "none",
                color: "#17BF63",
              }}
              onClick={setOpenshowModalBookingSummart1Modal}
            >
              Change status
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "35px",
          }}
          className="changerConfessCN"
        >
          <h1 style={{ fontSize: "19px", fontWeight: "600" }}>Declaration</h1>
          <p style={{}}>
            I, formerly known as{" "}
            <span>{`${CONUser?.formerTitle} ${CONUser?.formerLastname} ${CONUser?.formerFirstname} ${CONUser?.formerMiddlename} `}</span>{" "}
            now wish to be known and addressed as{" "}
            <span>
              {`${CONUser?.newTitle} ${CONUser?.newLastname} ${CONUser?.newFirstname} ${CONUser?.newMiddlename}`}
              .
            </span>
            <br /> General public take note.
          </p>
        </div>

        {/* <div
          className="changofnameLin"
          style={{ marginTop: '20px', marginBottom: '10px' }}
        ></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '35px',
          }}
          className="changerConfessCN"
        >
          <h1
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#6E7191',
              marginBottom: '20px',
            }}
          >
            Details
          </h1>
        </div>

        <div className="changeOfNameNewNPreview">
          <div className="changeOfNameNewNPreviewOne">
            <p style={{ marginTop: "28px" }}>Title</p>
            <p>Last name</p>
          </div>
          <div className="changeOfNameNewNPreviewOne">
            <h2>Former Name</h2>
            <p>{`${CONUser?.formerTitle}`}</p>
            <p>{`${CONUser?.formerLastname}`}</p>
          </div>
          <div className="changeOfNameNewNPreviewtwo">
            <h2>New Name</h2>
            <p>{`${CONUser?.newTitle}`}</p>
            <p>{`${CONUser?.newLastname}`}</p>
          </div>
        </div> */}

        <div className="changeOfNameNewNPreview" style={{ marginTop: "30px" }}>
          <div className="changeOfNameNewNPreviewOne">
            <h2>Reason</h2>
            <p>{CONUser?.reason}</p>
          </div>
        </div>
        <div
          className="changofnameLin"
          style={{ marginTop: "30px", marginBottom: "10px" }}
        ></div>

        <div className="changeOfNameNewNPreview" style={{ marginTop: "30px" }}>
          <div className="changeOfNameNewNPreviewOne snDocumwn">
            <h2>Uploaded Documents</h2>
            <div className="dcCns">
              <div className="changeOfNameDownload">
                <div className="changeOfNameDownloadImg">
                  <img src={CONUser?.affidavit} alt="" />
                </div>
                <a href={CONUser?.affidavit} download>
                  Download affidavit
                </a>
              </div>

              <div className="changeOfNameDownload">
                <div className="changeOfNameDownloadImg">
                  <img src={CONUser?.proveOfReason} alt="" />
                </div>
                <a href={CONUser?.proveOfReason} download>
                  Download Prove Of Reason
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={showModalBookingSummart1}
        onClose={setOpenshowModalBookingSummart1Modal}
      >
        <div
          className="inner-modal-wrap-trvChN"
          style={{ background: "white", width: "350px" }}
        >
          <div className="previewChangeOfname1Wrap">
            <h1
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginBottom: "0",
                fontWeight: "600",
                paddingTop: "15px",
              }}
            >
              Change Status
            </h1>
            <p
              style={{
                textAlign: "center",
                fontSize: "11px",
                padding: "12px 0px 15px 0px",
              }}
            >
              Select current status of Change of name
            </p>
          </div>
          <div className="changeOfNameTimmer">
            <div className="continputWrap">
              <select
                name=""
                id=""
                value={CONStage}
                onChange={(e) => setCONStage(e.target.value)}
              >
                <option value="started">Started</option>
                <option value="completed">Completed</option>
              </select>
            </div>

            <div className="continputWrap">
              <input
                type="date"
                value={startDate}
                onChange={({ target }) => {
                  console.log(target.value);
                  setStartDate(target.value);
                }}
              />
            </div>
          </div>
          {CONStage === "started" ? (
            ""
          ) : (
            <div style={{ position: "relative" }}>
              <div className="inputnwChtab3">
                <p className="inputnwChtab3label">
                  {file
                    ? truncateWithEllipses(file.name, 40)
                    : "Upload proof of publish *"}
                </p>
                <div className="inputnwChtab3FakeFileI"></div>
                <input
                  type="file"
                  placeholder="New First Name * "
                  className="mwhgeqinputTab3"
                  onChange={({ target }) => setFile(target.files[0])}
                />
                <button className="inputnwChtab3Button">
                  <img src={uploadIcon} alt="" />
                </button>
              </div>
            </div>
          )}

          {/* {CONStage === "completed" && ( */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="actionBtnCName" onClick={decideSubmit}>
              Save
            </button>
          </div>
          {/* )} */}
        </div>
      </Modal>
    </div>
  );
}
