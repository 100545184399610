import React from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
export default function chatBox({
  onEditorStateChange,
  editorState,
  uploadImageCallBack,
}) {
  //   const Embed = ({ block, contentState }) => {
  //     const entity = contentState.getEntity(block.getEntityAt(0));
  //     const { src, height, width } = entity.getData();
  //     return (
  //       <iframe
  //         height={height}
  //         width={width}
  //         src={src}
  //         frameBorder="0"
  //         allowFullScreen
  //         title="Wysiwyg Embedded andy"
  //       />
  //     );
  //   };

  const embedVideoCallBack = (link) => {
    // alert(link);
    if (link.includes("youtu.be") || link.includes("youtube")) {
      link = link.replace("watch?v=", "embed/");
      link = link.replace("/watch/", "/embed/");
      link = link.replace("youtu.be/", "youtube.com/embed/");
    }
    return link;
  };
  return (
    <div>
      <Editor
        editorState={editorState}
        height={60}
        toolbar={{
          options: ["emoji", "inline", "image", "embedded"],
          embedded: {
            // icon: embedded,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            embedCallback: embedVideoCallBack,
            defaultSize: {
              height: "auto",
              width: "100%",
            },
          },

          image: {
            // icon: editorImg,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "100%",
            },
          },
          //   inline: {
          //     inDropdown: false,
          //     className: undefined,
          //     component: undefined,
          //     dropdownClassName: undefined,
          //     options: ["bold", "italic", "underline"],
          //     bold: { icon: boldB, className: undefined },
          //     italic: { icon: TextItalic, className: undefined },
          //     underline: {
          //       icon: TextUnderline,
          //       className: undefined,
          //     },
          //   },

          //   image: {
          //     icon: editorImg,
          //     className: undefined,
          //     component: undefined,
          //     popupClassName: undefined,
          //     urlEnabled: true,
          //     uploadEnabled: true,
          //     alignmentEnabled: true,
          //     uploadCallback: _uploadImageCallBack,
          //     previewImage: true,
          //     inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          //     alt: { present: false, mandatory: false },
          //     defaultSize: {
          //       height: "auto",
          //       width: "auto",
          //     },
          //   },
          //   emoji: {
          //     icon: Smiley,
          //   },
          //   blockType: {
          //     inDropdown: true,
          //   },

          //   list: {
          //     inDropdown: true,
          //   },

          //   link: {
          //     inDropdown: true,
          //   },

          //   history: {
          //     inDropdown: true,
          //   },
        }}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(editor) => onEditorStateChange(editor)}
      />
    </div>
  );
}
