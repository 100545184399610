import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Progress } from "reactstrap";
// import { getPublicationTypes } from "../../controllers/publication/getPublications";
// import { AuthContext } from "../../context/AuthContext";
import { NotificationManager } from "react-notifications";
import { PublicationContext } from "../../context/PublicationContext";
import pendCn from "../../assets/images/pendCn.png";
import {
  httpGet,
  httpPatch,
  // httpPost,
  httpPostData,
} from "../../components/helpers/httpMethods";
import { getLocalItem } from "../../components/helpers/authService";
import "./index.css";
import { ArchiveWrapper } from "./style";
import warningIcon from "../../assets/warning.svg";
import upload from "../../assets/upload2.svg";
import info from "../../assets/info.svg";
// import ChangeOfNameRequests from "./changeOfNameRequest";
import { Modal } from "react-responsive-modal";
// import DatePicker from "../../utils/DatePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import ArchivesList from "../../components/tables/archivesList";
import swal from "sweetalert";
import validatePdf from "../../utils/validatePdf";

export default function Archives(props) {
  const [showModal, closeModal] = useState(false);
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const [name, setName] = useState("");
  const [turnAroundTime, setTurnAroundTime] = useState("");
  const [price, setPrice] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [applications, setApplications] = useState([]);
  const [subStatus, setSubStatus] = useState("");
  const [uploadList, setUploadList] = useState([]);
  const [uploadId, setUploadId] = useState("");
  const [uploadData, setUploadData] = useState({});
  const [progressVal, setProgressVal] = useState({});
  const [finalUploadData, setFinalUploadData] = useState([]);

  useEffect(() => {
    setSubStatus("");
    getSinglePub();
    getArchivesApplications();
    getSubscriptionStatus();
  }, [reload]);

  const getSinglePub = async () => {
    if (id) {
      let res = await httpGet(`publication/${id}`);
      if (res) {
        if (!res.success) {
          return;
        }
        setName(res.data.publication.name);
      }
    }
  };

  const getSubscriptionStatus = async () => {
    let res = await httpGet(`archive/subscription_status`);
    if (res?.success) {
      setSubStatus(res.data?.archiveSubscription?.status);
    } else {
      setSubStatus("false");
    }
  };

  const getArchivesApplications = async () => {
    let res = await httpGet(`archive/applications`);
    if (res) {
      setApplications(res.data?.archiveApplications);
    }
    return;
  };

  const handlePreview = (e, name) => {
    const uploadFile = e.target.files[0];
    const validateFile = validatePdf(uploadFile);
    console.log("validate>>>", validateFile);
    if (validateFile.valid === true) {
      setUploadData({ ...uploadData, [name]: uploadFile });
    } else {
      swal("error", "Please Upload the right format *.pdf ", "error");
    }
  };

  const handleSingleUpload = async (date, name) => {
    showLoader();
    if (!uploadData[name]) {
      swal("error", "please upload file", "error");
      hideLoader();
    }
    setProgressVal({ ...progressVal, [name]: "0" });
    const newForm = new FormData();
    uploadData[name] && newForm.append("file", uploadData[name]);
    newForm.append("date", date);

    const res = await httpPatch(`/archive/start_process/${uploadId}`, newForm);
    if (res?.success) {
      setFinalUploadData([
        ...finalUploadData,
        { date: date, path: res.data?.path },
      ]);

      setProgressVal({ ...progressVal, [name]: "100" });
      hideLoader();
      swal("success", res.message, "success");
    } else {
      hideLoader();
      swal("oops", res.message, "error");
    }
  };
  const handleUpload = async () => {
    showLoader();
    if (uploadList?.length !== Object.keys(finalUploadData).length) {
      swal("Ooops", "Please Upload the list of requested files", "error");
      hideLoader();
      return;
    }
    const data = {
      requestDates: JSON.stringify(finalUploadData),
    };
    const res = await httpPatch(`/archive/complete_process/${uploadId}`, data);
    if (res.success) {
      hideLoader();
      setUploadData({});
      closeModal(false);
      getArchivesApplications();
      swal("success", res.message, "success");
    } else {
      hideLoader();
      swal("oops", res.message, "error");
    }
  };
  const handleSubscribeArchives = async () => {
    showLoader();
    if (turnAroundTime === "") {
      swal("error", "Please Input Turn Around Time !!!", "error");
      hideLoader();
      return;
    }

    if (price === "") {
      swal("error", "Please Input Amount", "error");
      hideLoader();
      return;
    }

    const data = { turnAroundTime, price, fromDate, endDate: new Date() };

    let res = await httpPostData(`/archive/subscribe`, data);

    if (res) {
      if (res.er) {
        hideLoader();
        NotificationManager.error(res.er.message);
        return;
      }
      hideLoader();
      NotificationManager.success(res.message);
      getSubscriptionStatus();
    }
  };

  console.log("fin>>>", finalUploadData);

  return (
    <ArchiveWrapper>
      {subStatus === "false" && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="changeOfNameHeroImg">
              <h1>Archive</h1>
              <p>Giving users access to powerful past publications</p>
            </div>
          </div>

          <div className="Archive-create">
            <div className="part-1">
              <div className="item">
                <div className="item-name">Name of Publication</div>
                <div className="item-input">
                  <input
                    disabled
                    type="text"
                    className=" "
                    style={{ background: "#EEEEEE" }}
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="item">
                <div className="item-name">
                  <div>Amount </div>
                  <div className="sub-name">Per archive request</div>
                </div>
                <div className="item-input">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter Amount"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>
              <div className="item">
                <div className="item-name">
                  <div>Archive Range</div>
                  <div className="sub-name">
                    <img src={warningIcon} alt="" />
                    <div className="w-75">
                      Kindly select the years you have acrhive available for.
                    </div>
                  </div>
                </div>
                <div className="item-input">
                  <div className="item-date-box w-100">
                    <div>From</div>
                    <DatePicker
                      selected={fromDate}
                      onChange={(date) => setFromDate(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
              </div>
              <div className="item border-0 ">
                <div className="item-name">Turn around time</div>
                <div className="item-input">
                  <select
                    value={turnAroundTime}
                    onChange={(e) => setTurnAroundTime(e.target.value)}
                  >
                    <option value="" disabled>
                      --select no of days--
                    </option>
                    <option value="3">3 Days</option>
                    <option value="5">5 Days</option>
                    <option value="7">7 Days</option>
                    <option value="14">14 Days</option>
                  </select>
                </div>
              </div>
              <div className="p-1-button">
                <button onClick={handleSubscribeArchives}>Proceed</button>
              </div>
            </div>
            <div className="part-2">
              <img src={warningIcon} alt="" />
              <div>
                This field can not be changed. To change this, kindly selct
                another publication on the top right bar.
              </div>
            </div>
          </div>
        </div>
      )}
      {subStatus === "active" && (
        <ArchivesList
          applications={applications || []}
          closeModal={closeModal}
          showModal={showModal}
          setUploadList={setUploadList}
          setUploadId={setUploadId}
        />
      )}
      {subStatus === "pending" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "450px",
              borderRadius: "5px",
              flexDirection: "column",
              padding: "15px",
              border: "1px solid #f6f6f6",
            }}
          >
            <img
              src={pendCn}
              style={{ width: "350px", height: "300px" }}
              alt=""
            />
            <p
              style={{ textAlign: "center", padding: "12px", fontSize: "15px" }}
            >
              Your request is still being processed by{" "}
              <span style={{ color: "#ee7e0dc5" }}>TheNewspaperStand</span>, an
              email will be sent to you once you have been approved for this
              service.
            </p>
          </div>
        </div>
      )}
      <div className="comfirmPaymentModal">
        <Modal open={showModal} onClose={closeModal} center>
          <div
            className="inner-modal-wrap card border-0 mx-auto"
            style={{
              width: "300px",
            }}
          >
            <div className="text-center w-100 d-flex flex-column">
              <h1
                className="text-black"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Upload Archive
              </h1>
              <p
                className=""
                style={{
                  color: "#999999",
                  fontSize: "12px",
                }}
              >
                <img src={info} alt="" style={{ color: "#9999999" }} />
                <span>Upload archive for each request date</span>
              </p>
              {uploadList.map((date, index) => (
                <div className="mt-3 mb-2" key={index}>
                  <div
                    className="mt-4 "
                    style={{
                      height: "45px",
                    }}
                  >
                    <p
                      className=""
                      style={{
                        color: "#999999",
                        fontSize: "10px",
                        marginRight: "179px",
                      }}
                    >
                      <span>{moment(date?.date).format("MMMM Do YYYY")}</span>
                    </p>
                    <div
                      className="d-flex mx-auto"
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        boxSizing: "border-box",
                        borderRadius: "7px",
                      }}
                    >
                      <input
                        type="file"
                        name=""
                        id={index}
                        style={{
                          opacity: 0,
                        }}
                        onChange={(e) => handlePreview(e, e.target.id)}
                      />
                      <div
                        style={{
                          background: " #EE7F0D",
                          borderRadius: "0px 7px 7px 0px",
                        }}
                      >
                        <img
                          style={{
                            height: "43px",
                            borderRadius: "5px 0 0 5px",
                          }}
                          src={upload}
                          onClick={() => {
                            handleSingleUpload(date?.date, index);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {uploadData[index] && (
                    <div
                      className="mt-3 text-left"
                      style={{
                        fontSize: "12px",
                        color: "grey",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {uploadData[index]?.name}
                      <Progress value={`${progressVal[index] || 0}`}>
                        {progressVal[index] === "100" ? "success" : "error "}
                      </Progress>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="closeCONdocModald mx-auto">
              <button
                style={{
                  background: "#EE7F0D",
                  color: "white",
                  borderRadius: "5px",
                }}
                onClick={handleUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </ArchiveWrapper>
  );
}
