import React from "react";
import Transactions from "../../components/tables/transactions";
const Index = () => {
  return (
    <div>
      <Transactions />
    </div>
  );
};

export default Index;
