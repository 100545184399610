function getExtension(filename) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}

const validatePdf = (file) => {
  let valid = false;
  const ext = getExtension(file.name);
  if (ext.toLowerCase() === "pdf") {
    valid = true;
  }
  return { valid };
};

export default validatePdf;
