import React, { useState, useEffect, useContext } from 'react';
import { PublicationContext } from '../../context/PublicationContext';
import icons from './icons';
import MaterialTable from 'material-table';
import { Paper, TextField, Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@material-ui/core';
import Avatar from './avatar.svg';
import { axiosCalls } from '../helpers/httpMethods';
import moment from 'moment';
import './table.css';
import * as _ from 'lodash';
import FilterIcon from '@material-ui/icons/FilterList';

export default function DataTable(props) {
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const [popOver, setPopOver] = useState([]);
  const [show, setShow] = useState(false);
  const [dData, setData] = useState([]);

  useEffect(() => {
    getTransactions();
  }, [reload]);

  const getTransactions = async () => {
    let res = await axiosCalls(`/pub_payment_history`, 'GET', null, 'load');
    if (res) {
      if (res.er) {
        return;
      }
      setData(res.data.paymentHistory);
    }
  };

  function isDateInRange1(dateFrom, dateTo, dateToBeFiltered) {
    return dateFrom <= dateToBeFiltered && dateToBeFiltered <= dateTo;
  }
  function isDateInRange2(dateFrom, dateTo, dateToBeFiltered) {
    return dateFrom <= dateToBeFiltered && dateToBeFiltered <= dateTo;
  }

  const FilterNumericBetween = (props) => {
    const { columnDef, onFilterChanged } = props;

    return (
      <>
        <Accordion elevation={1} style={{ display: 'block' }}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <FilterIcon /> <Typography>Filter Date</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            <TextField
              style={{ marginTop: '-20px', marginBottom: '20px' }}
              type="date"
              value={_.get(columnDef, ['tableData', 'filterValue', 'dateFrom']) || ''}
              onChange={(event) => {
                let value = { ...columnDef.tableData.filterValue };
                value.dateFrom = event.target.value;
                onFilterChanged(columnDef.tableData.id, value);
              }}
            />
            <TextField
              type="date"
              value={_.get(columnDef, ['tableData', 'filterValue', 'dateTo']) || ''}
              onChange={(event) => {
                let value = { ...columnDef.tableData.filterValue };
                value.dateTo = event.target.value;
                onFilterChanged(columnDef.tableData.id, value);
              }}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
          filtering: true,
          exportAllData: true,
          pageSizeOptions: [10, 20, 50, 100, 200],
          pageSize: 10,
        }}
        columns={[
          { title: 'Name', field: 'Name', filtering: false },
          {
            title: 'Payment Date',
            field: 'paymentDate',
            filterComponent: FilterNumericBetween,
            customFilterAndSearch: (filter, rowData) => isDateInRange1(filter.dateFrom, filter.dateTo, rowData.paymentDate),
          },
          {
            title: 'Type',
            field: 'Subscription',
            lookup: {
              monthly: 'monthly',
              weekly: 'weekly',
              one_off: 'one-off',
              quarterly: 'quarterly',
              annually: 'annually',
              biannually: 'biannually',
            },
          },
          { title: 'Amount', field: 'amount', filtering: false },
          {
            title: 'Next Payment Date',
            field: 'nextPaymentDate',
            filterComponent: FilterNumericBetween,
            customFilterAndSearch: (filter, rowData) => isDateInRange2(filter.dateFrom, filter.dateTo, rowData.nextPaymentDate),
          },
          { title: ' Status', field: 'status', lookup: { active: 'active', expired: 'expired' } },
        ]}
        data={
          dData.length == 0
            ? []
            : dData.map((data) => {
                return {
                  Name: `${data.customerId} `,
                  paymentDate: moment(data.paymentDate).format('YYYY-MM-DD'),
                  Subscription: data.subType,
                  amount: data.amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: data.currency,
                  }),
                  nextPaymentDate: moment(data.nextPaymentDate).format('YYYY-MM-DD'),
                  status: data.status,
                };
              })
        }
        title="Transactions"
      />
    </div>
  );
}
