import React, { useState } from 'react';
import Layout from '../Layout/Layout.js';
import { Route } from 'react-router-dom';

const DefaultLayout = ({ children, ...rest }) => {
    //console.log('%%%', children)
    let browserRouter = children.props.history.push
    let currentRoute = children.props.location.pathname
    return <Layout currentRoute={currentRoute} browserRouter={browserRouter}>{children}</Layout>;
};

const DefaultLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(matchProps) => {
                return (
                    <DefaultLayout page={rest.page}>
                        <Component {...matchProps} />
                    </DefaultLayout>
                );
            }}
        />
    );
};
export default DefaultLayoutRoute;