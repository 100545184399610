import style from "styled-components";

export const WalletHistoryWrapper = style.div`
    width: 700px;
    height: 667px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    
    .wallet-history__header {
        border-bottom: 1px dashed #C7BFBF;
        width: 80%;
        margin: 0 auto;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            font-size: 20px;
            margin: 0;
            color: #4E4B66;
        }
    }

    .wallet-history__content {
        margin-top: 10px;

        .wallet-history__content-header {
            background: #FFECD8;
            padding: 8px 10px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;

            p {
                font-size: 14px;
                color: #4E4B66;
                margin: 0;
            }

            .wallet-history__content-header-item {
                flex: 1;
                justify-content: center;
                align-items: center;
            }

            .wallet-history__content-header-odd {
                width: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .wallet-history__content-container {
        flex: 1;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .wallet-history__content.item {
        .wallet-history__content-header {
            background: #F2F2F2 !important;
            padding: 20px 10px;
        }
    }
`;
export const DashWrapper = style.div`
    margin-top: 55px;
    display: flex;
    

    .dash-graph {
        flex: 1;
        // border: 1px solid blue;
        display: flex;
        flex-direction: column;
        border-radius: 10px;

        .dash-graph--header {
            position: relative;
            display: flex;
            justify-content: space-between;
            // align-items: center;
            margin: 20px 0;

            h1 {
                font-weight: 600;
                margin: 0;
            }

            h3 {
                color: #9FA2B4;
            }

            .dash-graph--header_dropdown {
                position: absolute;
                width:  300px;
                padding: 20px 0;
                right: 40px;
                top: 60px;
                background: #FFFFFF;
                box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);

                .dash-graph--header_dropdown_item {
                    margin-bottom: 10px;
                    padding: 0 20px;
                    cursor: pointer;
                    p {
                        font-size: 16px;
                        margin: 0;
                    }

                    &:hover {
                        background: #EFF0F7;
                    }

                }
                
            }


            .dash-graph--header-btn {
                padding: 10px;
                width: 130px;
                height: 50px;
                background: #EFF0F7;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 5px;
                // cursor: pointer;

                h3 {
                    margin: 0;
                    color: #000;
                }
            }
        }

        .dash-graph_graph {
            flex: 1;

            
        }
    }

    .dash__wallet {
        width: 200px;
        height: 350px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .dash__wallet-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .dash__wallet-content-item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                .dash__wallet-content-item-cur {
                    display: flex;
                    justify-content: center;

                    .content-item-cur__container {
                        padding: 2px 5px;
                        border-box: box-sizing;
                        // 
                        margin-right: 5px;
                        margin-bottom: 20px;
                        cursor: pointer;

                        p {
                            margin: 0;
                            font-size: 16px;
                            color: #334D6E;
                        } 

                        &:hover {
                            border-bottom: 1px solid #EE7F0D;
                        }
                    }

                    .content-item-cur__container .active {
                        border-box: box-sizing;
                        border-bottom: 1px solid #EE7F0D;
                    }

                    
                }

                button {
                    margin-top: 10px;
                    width: 130px;
                    padding: 15px;
                    border-radius: 40px;
                    background: #FEF2E6;

                    h3 {
                        margin: 0;
                        color: #F14A06;
                        font-weight: 600;
                        font-size: 15px;
                    }
                }

                h2 {
                    color: #EE7F0D;
                    font-size: 15px;
                }

                h1 {
                    font-weight: 600;
                    margin: 0;
                    font-size: 15px;
                }
            }
        }

        .dash__wallet-footer {
            .dash__wallet-footer-body {
                background: #EE7F0D;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    color: #000000;
                    margin: 0;
                    font-size: 15px; 
                    font-weight: 700;
                }

                h2 {
                    color: #fff;
                    margin: 0;
                    font-size: 10px;

                }

                h3 {
                    color: #383131;
                    margin: 0;
                }
            }

            .dash_wallet-footer_base {
                cursor: pointer;
                background: #000000;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                h2 {
                    color: #fff;
                    margin: 0;
                    font-size: 14px;

                }
            }
        }
    }
`;
