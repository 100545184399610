import { dispatcher } from "../context/AuthContext";
import ReducerAction from "../context/ReducerAction";

let AppHelpers = {};

AppHelpers.updateAppState = () => {
  dispatcher({
    type: ReducerAction.store.updateState,
  });
};
export default AppHelpers;
