import React, { useState } from 'react';
// import logo from "./logo.svg";
// import styles from "./Test.module.css";
import { Route, Switch } from 'react-router-dom';
// pages
import RegisterPage from './pages/Auth/register';
import LoginPage from './pages/Auth/login';
import ResetPasswordPage from './pages/Auth/resetPassword';
import DefaultLayoutRoute from './components/DefaultLayout/DefaultLayoutRoute';
import HomePage from './pages/dashboard/index';
import 'react-datepicker/dist/react-datepicker.css';
import AcademyPage from './pages/dashboard/academy';
import Publications from './pages/publication/index';
import Articles from './pages/publication/articles/index';
import AddArticle from './pages/publication/articles/add/addArticle';
import EditArticle from './pages/publication/articles/editArticle';
import UploadPdf from './pages/publication/uploadPdf';
import Editions from './pages/publication/edition';
import PDFTest from './pages/testPdf/test';
import Subscribers from './pages/subscribers/subscribers';
import Reviews from './pages/reviews/review';
import Comments from './pages/comments/comments';
import SalesReport from './pages/salesReport/Sales';

import Analytics from './pages/analytics/index';
import Settings from './pages/settings/settings';
import General from './pages/settings/general';
import { NotificationContainer } from 'react-notifications';
import Sales from './pages/sales/index';

// context
import { AuthContextProvider } from './context/AuthContext';
import { PublicationContextProvider } from './context/PublicationContext';
import { SettingsContextProvider } from './context/SettingsContext';
import { DashboardContextProvider } from './context/DashboardContext';

import VerifyAccount from './pages/Auth/verifyPage';

import NewPass from './pages/Auth/newPassword';

// onboard
import OnboardPage from './pages/onboarding/onboarding';
import ChangeOfName from './pages/changeOfName/index';
import ChangeOfNameRequests from './pages/changeOfName/changeOfNameRequest';
import ViewRequest from './pages/changeOfName/changeOfNameUserRequest';
import Archives from './pages/Archives';
import PublicNotice from './pages/publicNotice';
import Dragable from './dragable';
function App() {
  // console.log(process.env.REACT_APP_BASE_URL);

  return (
    <>
      <div className='dragtable'>
        <NotificationContainer />
        <AuthContextProvider>
          <PublicationContextProvider>
            <SettingsContextProvider>
              <DashboardContextProvider>
                <Switch>
                  <Route exact path='/' component={LoginPage} />
                  <Route exact path='/register' component={RegisterPage} />
                  <Route exact path='/reset' component={ResetPasswordPage} />
                  <Route exact path='/onboard' component={OnboardPage} />
                  <Route
                    exact
                    path='/verify_vendor_account/:id'
                    component={VerifyAccount}
                  />
                  <Route exact path='/reset_password/:id' component={NewPass} />
                  {/* <Route exact="/test" path="/test" component={Test} /> */}
                  {/* with layout */}
                  <DefaultLayoutRoute exact path='/home' component={HomePage} />
                  <DefaultLayoutRoute
                    exact
                    path='/academy'
                    component={AcademyPage}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/publications'
                    component={Publications}
                  />

                  <DefaultLayoutRoute
                    exact
                    path='/transactions'
                    component={Sales}
                  />

                  <DefaultLayoutRoute
                    exact
                    path='/publications/upload_pdf'
                    component={UploadPdf}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/publications/edition/:id'
                    component={Editions}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/publications/articles/:id'
                    component={Articles}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/publications/articles/add/:id'
                    component={AddArticle}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/publications/articles/edit/:id'
                    component={EditArticle}
                  />
                  <DefaultLayoutRoute exact path='/pdf' component={PDFTest} />
                  <DefaultLayoutRoute
                    exact
                    path='/subscribers'
                    component={Subscribers}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/reviews'
                    component={Reviews}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/comments'
                    component={Comments}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/sales_report'
                    component={SalesReport}
                  />

                  <DefaultLayoutRoute
                    exact
                    path='/archive'
                    component={Archives}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/public_notice'
                    component={PublicNotice}
                  />
                  {/* analytics */}
                  <DefaultLayoutRoute
                    exact
                    path='/analytics'
                    component={Analytics}
                  />

                  <DefaultLayoutRoute
                    exact
                    path='/settings'
                    component={Settings}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/business_settings'
                    component={General}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/change_of_name'
                    component={ChangeOfName}
                  />
                  <DefaultLayoutRoute
                    exact
                    path='/view_name_request/:id'
                    component={ViewRequest}
                  />
                  <DefaultLayoutRoute exact path='/d' component={Dragable} />
                </Switch>
              </DashboardContextProvider>
            </SettingsContextProvider>
          </PublicationContextProvider>
        </AuthContextProvider>
      </div>
    </>
  );
}

export default App;
