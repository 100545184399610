import React, { useState, useEffect } from "react";
import Breadcrum from "../../components/shared/breadcrum";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Input, Button } from "reactstrap";
import TestPdf from "./pdfData";
import { axiosCalls } from "../../components/helpers/httpMethods";
import { NotificationManager } from "react-notifications";
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import moment from "moment";
import swal from "sweetalert";
import { Scrollbars } from "react-custom-scrollbars";
import style from "styled-components";
import { SizedBox } from "../../lib/main";

// using CommonJS modules
// import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
export default function UploadPdf(props) {
  const id = props.match.params.id;
  const [editionEdit, setEditionEdit] = useState(false);

  const [openCreatePdfModal, setOpenCreatePdfModal] = useState(false);
  const onOpenCreatePdfModalModal = () => setOpenCreatePdfModal(true);
  const onCloseCreatePdfModal = () => setOpenCreatePdfModal(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [PublicationType, setPublicationType] = useState("");
  const [editionUrl, setEditionUrl] = useState("");
  const [editionName, setEditionName] = useState("");
  const [editionId, setEditionId] = useState("");
  const [pubedDate, setPubedData] = useState(new Date());
  const [selectedCat, setSelectedCat] = useState("");
  const [articles, setArticles] = useState([]);

  const [cats, setCats] = useState([]);

  useEffect(() => {
    getArticles();
    getCats();
    getSingleEdition();
  }, []);

  const getSingleEdition = async () => {
    let res = await axiosCalls(`/edition/${id}`);
    if (res) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }
      console.log(res);
      console.log("succcess", res.data);
      setEditionUrl(res.data.edition.image);
      setEditionName(res.data.edition.editionName);
      setEditionId(res.data.edition.editionId);
      setPubedData(new Date(res.data.edition.publishedDate));
      setSelectedCat(res.data.edition.categoryId);
      setPublicationType(res.data.edition.publication.typeId);
    }
  };

  const getCats = async () => {
    setCats([]);
    let res = await axiosCalls(`/categories`, "GET");
    if (res) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }

      console.log("cats", res.data.categories);
      setCats(res.data.categories);
    }
  };

  // console.log(cats);

  const updateEdition = async () => {
    console.log(pubedDate);

    if (selectedCat === "") {
      return NotificationManager.info("Please select edition category");
    }
    let data = {
      editionName,
      categoryId: selectedCat,
      status: "active",
      publishedDate: pubedDate,
    };
    let res = await axiosCalls(`/update_edition/${id}`, "PATCH", data);
    if (res) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }
      swal("Congratulations!", "Your Edition is now live", "success");
      props.history.push("/home");
    }
  };

  const getArticles = async () => {
    let res = await axiosCalls(`/get_articles/${id}`, "GET", "load");
    if (res) {
      if (res.er) {
        console.log("dfs", res.er);
        return;
      }
      console.log("hgjg", res.data);
      setArticles(res.data.articles);
    }
  };

  return (
    <EditionEditWrapper>
      <Breadcrum hide="ir" title="Edit Edition" nav={props.history} />
      <div className="edition--wrapper">
        <div className="edit-wrapper">
          {/* <div className='edit-wrapper__btn'>
            <button
              onClick={() => {
                if (articles.length) {
                  return props.history.push(`/publications/articles/${id}`);
                }
                props.history.push(
                  `/publications/articles/add/${id}_${editionId}`
                );
              }}
            >
              <h2>{articles.length > 0 ? 'View Articles' : 'Add Articles'}</h2>
            </button>
          </div> */}

          <div className="edit-wrapper-container">
            <div className="container">
              <div className="row">
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-10 mx-auto edition--wrapper-input-wrapper"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div className="edition--wrapper-input-box">
                    <h2>Edition Id</h2>
                    {/* <SizedBox height=".3vh" /> */}
                    <input
                      type="text"
                      disabled={true}
                      value={editionId}
                      onChange={(e) => setEditionId(e.target.value)}
                      name="name"
                      className=""
                      id="examplePassword"
                    />
                  </div>
                  <div className="edition--wrapper-input-box">
                    {PublicationType ===
                    "eb244fbc-ab2c-4a51-9b6f-034d987f352e" ? (
                      <h2>Author name</h2>
                    ) : (
                      <h2>Edition name</h2>
                    )}

                    {/* <SizedBox height=".3vh" /> */}
                    <input
                      type="text"
                      value={editionName}
                      onChange={(e) => setEditionName(e.target.value)}
                      name="name"
                      className=""
                      id="examplePassword"
                    />
                  </div>
                  <div className="edition--wrapper-input-box">
                    <h2>Publish date</h2>
                    {/* <SizedBox height=".3vh" /> */}
                    <input
                      type="date"
                      value={moment(pubedDate).format("yyyy-MM-DD")}
                      defaultValue={pubedDate}
                      onChange={(e) => {
                        console.log(new Date(e.target.value));
                        setPubedData(new Date(e.target.value));
                      }}
                      name="name"
                      className=""
                      id="examplePassword"
                    />
                  </div>
                  <div>
                    <h2 className="h2">Category</h2>
                    <select
                      value={selectedCat}
                      onChange={(e) => setSelectedCat(e.target.value)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {/* <option>Select category</option> */}
                      <option selected value={null}>
                        General
                      </option>
                      {cats.map((el, i) => {
                        return (
                          <option value={el.id} key={i}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="mt-4 d-flex justify-content-end"
                    style={{ width: "70%" }}
                  >
                    <button onClick={() => updateEdition()}>
                      <h2>Publish</h2>
                    </button>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-10 mx-auto">
                  <div className="d-flex justify-content-end">
                    <div className="feature--image-box">
                      <img
                        src={editionUrl}
                        alt="thumnail"
                        className="img-fluid"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditionEditWrapper>
  );
}

const EditionEditWrapper = style.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .h2 {
      font-size: 16px;
      margin: 0;
      color: var(--appGrey3);
      font-weight: 400;
      margin-bottom: 10px;
    }
    .edition--wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .edit-wrapper {
            width: 100%;
            // margin-top: 

            .edit-wrapper__btn {
              display: flex;
              justify-content: flex-end;

              margin-bottom: 20px;

              button {
                width: 10%;
              }
            }
            
            .edition--wrapper-input-wrapper {
                .edition--wrapper-input-box {
                    margin-bottom: 30px;

                    h2 {
                        font-size: 16px;
                        margin: 0;
                        color: var(--appGrey3);
                        font-weight: 400;
                    }

                    input {
                        border-radius: 5px;
                        margin-top: 10px;
                        font-size: 14px;
                        letter-spacing: 1px;

                        &:focus {
                            outline: none;
                        }
                    }

                    select {
                        border-radius: 5px;
                        margin-top: 10px;
                        font-size: 15px;
                        padding: 23px 10px;
                        letter-spacing: 1px;
                        color: #8692A6;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            .feature--image-box {
                border: 10px solid var(--bgColor);
                width: 80%;
            }
            
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--mainColor);
                padding: 14px;
                border-radius: 25px;
                width: 40%;
                border: none;

                h2 {
                    color: #fff;
                    margin: 0;
                    font-size: 16px;
                }
            }
        }
    }
    
`;
