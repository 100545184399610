import React, { useState } from "react";
import moment from "moment";
import icons from "../../helpers/materialUIicon";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
// import dateFormater from "./../../../helpers/formatDate";

export default function TransationTable(props) {
  console.log("CON>>>", props.applications);

  const columns = [
    { title: "Old Name", field: "oldName" },
    { title: "New Name", field: "newName" },
    { title: "Reason", field: "reason" },
    { title: "Status", field: "status" },
    { title: "Requested on", field: "createdAt" },
    { title: "", field: "docs" },
    { title: "", field: "view" },

    //   { title: "Full new name", field: "newName" },
  ];

  const options = {
    exportButton: true,
    pageSizeOptions: [10, 20, 50, 100, 200],
    pageSize: 10,
    exportAllData: true,
    actionsColumnIndex: -1,
  };

  const handleViewRequest = (rowData) => {
    // console.log("Edit action clicked for row:", rowData);
    props.history.push(`/view_name_request/${rowData?.id}`);
  };

  const handleViewDocuments = (rowData) => {
    props.setgetDocsPreview({
      doc1: rowData.affidavit,
      doc2: rowData.proveOfReason,
    });
    props.toggleCONDocsModal();
  };

  return (
    <div classaction="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Action: (props) => (
            <>
              <props.action
                {...props.actionProps}
                onClick={(event) => handleViewRequest(props.data)}
              />
              <props.action
                {...props.actionProps}
                onClick={(event) => handleViewDocuments(props.data)}
              />
            </>
          ),
        }}
        icons={icons}
        options={options}
        columns={columns}
        actions={[]}
        data={props.applications?.map((data) => {
          return {
            oldName: `${data?.formerTitle} ${data?.formerLastname} ${data?.formerFirstname} ${data?.formerMiddlename}`,
            newName: `${data?.newTitle} ${data?.newLastname} ${data?.newFirstname} ${data?.newMiddlename}`,
            reason: `${data?.reason}`,
            status: data?.status,
            docs: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.setgetDocsPreview({
                    doc1: data.affidavit,
                    doc2: data.proveOfReason,
                  });
                  props.toggleCONDocsModal();
                }}
              >
                {" "}
                View Documents
              </span>
            ),
            view: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.history.push(`/view_name_request/${data?.id}`);
                }}
              >
                View Request
              </span>
            ),
            // status: (
            //   <span
            //     style={{
            //       color:
            //         data?.status.toLowerCase() === "pending" ? "red" : "green",
            //       textTransform: "capitalize",
            //     }}
            //   >
            //     {data?.status}
            //   </span>
            // ),
            createdAt: moment(data?.createdAt).format("MMMM Do YYYY"),
          };
        })}
        title="Change of Name"
      />
    </div>
  );
}
