import React, { useState, useEffect, useContext } from 'react';
import Breadcrum from '../../components/shared/breadcrum';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Button } from 'reactstrap';
import ProgressBar from '@ramonak/react-progress-bar';
// import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import TestPdf from './pdfData';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './pdf.css';
import axios from 'axios';
import { PublicationContext } from '../../context/PublicationContext';
import { AuthContext } from '../../context/AuthContext';
import { baseUrl, axiosFormData } from '../../components/helpers/httpMethods';
import { getToken } from '../../components/helpers/authService';
import { NotificationManager } from 'react-notifications';
import { star } from '../../assets/icons/svgs';
import ReducerAction from '../../context/ReducerAction';
import AppHelpers from '../../methods/AppHelper';
import swal from 'sweetalert';
import { PubUploadWrapper } from './css/pubCss';
import { DropBox, GoogleDrive, Url } from '../../assets/upload';

// using CommonJS modules
// import 'react-pdf/dist/umd/Page/AnnotationLayer.css';

let pdfjsLib;
export default function UploadPdf(props) {
  let appState = useContext(AuthContext).state;
  const { setAuth } = useContext(AuthContext);
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingPdf, setLoadingPdf] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);

  const [pdfLoader, setPdfLoader] = useState(false);

  const [editionEdit, setEditionEdit] = useState(false);

  const [openCreatePdfModal, setOpenCreatePdfModal] = useState(false);
  const onOpenCreatePdfModalModal = () => setOpenCreatePdfModal(true);
  const onCloseCreatePdfModal = () => setOpenCreatePdfModal(false);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(false);

  const [percent, setPercent] = useState(10);
  // const [pageNumber, setPageNumber] = useState(1);

  // navigator.connection.onchange = function () {
  //   //do what you need to do ,on speed change event
  //   console.log("Connection Speed Changed");
  // };

  const changeHandler = () => {
    console.log('dd', navigator.connection);
  };

  navigator.connection.onchange = changeHandler();

  useEffect(() => {
    //handlePdf();
    // setInterval(() => {

    //   console.log("recurring");
    // }, 300);

    pdfjsLib = window.pdfjsLib;
  
    return () => {
      clearState();
    };
  }, []);

  useEffect(() => {
    var timer = setInterval(function () {
      if (percent < 90) {
        setPercent(percent + 10);
        console.log('g');
      } else {
        console.log('he');
      }
    }, 500);

    return clearInterval(timer);
  }, [percent]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getConfigurableProps = () => ({
    showArrows: false,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: false,
    swipeable: true,
    dynamicHeight: true,
    emulateTouch: true,
    // thumbWidth: number('thumbWidth', 100, {}, valuesGroupId),
    // selectedItem: number('selectedItem', 0, {}, valuesGroupId),
    interval: 3000,
    transitionTime: 290,
    // swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
  });

  const uploadPdf = async () => {
    setLoading(true);
    setPdfLoader(false);
    setOpenCreatePdfModal(false);
    const fd = new FormData();

    fd.append('file', pdfFile);
    fd.append('image', thumbnail);
    fd.append('publicationId', id);

    let res = await axiosFormData(`/create_edition/${id}`, 'POST', fd, '!load', setPercent);
    if (res) {
      if (res.er) {
        if (res.er.message === 'session expired' || res.er.message === 'Unauthorized, Your token is invalid or expired') {
          setOpenCreatePdfModal(false);
          appState.modals = ReducerAction.modals.expiredToken;
          return AppHelpers.updateAppState();
          // return swal("Session expired", "Your session has expired, please proceed to login to continue.", "error").then(() => {
          //   setAuth(false);
          //   props.history.push(`/`);
          // });
        }
        setOpenCreatePdfModal(false);
        return NotificationManager.error(res.er.message);
      }

      NotificationManager.success('Uploaded Successfully');
      setOpenCreatePdfModal(false);
      console.log('====================================');
      console.log(res);
      console.log('====================================');
      let id = res.data.id;
      props.history.push(`edition/${id}`);
    }
  };

  
  const handlePdf = (e) => {

    console.log('pdf upload',pdfjsLib);
    pdfjsLib.disableWorker = true;

    // Asynchronous download of PDF

    let file = e.target.files[0];
    // console.log(file);
    setPdfFile(file);
    setPdfLoader(true);
    let fileReader = new FileReader();
    fileReader.onload = function (ev) {
      var loadingTask = pdfjsLib.getDocument(fileReader.result);
      loadingTask.promise.then(
        function (pdf) {
          console.log('PDF loaded', pdf._pdfInfo.numPages);
          setLoadingPdf(pdf);

          // let totalPages = pdf._pdfInfo.numPages;

          pdf.getPage(pageNumber).then(function (page) {
            //console.log('Page loaded', i);

            var scale = 1.5;
            var viewport = page.getViewport({ scale: scale });

            var canvas = document.getElementById('the-canvas');
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              console.log('Page rendered');
              if (pageNumber === 1) {
                var file = dataURLtoFile(canvas.toDataURL('image/jpeg'), 'page1.png');
                console.log('====================================');
                console.log('file thub', file);
                console.log('====================================');
                setThumbnail(file);
              }
            });
          });
        },
        function (reason) {
          // PDF loading error
          console.error("pdf upload error", reason);
        }
      );
    };
    fileReader.readAsArrayBuffer(file);
  };

  const clearState = () => {
    console.log('here');
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const getPage = (type) => {
    const pdf = loadingPdf;
    console.log(pageNumber);
    let goToPage = pageNumber;
    if (type === 'next') {
      setPageNumber(pageNumber + 1);
      goToPage = pageNumber + 1;
    } else {
      if (pageNumber === 1) {
        return;
      }
      setPageNumber(pageNumber - 1);
      goToPage = pageNumber - 1;
    }

    pdf.getPage(goToPage).then(function (page) {
      console.log('Page loaded');

      var scale = 1.5;
      var viewport = page.getViewport({ scale: scale });

      // Prepare canvas using PDF page dimensions
      var canvas = document.getElementById('the-canvas');
      var context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      var renderTask = page.render(renderContext);
      renderTask.promise.then(function () {
        console.log('Page rendered');
      });
    });
  };

  return (
    <PubUploadWrapper className="">
      <Breadcrum hide title="Editions - upload PDF" nav={props.history} />
      <div className="upload-pdf__container">
        <div className="upload-pdf__container-header">
          <p className="upload-pdf__container-header-title">Upload PDF</p>
          <div className="sub-title">
            <p>Note: Ensure you upload a non curved pdf for better viewing for your subscribers.</p>
          </div>
        </div>
        {!loading && (
          <div className="upload__box">
            <div className="upload__box--btn">
              <input type="file" onChange={handlePdf} accept=".pdf" />
              <h2>Choose File</h2>
              <div className="mt-2">
                <p>From your computer</p>
              </div>
            </div>

            {/* <div className="upload__box--options">
            <div className="upload__box--options-item">
              <GoogleDrive />
              <div className="ml-2">
                <h3>from google drive</h3>
              </div>
            </div>
            <div className="upload__box--options-item">
              <DropBox />
              <div className="ml-2">
                <h3>from dropbox</h3>
              </div>
            </div>
            <div className="upload__box--options-item">
              <Url />
              <div className="ml-2">
                <h3>from url</h3>
              </div>
            </div>
          </div> */}
          </div>
        )}

        {loading && (
          <div className="upload__box loading">
            <p>Uploading Pdf</p>
            <div className="" style={{ width: '100%', marginTop: '10px' }}>
              <ProgressBar completed={percent} bgColor="#EE7F0D" borderRadius={`2px`} />
            </div>
          </div>
        )}
      </div>
      {/* <div className="upload-pdf-container-wrap my-5">
        <label htmlFor="uploadPdf">Upload Your PDF Here</label>
        <div className="d-flex align-items-center">
          <div className="mt-3 border mr-2">
            <input type="file" onChange={handlePdf} style={{ padding: "10px", borderRadius: "15px" }} accept=".pdf" />
          </div>
          <div className="mt-3 upload-btn">
            <Button onClick={() => setPdfLoader(true)}>Validate PDF</Button>
          </div>
        </div>
      </div> */}

      {/* <div className="pdf-container-wrap-display">
                <TestPdf />
            </div> */}

      <div className="rs-modal-wrap">
        <Modal
          open={openCreatePdfModal}
          onClose={onCloseCreatePdfModal}
          center
          animationDuration={400}
          closeOnOverlayClick={false}
          focusTrapped={true}
        >
          <div className="modal-body-rs">
            <div className="waya-modal-body">
              <h1>Uploading Pdf Please Wait...</h1>
              <div class="lds-hourglass"></div>

              <Carousel {...getConfigurableProps()}>
                <div className="slide-wrap-upload-pdf">
                  <div className="slide-image-wrap-upload-pdf">{star}</div>

                  <p className="upload-pdf-slipe-caption">You can know how much readers are enjoying your publication via reviews and comments.</p>
                </div>

                <div className="slide-wrap-upload-pdf">
                  <div className="slide-image-wrap-upload-pdf ">
                    <img src="../assets/Gallery.png" />
                  </div>
                  <p className="upload-pdf-slipe-caption">
                    You can also combine all your photos into one seamless, fully customizable photo gallery.
                  </p>
                </div>

                <div className="slide-wrap-upload-pdf">
                  <div className="slide-image-wrap-upload-pdf">
                    <img src="../assets/link.png" />
                  </div>
                  <p className="upload-pdf-slipe-caption">
                    You can also combine all your photos into one seamless, fully customizable photo gallery.
                  </p>
                </div>
              </Carousel>
            </div>
          </div>
        </Modal>
      </div>

      <div className="rs-modal-wrap ">
        <Modal open={pdfLoader} onClose={() => setPdfLoader(false)} center animationDuration={400} closeOnOverlayClick={true} focusTrapped={true}>
          <div className="modal-container">
            <div className="control-section">
              <div className="control-section-col1"></div>
              <div className="control-section-col2 ">
                <i class="fa fa-angle-left" onClick={() => getPage('previous')} aria-hidden="true"></i>
                <h2 className="mx-3" style={{ margin: 0, color: '#fff', fontSize: '15px' }}>
                  {pageNumber}
                </h2>
                <i class="fa fa-angle-right " onClick={() => getPage('next')} aria-hidden="true"></i>
              </div>
              <div className="control-section-col3 ">
                <button onClick={() => uploadPdf()}>Next</button>
              </div>

              {/* <button onClick={() => getPage('previous')}>previous</button>
                                <button className="ml-2" onClick={() => getPage('next')}>Next</button> */}
            </div>
            {pdfFile && (
              <div className="">
                <div className="canvas-home">
                  <canvas id="the-canvas" className=""></canvas>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </PubUploadWrapper>
  );
}
