import React from 'react';

export const SizedBox = (props) => {
    return <div style={{
        height: props.height,
        width: props.width,
    }}></div>
}

export const Container = (props) => {
    return <div onClick={props.onClick} className={props.className} style={{
        display: props.display,
        position: props.position,
        justifyContent: props.justifyContent,
        flexDirection: props.direction,
        flexGrow: props.flex,
        flexWrap: props.wrap,
        alignItems: props.alignItems,
        background: props.background,
        width: props.width,
        height: props.height,
        border: props.border,
        borderRadius: props.borderRadius,
        padding: props.padding,
        padding: `${props.paddingVertical} 0`,
        padding: `0 ${props.paddingHorizontal}`,
        margin: props.center ? '0 auto' : props.margin,
        marginTop: props.marginTop,
        left: props.left,
        right: props.right,
        top: props.top,
        bottom: props.bottom,
        cursor: props.cursor,

    }}>
        {props.children}
    </div>
}

export const H1 = (props) => {
    return <h1 className={props.className} style={{
        fontSize: props.fontSize || '1.2vw',
        fontFamily: "Poppins, sans-serif !important",
        letterSpacing: '1px',
        fontWeight: props.fontWeight,
        lineHeight: props.lineHeight || '3vh',
        color: props.color,
    }}>
        {props.children}
    </h1>
}

export const H3 = (props) => {
    return <h3 className={props.className} style={{
        fontSize: props.fontSize || '1.2vw',
        // letterSpacing: '1px',
        fontFamily: props.fontFamily,
        fontWeight: props.fontWeight,
        lineHeight: props.lineHeight || '3vh',
        color: props.color,
    }}>
        {props.children}
    </h3>
}