import styled from "styled-components";

export const ArchiveWrapper = styled.div`
  .Archive-create {
    margin-right: auto;
    margin-left: auto;
    margin-top: 109px;
    width: 60%;
    display: flex;
    .part-1 {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        text-align: left;
        border-bottom: 2px dashed rgba(0, 0, 0, 0.07);
        align-items: center;
        padding: 9px 0px;
        .item-input {
          margin-left: 38px;
          display: flex;
          width: 51%;
          justify-content: space-between;

          input {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            border-radius: 7px;
            padding: 12px 14px;
            width: 100%;
            caret-color: #ee7f0d;

            ::placeholder {
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: #6e7191;
            }
          }
          select {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            border-radius: 7px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #6e7191;
            width: 100%;
            padding: 12px 13px;
            caret-color: #ee7f0d;
          }
        }
        .item-date-box {
          display: flex;
          flex-direction: column;
          width: 47%;
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #ee7f0d;

          .item-date {
            display: flex;
            flex-wrap: nowrap;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            border-radius: 7px;
            padding: 12px 16px;
            justify-content: space-between;
            img {
              margin-right: 0px;
              width: 18.32px;
              height: 20px;
            }
            .cont {
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: #6e7191;
            }
          }
        }

        .item-name {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #334d6e;
          width: 40%;
          .sub-name {
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 12px;
            color: #999999;
            display: flex;
            align-items: flex-start;
            width: 70%;
            img {
              margin-right: 13px;
            }
          }
        }
      }
      .p-1-button {
        margin-top: 44px;
        margin-left: auto;
        margin-right: auto;
        button {
          background: #ee7f0d;
          border-radius: 5px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          padding: 9.5px 36px;
        }
      }
    }
    .part-2 {
      width: 20%;
      display: flex;
      margin-left: 24px;
      align-items: flex-start;
      font-family: Poppins;
      font-style: italic;
      font-weight: 300;
      font-size: 10px;
      line-height: 15px;
      color: #999999;
      margin-top: 25px;
      img {
        margin-right: 9px;
      }
    }
  }
`;
