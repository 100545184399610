import React, { useContext, useState, useRef } from "react";
import { Modal } from "react-responsive-modal";
import { AuthContext } from "../../context/AuthContext";
import ReducerAction from "../../context/ReducerAction";
import AppHelpers from "../../methods/AppHelper";
import AuthFunctions from "../../methods/AuthFunctions";
import { LoaderAction } from "../helpers/loader";
import { SessionExpiredCss } from "./modalsCss";
import swal from "sweetalert";

export const SessionExpiredModal = ({ open, navigation }) => {
  let appState = useContext(AuthContext).state;
  const [showPass, setShhowPass] = useState(true);

  const inputRef = useRef({
    email: "",
    password: "",
  });

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={() => {
            appState.modals = null;
            AppHelpers.updateAppState();
          }}
          center
          closeOnEsc={false}
          closeOnOverlayClick={false}>
          <SessionExpiredCss>
            <div className="inner-modal-wrap-mLogin">
              <div style={{ marginBottom: "0px" }} className="login-wrap-home-input">
                <h1 className="login-modal-header-text">
                  Your session has expired
                  <br />
                  please login again
                </h1>
                <form className="login-form-wrap">
                  <div className="login-col-three-home">
                    <div className="inPutHomeWrapP">
                      <input
                        name="email"
                        onChange={(val) => {
                          inputRef.current.email = val.target.value;
                        }}
                        placeholder="Enter email address"
                        type="text"
                      />
                      {/* <div className="error-container">
                    <span
                      style={
                        errorInput.email === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.email}
                    </span>
                  </div> */}
                      <input
                        style={{ marginTop: "15px" }}
                        onChange={(val) => {
                          inputRef.current.password = val.target.value;
                        }}
                        name="password"
                        placeholder="Enter password"
                        type={`${showPass ? "text" : "password"}`}
                      />
                      <div className="toggleViewPassword">
                        <i onClick={() => setShhowPass(!showPass)} className={`fas ${showPass ? " fa-eye" : " fa-eye-slash"}`}></i>
                        {/* <i class="fas fa-eye-slash"></i> */}
                      </div>

                      {/* <div className="error-container">
                    <span
                      style={
                        errorInput.password === "Looks Good!"
                          ? { color: "green" }
                          : {}
                      }
                      className="AppError"
                    >
                      {errorInput.password}
                    </span>
                  </div> */}
                    </div>
                  </div>
                </form>
                <div className="submit-login-details-home">
                  <button
                    onClick={() => {
                      LoaderAction(true);
                      AuthFunctions.reLoginUser(inputRef.current, navigation);
                    }}>
                    Login to your account
                  </button>
                </div>
                <div
                  className="logoutBtnLoginModal"
                  onClick={() => {
                    console.log("dfs");
                    swal("Session expired", "Are you sure you want to logout?", "warning", {
                      buttons: ["Cancel", "Logout"],
                      dangerMode: true,
                      closeOnClickOutside: true,
                    }).then((val) => {
                      if (!val) {
                        return;
                      }
                      appState.modals = null;
                      AppHelpers.updateAppState();

                      AuthFunctions.logout(navigation);
                      //   deleteEdition(id);
                    });
                  }}>
                  <span>LOGOUT</span>
                </div>
              </div>
            </div>
          </SessionExpiredCss>
        </Modal>
      )}
    </>
  );
};
