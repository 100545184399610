import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import warningIcon from "../../assets/images/warning.png";
import { axiosCalls, httpGet } from "./../../components/helpers/httpMethods";
import ChangeOfNameRequests from "./changeOfNameRequest";
import swal from "sweetalert";
import pendCn from "../../assets/images/pendCn.png";
import { showLoader, hideLoader } from "../../components/helpers/loader";
import { Modal } from "react-responsive-modal";
import { PublicationContext } from "../../context/PublicationContext";
import axios from "axios";

export default function Index({ history }) {
  const { currentPublication: id, reload } = useContext(PublicationContext);
  let [showTable, setShowTable] = useState(false);
  let [VendorStatus, setVendorStatus] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    setVendorStatus("");
    showChangeOfNameVendors();

    checkVendorChangeOfNameStatus();
    document.body.style.backgroundColor = "#e5e5e558";
    return () => {
      document.body.style.backgroundColor = "white";
      //   elementsArray[0].style.backgroundColor = "white";
    };
  }, [reload]);

  const getName = () => {
    axios
      .get(`https://trustpaddi.herokuapp.com/auth/profile/${"kingifean@gmail.com"}`, {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImtpbmdpZmVhbkBnbWFpbC5jb20iLCJ1c2VySWQiOiI2MjQ3NmRjNmJkNGI1OTU0MzgzMTVmMzYiLCJpYXQiOjE2NTM4NTUxODUsImV4cCI6MTY1NDQ1OTk4NX0.t7YEZ0wB62tHsJRvEhdzZM2MEw_0PFJC99yJo3NQcBQ`
        }
      })
      .then((res) => {
        console.log("getProfile:======= ", res);
      })
      .catch((err) => {
        console.log("getProfile:======= error", err);
      });
  };

  useEffect(() => {
    getName();
  }, []);

  useEffect(() => {
    getPub();
  }, [reload]);

  const getPub = async () => {
    let res = await httpGet(`publication/${id}`);
    if (res) {
      if (!res.success) {
        return;
      }
      setName(res.data.publication.name);
    }
  };

  const [applications, setApplications] = useState([]);

  const showChangeOfNameVendors = async () => {
    showLoader();
    const res = await httpGet("con/applications");
    if (res.code == 200) {
      hideLoader();
      console.log(res.data.conApplications);
      setApplications(res.data.conApplications);
    }
    hideLoader();
  };

  const checkVendorChangeOfNameStatus = async () => {
    let res = await axiosCalls("/con/subscription_status");
    console.log("here");
    if (res) {
      if (res.er) {
        console.log("this is the error from dsdfdsf", res.er);
        setVendorStatus("non");
        return setShowTable(false);
      }
      setShowTable(true);
      console.log(">>>>", res.data.conSubscription);
      setVendorStatus(res.data.conSubscription.status);
    }
  };

  const [amount, setAmount] = useState("");
  const [pubDays, setPubDays] = useState([]);
  const [turnAroundTime, setTurnAroundTime] = useState("");

  const handleSetDayToPub = (e) => {
    console.log("CurrentStae", pubDays);
    const { checked, value } = e.target;
    if (checked) {
      setPubDays([...pubDays, value]);
      console.log("1", checked);
    } else {
      let data = pubDays.filter((v) => v !== value);
      // console.log(data);
      setPubDays(data);
      console.log("2", checked);
    }

    // console.log(pubDays);
    //console.log(checked);
  };

  const handleSubmit = async () => {
    showLoader();
    console.log(amount, turnAroundTime, pubDays);
    const data = {
      price: amount,
      publishDays: pubDays,
      turnAroundTime: turnAroundTime,
    };
    const res = await axiosCalls("/con/subscribe", "post", data);
    if (res.er) {
      swal("Error", res?.er?.message, "error");
    } else {
      setAmount("");
      setPubDays("");
      setTurnAroundTime("");
      swal(
        "Success",
        "You have successfully opted for the Change of Name service.",
        "success"
      );
      checkVendorChangeOfNameStatus();
    }
    hideLoader();
  };
  const [getDocsPreview, setgetDocsPreview] = useState({
    doc1: "",
    doc2: "",
  });
  const [conModalT, setconModalT] = useState(false);
  const toggleCONDocsModal = () => {
    setconModalT(!conModalT);
  };
  return (
    <div>
      {VendorStatus === "active" && (
        <ChangeOfNameRequests
          applications={applications}
          setgetDocsPreview={setgetDocsPreview}
          toggleCONDocsModal={toggleCONDocsModal}
          history={history}
        />
      )}

      {VendorStatus === "pending" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "450px",
              borderRadius: "5px",
              flexDirection: "column",
              padding: "15px",
            }}
          >
            <img
              src={pendCn}
              style={{ width: "350px", height: "300px" }}
              alt=""
            />
            <p
              style={{ textAlign: "center", padding: "12px", fontSize: "15px" }}
            >
              Your request is still being processed by{" "}
              <span style={{ color: "#ee7e0dc5" }}>TheNewspaperStand</span>, an
              email will be sent to you once you have been approved for this
              service.
            </p>
          </div>
        </div>
      )}

      {VendorStatus === "non" && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="changeOfNameHeroImg">
              <h1>Change of Name</h1>
              <p>Subscribe to TheNewspaperStand Change of Name service.</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <div className="changeOfNameSetUp">
              <div className="fillCnCol">
                <div className="fillCnColCol1">
                  <h1>Name of Publication</h1>
                </div>
                <div className="fillCnColCol2">
                  <input
                    disabled
                    type="text"
                    placeholder={name}
                    className="kwegqo"
                  />
                </div>
              </div>

              <div className="lineBreakCNsec"></div>

              <div className="fillCnCol">
                <div className="fillCnColCol1">
                  <h1>Amount</h1>
                </div>
                <div className="fillCnColCol2">
                  <input
                    type="text"
                    className="kwegqo"
                    style={{ background: "#FFFFFF" }}
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>

              <div className="lineBreakCNsec"></div>

              <div className="fillCnCol">
                <div className="fillCnColCol1">
                  <h1>Days</h1>
                  <p className="qpgdscbCnAME">
                    <img src={warningIcon} alt="" />
                    <span>Days of the week you publish change of name</span>
                  </p>
                </div>
                <div className="fillCnColCol2">
                  <ul>
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        value="Monday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />{" "}
                      Monday
                    </li>
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        value="Tuesday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />{" "}
                      Tuesday
                    </li>
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        value="Wednesday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />{" "}
                      Wednesday
                    </li>
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        value="Thursday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />{" "}
                      Thursday
                    </li>
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        value="Friday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />{" "}
                      Friday
                    </li>
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        value="Saturday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Saturday
                    </li>
                    <li>
                      {" "}
                      <input
                        type="checkbox"
                        value="Sunday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />{" "}
                      Sunday
                    </li>
                  </ul>
                </div>
              </div>

              <div className="lineBreakCNsec"></div>

              <div className="fillCnCol">
                <div className="fillCnColCol1">
                  <h1>Turn around time</h1>
                </div>
                <div className="fillCnColCol2">
                  <select
                    className="kwegqo"
                    style={{ background: "#FFFFFF" }}
                    onChange={({ target: { value } }) =>
                      setTurnAroundTime(value)
                    }
                    value={turnAroundTime}
                  >
                    <option value="">--select no of days--</option>
                    <option value="3 Days">3 Days</option>
                    <option value="5 Days">5 Days</option>
                    <option value="7 Days">7 Days</option>
                    <option value="14 Days">14 Days</option>
                  </select>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                  marginBottom: "40px",
                }}
              >
                <button
                  style={{
                    backgroundColor: "#EE7F0D",
                    padding: "10px",
                    width: "140px",
                    borderRadius: "4px",
                    border: "none",
                    color: "white",
                  }}
                  onClick={handleSubmit}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="comfirmPaymentModal">
        <Modal open={conModalT} onClose={toggleCONDocsModal} center>
          <div
            className="inner-modal-wrap"
            style={{ width: "600px", background: "white" }}
          >
            <div className="proveOfReasonDocsCON">
              <div className="proveOfReasonCol1">
                <p style={{ textAlign: "center" }}>Affidavit</p>
                <img src={getDocsPreview.doc1} alt="" />
                <div className="downloadProveOfReasonDocs">
                  <a download={getDocsPreview.doc1} href={getDocsPreview.doc1}>
                    Download Document
                  </a>
                </div>
              </div>

              <div className="proveOfReasonCol1">
                <p>Prove Of Reason</p>
                <img src={getDocsPreview.doc1} alt="" />
                <div className="downloadProveOfReasonDocs">
                  <a download={getDocsPreview.doc2} href={getDocsPreview.doc2}>
                    Download Document
                  </a>
                </div>
              </div>
            </div>
            <div className="closeCONdocModald">
              <button onClick={toggleCONDocsModal}>Close</button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
