import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { submit } from "../../controllers/login";
import style from "styled-components";
// import AppLogo from "../../assets/images/appLogo.png";
import { home, bgBottom, Read } from "../../assets/icons/svgs";
import bg from "../../assets/images/bg.png";
import { ValidateEmail, ValidateInput } from "../../components/helpers/validateInput";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import MyComponent from "react-fullpage-custom-loader";
import { Link } from "react-router-dom";
import { httpPostFormData, httpPut, httpPatch, httpGet, httpPost, httpDelete, axiosCalls } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import { AuthContext } from "../../context/AuthContext";
import "./login.css";
import countryList from "react-select-country-list";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import swal from "sweetalert";
import { Scrollbars } from "react-custom-scrollbars";
import { AppLogo } from "../../assets/svg";
import Logo from "../../assets/images/appLogo.png";

const Login = (props) => {
  const { auth, loading, changeLoading } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [shortInfo, setShortInfo] = useState([
    { title: "Create Publication", desc: "Start publishing your Newspapers, Magazines, Books or White papers in minutes and reach millions of readers across the globe." },
    { title: "Real-time Tracking", desc: "Track sales and subscribers' action real-time with our easy to use portal." },
    { title: "Make More Money", desc: "Sell a single edition or subscription of your publication." },
    // { title: 'Subscribe to Managed Services', desc: 'As a publisher you can subscribe to some of our add-on services such as Change of Name, publication archive request and many more. ' },
  ]);

  const country = useRef(null);
  const [secure, setSecure] = useState(true);

  const [userAccount, setUserAccount] = useState({
    type: "",
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    accountType: "",
    activeInput: "",
    country: "",
  });
  const [userCountry, setuserCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [userNumber, setuserNumber] = useState("");

  const [accountType, SetaccountType] = useState("individual");
  const [setValidatedForm, setValidated] = useState(false);
  const [emailOptionText, setEmailOptionText] = useState(false);
  const [errorInput, setErrorInput] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: "",
  });

  const [terms, setTerms] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const toggleSeePasswordIcon = () => {
    setShowPass(!showPass);
  };

  const [termAndConditionCheck, SettermAndConditionCheck] = useState(false);

  useEffect(() => {
    if (auth) {
      // props.history.push('/home');
      return changeLoading(false);
    }
    changeLoading(false);
  }, []);

  const changeHandler = (value) => {
    // alert(value.label);
    setuserCountry(value);
  };

  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, firstName: testValid });
      if (testValid !== "Valid") {
        return false;
      }
    }

    if (e.target.name === "country") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, country: testValid });
      if (testValid !== "Valid") {
        return false;
      }
    }

    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.name === "header_image") {
      //  setUserAccount({...offers,header_image:e.target.files[0], previewHeaderImg: URL.createObjectURL(e.target.files[0])})
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Valid details", setValidatedForm);
    if (!termAndConditionCheck) {
      return NotificationManager.info("Please accept terms and conditions to continue");
    }
    if (errorInput.password === "Valid" && errorInput.email === "Valid" && errorInput.firstName === "Valid" && userCountry !== "") {
      console.log("validated");

      // showLoader();
      // return;
      const data = {
        businessName: userAccount.firstName,
        email: userAccount.email,
        country: userCountry.label,
        password: userAccount.password,
        emailOpt: emailOptionText,
      };
      console.log(data);
      // return;
      let newData = accountType === "corporate" ? { ...data, companyName: userAccount.companyName } : data;
      let res = await axiosCalls(`/auth/register_company`, "POST", newData);
      if (res) {
        console.log("there is res", res);
        if (res.er) {
          console.log("there is an error", res.er);
          NotificationManager.error(Object.values(res.er.data).join("  "));
          return;
        }
        setUserAccount({
          type: "",
          companyName: "",
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          accountType: "",
          activeInput: "",
        });
        swal("Account Created!", "In order to complete the sign-up process, please click the confirmation link sent to your registered email. If you do not receive a confirmation email, please check your spam folder also.", "success");
        props.history.push("/");
      }
    } else {
      console.log("not validated");

      NotificationManager.error("Please fill in all input.");
    }
  };
  return (
    <div className="login-wrap-v2">
      <div className="login-wrap-v2-col1">
        <AppLogo />
      </div>

      <div className="login-wrap-v2-col2">
        <div className="signUpOptonLogin">
          <p>
            Already have an Account?{" "}
            <span>
              {" "}
              <Link to="/">Sign In</Link>
            </span>
          </p>
        </div>

        <div className="mobileViewLogoV2">
          <img src={Logo} alt="" />
        </div>

        <div className="login-wrapv2-main">
          <h1 className="login-txt-header">Sign Up</h1>
          <form>
            {accountType === "individual" ? (
              <div>
                <div className="inputWrap-v2">
                  <label>Business Name</label>

                  <input name="firstName" onChange={handleChange} placeholder="Enter business name" type="text" value={userAccount.firstName} />
                  <span style={errorInput.firstName === "Looks Good!" ? { color: "green" } : {}} className="AppError">
                    {errorInput.firstName}
                  </span>
                </div>

                <div className="inputWrap-v2">
                  <label>Email address</label>
                  <div className="input-create-acc">
                    <input name="email" onChange={handleChange} placeholder="Enter email address" type="text" value={userAccount.email} />
                    <span style={errorInput.email === "Looks Good!" ? { color: "green" } : {}} className="AppError">
                      {errorInput.email}
                    </span>
                  </div>
                </div>

                <div className="inputWrap-v2">
                  <label>Select Country</label>
                  <div className="input-create-acc input-wrap-create-acc  " style={{ width: "90%" }}>
                    <Select className="dfsdf" options={options} value={userCountry} onChange={changeHandler} />
                  </div>
                </div>

                <div className="inputWrap-v2">
                  <label>Password</label>
                  <div className="input-create-acc">
                    <input name="password" onChange={handleChange} placeholder="Enter password" type="password" value={userAccount.password} type={`${showPass ? "text" : "password"}`} />
                    <div className="togglePasscreAcc">
                      <i onClick={toggleSeePasswordIcon} className={`fas ${showPass ? " fa-eye" : " fa-eye-slash"}`}></i>
                      {/* <i class="fas fa-eye-slash"></i> */}
                    </div>

                    <span style={errorInput.password === "Looks Good!" ? { color: "green" } : {}} className="AppError">
                      {errorInput.password}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="create-acc-newlette" style={{ marginTop: "40px" }}>
              <h1>Allow email communications:</h1>
              <p>
                <input onClick={() => setEmailOptionText(!emailOptionText)} type="checkbox" />
                General Update, New Issues and topic trends
              </p>
            </div>

            <div style={{ marginTop: "23px" }} className="create-acc-newlette">
              <h1>Terms and condition:</h1>
              <p>
                <input checked={termAndConditionCheck} onClick={() => SettermAndConditionCheck(!termAndConditionCheck)} type="checkbox" />I understand and agree to the{" "}
                <a target="blank" href="https://thenewspaperstand.com/terms-conditions/">
                  <span style={{ color: "orange", cursor: "pointer" }}>TNS terms and condition </span>
                </a>{" "}
                and
                {"\n"}
                <br className="hdCreM" />
                <a target="blank" href="https://thenewspaperstand.com/privacy-policy/">
                  <span
                    style={{
                      color: "orange",
                      cursor: "pointer",
                      marginLeft: "32px",
                      top: "-7px",
                      position: "relative",
                    }}
                    className="pPMr">
                    Privacy Policy
                  </span>
                </a>
              </p>
            </div>

            <div className="loginBtnV2" style={{ marginBottom: "30px" }}>
              <button onClick={handleSubmit}>Create my account</button>
            </div>

            <div className="haveanAccountV2Op">
              <p>
                {" "}
                Already have an Account?
                <Link to="/"> SIGN IN</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
