import React, { useState } from 'react'
import Breadcrum from '../../components/shared/breadcrum';
import SalesTable from '../../components/tables/Sales'
import SalesStatcs from './salesStacts'
import './sales.css'
export default function Sales() {
    const [activeTab, setActiveTab] = useState("summary")
    return (
        <React.Fragment>
            <Breadcrum title="Sales Report" />
            <h1>COMING SOON</h1>

            {/* <div className="tab-control-sales">
                <div onClick={() => setActiveTab("summary")} className="tab-one">
                    <p>Summary</p>
                    {
                        activeTab === "summary" ? <div className="active-tab-sales"></div> : ""
                    }

                </div>

                <div onClick={() => setActiveTab("DetailedReport")} className="tab-two">
                    <p>Detailed Report</p>
                    {
                        activeTab === "DetailedReport" ? <div className="active-tab-sales"></div> : ""
                    }
                </div>

            </div> */}

            {/* <div className="sales-record-wrap">

                {
                    activeTab === "summary" ?
                        <div className="sales-switch-wrap">
                            <h1 className="sales-graph-header">You’ve earned NGN 180,000 this month.</h1>
                            <div className="sales-graph-wrap">
                                <SalesStatcs />
                            </div>

                        </div>
                        : <SalesTable />
                }

            </div> */}

        </React.Fragment>
    )
}
