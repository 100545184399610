import React from "react";
import { Line as LineChart } from "react-chartjs-2";

function chartData() {
  return {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],

    datasets: [
      {
        label: "Publication",
        data: [800, 160, 1000, 180, 56, 100, 920, 1000, 180, 56, 100, 1220],
        fill: false,
        backgroundColor: "transparent",
        borderColor: "#3751FF",
        borderWidth: 1.5,
        pointStyle: "rectRounded",
      },
      {
        label: "Change of name",
        data: [90, 250, 150, 100, 400, 70, 1220],
        fill: false,
        backgroundColor: "transparent",
        borderColor: "#DFE0EB",
        borderWidth: 1.5,
        pointStyle: "rectRounded",
      },
    ],
  };
}

const options = {
  legend: {
    display: false,
    labels: {
      color: "rgb(255, 99, 132)",
    },
  },
  scales: {
    color: "red",
  },
};

class OverviewChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: chartData(),
    };
  }

  render() {
    return (
      <div className="chart-canvas">
        {" "}
        <LineChart type="line" options={options} data={this.props.data} />
      </div>
    );
  }
}

export default OverviewChart;
