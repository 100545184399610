import React, { useState, useEffect, useContext } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import moment from "moment";
import "./table.css";
import more from "../../assets/More.svg";
import truncate from "../../utils/truncate";

export default function index(props) {
  const { showModal, closeModal, applications, setUploadList, setUploadId } =
    props;

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Customer Name", field: "customerName" },
          { title: "Date Requested", field: "dateRequested" },
          { title: "Numbers Of Request", field: "noOfRequest" },
          { title: " Status", field: "status" },
          { title: " Date Submitted", field: "dateModified" },
          { title: " Upload", field: "upload" },
        ]}
        data={applications.map((data) => {
          return {
            customerName: (
              <span>{`${data.user?.firstName} ${data.user?.lastName}`}</span>
            ),
            dateRequested: (
              <span>
                {truncate(
                  JSON.parse(data?.requestDates)
                    .map(
                      (date) => ` ${moment(date?.date).format("MMMM Do YYYY")} `
                    )
                    .toString(),
                  90
                )}
              </span>
            ),
            noOfRequest: (
              <span className="w-100">
                {JSON.parse(data?.requestDates).length}
              </span>
            ),
            status: (
              <span
                style={{
                  background:
                    data.status.toLowerCase() === "completed"
                      ? "rgb(187 249 176)"
                      : "#FFECD8",
                  color:
                    data.status.toLowerCase() === "completed"
                      ? "rgb(47 218 92)"
                      : "rgb(241 79 17)",
                  textTransform: "capitalize",
                  padding: "4px 8px",
                }}
              >
                {data?.status}
              </span>
            ),
            dateModified: moment(data?.createdAt).format("MMMM Do YYYY"),
            upload: (
              <span className="d-flex align-items-center ">
                <img
                  src={more}
                  alt=""
                  onClick={() => {
                    if (data.status.toLowerCase() === "completed") {
                      return false;
                    }
                    setUploadList(JSON.parse(data?.requestDates));
                    setUploadId(data?.id);
                    closeModal(!showModal);
                  }}
                />
              </span>
            ),
          };
        })}
        title="Archives"
      />
    </div>
  );
}
