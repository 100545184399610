import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert";
import { axiosCalls } from "./../../components/helpers/httpMethods";
import { PublicationContext } from "../../context/PublicationContext";
import {
  httpGet,
  httpPatch,
  // httpPost,
  httpPostData,
} from "../../components/helpers/httpMethods";
import { PubNotWrapper } from "./style";
import "./index.css";
import pendCn from "../../assets/images/pendCn.png";
import back from "../../assets/back.svg";
import warningIcon from "../../assets/warning.svg";
// import upload from "../../assets/upload2.svg";
// import info from "../../assets/info.svg";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import PublicNoticeList from "../../components/tables/publicNoticeList";

const Index = () => {
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [turnAroundTime, setTurnAroundTime] = useState("");
  const [pnStatus, setPnStatus] = useState("");
  const [pubDays, setPubDays] = useState({});
  const [applications, setApplications] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    setPnStatus("");
    getSinglePub();
    getPublicNoticeStatus();
    getPnApplications();
  }, [reload]);

  const getSinglePub = async () => {
    if (id) {
      let res = await httpGet(`publication/${id}`);
      if (res) {
        if (!res.success) {
          return;
        }
        setName(res.data.publication.name);
      }
    }
  };

  const getPublicNoticeStatus = async () => {
    let res = await httpGet(`pn/subscription_status`);
    if (res?.success) {
      setPnStatus(res.data?.pnSubscription?.status);
    } else {
      setPnStatus("false");
    }
  };

  const getPnApplications = async () => {
    let res = await httpGet(`pn/applications`);
    if (res) {
      setApplications(res.data?.pnApplications);
    }
    return;
  };

  const handleSetDayToPub = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setPubDays({ ...pubDays, [e.target.id]: value });
    } else {
      const newPubDays = { ...pubDays };
      delete newPubDays[e.target.id];
      setPubDays(newPubDays);
    }
  };
  const handleSubmit = async () => {
    showLoader();
    console.log(price, turnAroundTime, pubDays);
    const data = {
      price,
      publishDays: Object.values(pubDays),
      turnAroundTime,
    };
    const res = await axiosCalls("pn/subscribe", "post", data);
    if (res.er) {
      swal("Error", res?.er?.message, "error");
    } else {
      setPrice("");
      setPubDays("");
      setTurnAroundTime("");
      swal(
        "Success",
        "You have successfully opted for Publc Notice service.",
        "success"
      );
      getPublicNoticeStatus();
    }
    hideLoader();
  };

  const handleUpdateStatus = async (id) => {
    showLoader();

    const newForm = new FormData();
    newForm.append("publishedDate", Date.now());

    let res = await httpPatch(`pn/complete_process/${id}`, newForm);
    if (res) {
      console.log("pnA>>>", res);
      if (!res.success) {
        swal("error", res.message, "error");
        hideLoader();
        return;
      }
      getPnApplications();
      getPublicNoticeStatus();
      hideLoader();
    }
  };

  return (
    <PubNotWrapper>
      {pnStatus === "false" && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="changeOfNameHeroImg">
              <h1>Public notice</h1>
              <p className="text-center">
                Notify important bodies about the status of your document and
                business
              </p>
            </div>
          </div>

          <div className="Archive-create">
            <div className="part-1">
              <div className="item">
                <div className="item-name">Name of Publication</div>
                <div className="item-input">
                  <input
                    disabled
                    type="text"
                    className=" "
                    style={{ background: "#EEEEEE" }}
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="item">
                <div className="item-name">
                  <div>Amount </div>
                  <div className="sub-name">Per archive request</div>
                </div>
                <div className="item-input">
                  <input
                    type="text"
                    className=""
                    placeholder="Enter Amount"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>
              <div className="item">
                <div
                  className="item-name "
                  style={{
                    marginTop: "-6rem",
                  }}
                >
                  <div>Days</div>
                  <div className="sub-name">
                    <img src={warningIcon} alt="" />
                    <span>Days of the week you publish change of name</span>
                  </div>
                </div>
                <div
                  className="fillCnColCol2"
                  style={{
                    width: "51%",
                    marginLeft: "38px",
                  }}
                >
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        value="Monday"
                        id="Monday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Monday
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Tuesday"
                        id="Tuesday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Tuesday
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Wednesday"
                        id="wednesday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Wednesday
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Thursday"
                        id="Thursday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Thursday
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Friday"
                        vaidlue="Friday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Friday
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Saturday"
                        id="Saturday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Saturday
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        value="Sunday"
                        id="Sunday"
                        onClick={(e) => handleSetDayToPub(e)}
                      />
                      Sunday
                    </li>
                  </ul>
                </div>
              </div>
              <div className="item border-0 ">
                <div className="item-name">Turn around time</div>
                <div className="item-input">
                  <select
                    value={turnAroundTime}
                    onChange={(e) => setTurnAroundTime(e.target.value)}
                  >
                    <option value="" disabled>
                      --select no of days--
                    </option>
                    <option value="3">3 Days</option>
                    <option value="5">5 Days</option>
                    <option value="7">7 Days</option>
                    <option value="14">14 Days</option>
                  </select>
                </div>
              </div>
              <div className="p-1-button">
                <button onClick={handleSubmit}>Proceed</button>
              </div>
            </div>
            <div className="part-2">
              <img src={warningIcon} alt="" />
              <div>
                This field can not be changed. To change this, kindly selct
                another publication on the top right bar.
              </div>
            </div>
          </div>
        </div>
      )}

      {pnStatus === "active" && (
        <PublicNoticeList
          applications={applications || []}
          setPnStatus={setPnStatus}
          setData={setData}
        />
      )}
      {pnStatus === "pending" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "450px",
              borderRadius: "5px",
              flexDirection: "column",
              padding: "15px",
              border: "1px solid #f6f6f6",
            }}
          >
            <img
              src={pendCn}
              style={{ width: "350px", height: "300px" }}
              alt=""
            />
            <p
              style={{
                textAlign: "center",
                padding: "12px",
                fontSize: "15px",
              }}
            >
              Your request is still being processed by
              <span style={{ color: "#ee7e0dc5" }}>TheNewspaperStand</span>, an
              email will be sent to you once you have been approved for this
              service.
            </p>
          </div>
        </div>
      )}
      {pnStatus === "Change of businees name" && (
        <div className="publicNotice">
          <div className="nav">
            <div onClick={() => setPnStatus("active")} className="nav-back">
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div>
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Change of business name</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Old business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.oldBussinessName}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">New business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.newBussinessName}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Business Address</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.businessAddress}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.bussinessTrustee1}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.bussinessTrustee2}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Signed by</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.signedBy}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pnStatus === "Business registration" && (
        <div className="publicNotice">
          <div className="nav">
            <div onClick={() => setPnStatus("active")} className="nav-back">
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div>
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Business Registration</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.bussinessName}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">business Address</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.houseAddress}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.bussinessTrustee1}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Business Trustees</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.bussinessTrustee2}
                  </div>
                </div>
              </div>

              <div className="det-item">
                <div className="det-head">Business aim & Goals</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.aimsGoals}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Signed by</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.signedBy}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pnStatus === "Change of board of trustees" && (
        <div className="publicNotice">
          <div className="nav">
            <div onClick={() => setPnStatus("active")} className="nav-back">
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div>
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Change of business trustees</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Business name</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.bussinessName}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Address</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.houseAddress}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Old Business Trustees</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.bussinessTrustee1}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">New Business Trustees</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.bussinessTrustee2}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Signed by</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.signedBy}
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pnStatus === "Loss of document" && (
        <div className="publicNotice">
          <div className="nav">
            <div onClick={() => setPnStatus("active")} className="nav-back">
              <img src={back} alt="" />
              <div>Public notice</div>
            </div>
          </div>
          <div className="p-n-cont">
            <div className="status-div">
              <div className="s-d-1">
                <div className="sd-1-up">Status</div>
                <div
                  className={
                    data?.status === "paid" ? "sd-1-down-p" : "sd-1-down"
                  }
                >
                  {data?.status === "paid" ? "Scheduled" : " Published"}
                </div>
              </div>
              <div
                className="s-d-2"
                onClick={() => {
                  handleUpdateStatus(data?.id);
                }}
              >
                Change status
              </div>
            </div>
            <div className="p-n-title">
              <div className="p-n-top">Notice type</div>
              <div className="p-n-bot">Loss of Document</div>
            </div>
            <hr />
            <div className="detail-cont">
              <div classNmae="p-n-det">Details</div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Full Name</div>
                  <div className="det-cont">
                    {`${JSON.parse(data?.informations)?.firstName} ${
                      JSON.parse(data?.informations)?.lastName
                    }`}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Document ID</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.documentId}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="det-item">
                  <div className="det-head">Type of Document</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.typeOfDoc}
                  </div>
                </div>
                <div className="det-item">
                  <div className="det-head">Date of loss</div>
                  <div className="det-cont">
                    {JSON.parse(data?.informations)?.dateOfLoss}
                  </div>
                </div>
              </div>
              <div className="det-item">
                <div className="det-head">Declaration</div>
                <div className="det-cont">
                  {JSON.parse(data?.informations)?.declearation}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PubNotWrapper>
  );
};

export default Index;
