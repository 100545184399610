import socketIO from "socket.io-client";
import { getToken } from "../components/helpers/authService";
import { baseUrl } from "../components/helpers/httpMethods";
import { appState } from "../context/AuthContext";
import AppHelpers from "./AppHelper";

const AppSocket = {};

AppSocket.createConnection = async () => {
  if (AppSocket.io?.connected) return; // if there has been a connection before, return
  //open a connection
  let userToken = await getToken();
  var connectionOptions = {
    "force new connection": true,
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
  };
  AppSocket.io = socketIO(`${baseUrl}?token=${userToken}`, connectionOptions);

  //listen for connection
  AppSocket.io.on("connect", () => {
    AppSocket.io.emit("notifications");
  });

  AppSocket.io.on("notifications", (data) => {
    appState.notifications = [...appState.notifications, ...data];
    AppHelpers.updateAppState();
  });

  AppSocket.io.on("notification", (data) => {
    appState.notifications = [...appState.notifications, data];
    AppHelpers.updateAppState();
  });
};

export default AppSocket;
