const ReducerAction = {};

ReducerAction.store = {};
ReducerAction.store.updateState = "update_app_state";
ReducerAction.store.resetStore = "reset_app_state";
ReducerAction.store.toggleLogout = "toggle_logout";

//for loader all over the app
ReducerAction.loader = {};
ReducerAction.loader.signUp = "show_loader_for_sign_up";
ReducerAction.loader.signUpSuccess = "show_loader_for_success";

ReducerAction.modals = {};
ReducerAction.modals.expiredToken = "token_expired_login";

export default ReducerAction;
