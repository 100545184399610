import React, { useState, useEffect, useContext } from 'react';
import '../dashboard/index.css';
import { getPublicationTypes } from '../../controllers/publication/getPublications';

// contexts
import { AuthContext } from '../../context/AuthContext';
import { PublicationContext } from '../../context/PublicationContext';
import { DashboardContext } from '../../context/DashboardContext';
import { SettingsContext } from '../../context/SettingsContext';
import numeral from 'numeral';
import swal from 'sweetalert';
import bg2 from '../../assets/images/bgPub.jpg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Switch from 'react-switch';

import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-toastify/dist/ReactToastify.css';
import { hideLoader, showLoader } from '../../components/helpers/loader';
import {
  httpPostData,
  axiosCalls,
  httpGet,
} from '../../components/helpers/httpMethods';
import {
  getLocalItem,
  setLocalItem,
} from '../../components/helpers/authService';
import { Modal } from 'react-responsive-modal';

import { Withdraw } from '../../assets/icons/svgs';
import moment from 'moment';
import style from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

const Home = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [pubType, setPubType] = useState([]);
  const { setAuth, onBording, setOnBording } = useContext(AuthContext);
  const { renderOpt, setRenderOpt } = useContext(DashboardContext);
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const { setPubReload, pubReload, currentPublication } =
    useContext(PublicationContext);
  const { activeState, setActiveState } = useContext(SettingsContext);
  const [wallet, setWallet] = useState({});
  // const [renderOpt, setRenderOpt] = useState('create');
  const [selectedCat, setSelectedCat] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [selectedOften, setSelectedOften] = useState('');
  const [pub, setPub] = useState({});

  const [withdrawalAmount, setWithdrawalAmount] = useState(0);

  const [modal, setModal] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [company, setCompany] = useState({});
  const [twoFactorAuth, setTwoFactorAuth] = useState(true);

  const [walletHistory, setWalletHistory] = useState([]);

  const [stats, setStats] = useState({});

  const [logo, setLogo] = useState('');

  const [typee, setTypee] = useState('create');

  const [oneTime, setOneTime] = useState(0);
  const [weekly, setWeekly] = useState(0);
  const [monthly, setMonthly] = useState(0);
  const [yearly, setYearly] = useState(0);

  const [oneTimed, setOneTimed] = useState(0);
  const [weeklyd, setWeeklyd] = useState(0);
  const [monthlyd, setMonthlyd] = useState(0);
  const [yearlyd, setYearlyd] = useState(0);
  const [freeEndDate, setEndDate] = useState(new Date());

  const [image, setImage] = useState('');
  const [copied, setCopied] = useState(false);

  const [quarterly, setQuarterly] = useState(0);
  const [biannually, setBiannually] = useState(0);

  useEffect(() => {
    setCopied(false);
    getSinglePub();
    getCompanyData();
    getTypes();
  }, [reload]);

  const getSinglePub = async () => {
    setOneTime(0);
    setOneTimed(0);
    setWeekly(0);
    setWeeklyd(0);
    setMonthly(0);
    setQuarterly(0);
    setBiannually(0);
    setMonthlyd(0);
    setYearly(0);
    setYearlyd(0);
    let companyData = await getLocalItem('company');
    console.log('triggered2');
    setCompany(JSON.parse(companyData));
    if (id) {
      let res = await httpGet(`publication/${id}`);
      if (res) {
        if (!res.success) {
          return;
        }
        setImage(res?.data?.publication.logo);
        setPub(res?.data?.publication);
        setName(res?.data?.publication.name);
        setPhone(res?.data?.publication.officialContact);
        setWebsite(res?.data?.publication.websiteUrl);
        setDescription(res?.data?.publication.description);
        setSelectedCat(res?.data?.publication.typeId);
        setSelectedOften(res?.data?.publication?.subscriptionType);
        setTwoFactorAuth(res?.data?.publication.isPaid);

        if (res?.data?.publication.oneOffFee) {
          setOneTime(res?.data?.publication.oneOffFee);
        }
        if (res?.data?.publication.oneOffDollarFee) {
          setOneTimed(res?.data?.publication.oneOffDollarFee);
        }
        if (res?.data?.publication.weeklyFee) {
          setWeekly(res?.data?.publication.weeklyFee);
        }
        if (res?.data?.publication.weeklyDollarFee) {
          setWeeklyd(res?.data?.publication.weeklyDollarFee);
        }
        if (res?.data?.publication.quarterlyFee) {
          setQuarterly(res?.data?.publication.quarterlyFee);
        }
        if (res?.data?.publication.biannuallyFee) {
          setBiannually(res?.data?.publication.biannuallyFee);
        }
        if (res?.data?.publication.monthlyFee) {
          setMonthly(res?.data?.publication.monthlyFee);
        }
        if (res?.data?.publication.monthlyDollarFee) {
          setMonthlyd(res?.data?.publication.monthlyDollarFee);
        }
        if (res?.data?.publication.annualFee) {
          setYearly(res?.data?.publication.annualFee);
        }
        if (res?.data?.publication.annualDollarFee) {
          setYearlyd(res?.data?.publication.annualDollarFee);
        }
      }
    }
  };

  const getWalletHistory = async () => {
    setWallet([]);
    let res = await axiosCalls(`/vendor_wallet_history`, 'GET');
    if (res) {
      if (res.er) {
        // console.log("err", res.er);
        return;
      }
      // console.log('suuu', res.data);
      setWalletHistory(res?.data?.walletHistory);
    }
  };

  const getCompanyData = async () => {
    let res = await axiosCalls(`/vendor_wallet_details`);
    if (res) {
      if (res.er) {
        // console.log("dfdfad", res.er);
        return;
      }
      setWallet(res?.data?.wallet);
    }
  };

  const getTypes = async () => {
    const data = await getPublicationTypes();
    if (data) {
      if (data.er) {
        // NotificationManager.error(data.er.data.message);
        if (
          data.er.data.message ===
          'Unauthorized, Your token is invalid or expired'
        ) {
          setAuth(false);
          props.history.push('/');
        }
        return;
      }
      setPubType(data?.data?.publicationTypes);
    }
  };

  const handleChange = (e) => {
    if (e.target?.name) {
      return setSelectedOften(e.target.value);
    }
    setSelectedCat(e.target.value);
  };

  const submit = async () => {
    // e.preventDefault();
    console.log(selectedCat, name);
    if (selectedCat === '' || name === '') {
      return NotificationManager.error('All fields are required');
    }

    let data = null;

    if (twoFactorAuth) {
      data = {
        name,
        typeId: selectedCat,
        isPaid: twoFactorAuth,
      };
    } else {
      data = {
        name,
        typeId: selectedCat,
        isPaid: twoFactorAuth,
        freeEndDate,
      };
    }

    let res = await httpPostData(`/create_publication`, data);
    if (res) {
      if (res.er) {
        console.log('err', res.er);
        hideLoader();
        return NotificationManager.error(res.er.message);
      }
      let companyData = await getLocalItem('company');
      setLocalItem('company', {
        ...JSON.parse(companyData),
        currentPublication: res?.data?.id,
      });
      setPubReload(!pubReload);
      // setTypee('pn');

      hideLoader();
    }
  };

  const getDashoardStats = async () => {
    let res = await axiosCalls(`/dashboard_stats`);
    if (res) {
      if (res.er) {
        return;
      }
      setStats(res.data);
    }
  };

  const withdrawAmount = async () => {
    if (!withdrawalAmount) {
      return;
    }
    let res = await axiosCalls(`/withdraw_wallet`, 'POST', {
      amount: withdrawalAmount,
    });
    if (res) {
      if (res.er) {
        return swal('TheNewsPaperStand!', `${res.er.message}`, 'error');
      }
      swal(
        'TheNewsPaperStand!',
        `Your transaction is being processed, your account will be credited shortly `,
        'success'
      );
      setWalletModal(false);
      getCompanyData();
      getDashoardStats();
    }
  };

  const updatePublication = async (e) => {
    let data = new FormData();
    if (
      selectedCat === '' ||
      name === '' ||
      selectedOften === '' ||
      phone === '' ||
      website === '' ||
      description === ''
    ) {
      swal('all fields are required !!!');
    }
    if (twoFactorAuth) {
      data = {
        name,
        officialContact: phone,
        websiteUrl: website,
        description,
        typeId: selectedCat,
        oneOffFee: oneTime,
        weeklyFee: weekly,
        monthlyFee: monthly,
        quarterlyFee: quarterly,
        biannuallyFee: biannually,
        annualFee: yearly,
        oneOffDollarFee: oneTimed,
        weeklyDollarFee: weeklyd,
        monthlyDollarFee: monthlyd,
        annualDollarFee: yearlyd,
        isPaid: twoFactorAuth,
        subscriptionType: selectedOften,
      };
    } else {
      data = {
        name,
        officialContact: phone,
        websiteUrl: website,
        description,
        typeId: selectedCat,
        oneOffFee: oneTime,
        weeklyFee: weekly,
        monthlyFee: monthly,
        quarterlyFee: quarterly,
        biannuallyFee: biannually,
        annualFee: yearly,
        oneOffDollarFee: oneTimed,
        weeklyDollarFee: weeklyd,
        monthlyDollarFee: monthlyd,
        annualDollarFee: yearlyd,
        isPaid: twoFactorAuth,
        subscriptionType: selectedOften,
        freeEndDate: freeEndDate,
      };
    }

    let res = await axiosCalls(`/update_publication/${id}`, 'PATCH', data);
    if (res?.success) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }
      NotificationManager.success(res.message);
      setPubReload(!pubReload);
      getSinglePub();
    }
  };

  console.log(logo);

  return (
    <>
      <Wrapper>
        {/* {!renderOpt === "create" && <Breadcr/>um title={renderOpt !== 'complete' ? "Get Started" : "Dashboard"} />} */}
        <div className='mod'>
          <h2 className='text-center pub-title'>Edit Publication</h2>
          <div className='pub-wrapper'>
            <div className='bg-container'>
              <img src={bg2} alt='pub image' className='img-fluid' />
            </div>
            <Scrollbars
              style={{ flex: 1 }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div className='pub-content'>
                <div className='logo-container'>
                  <div className='logo-container-content'>
                    <img src={image} className='img-fluid' />
                  </div>
                </div>
                <div className='pub-content--wrapper--main'>
                  <div className='pub-content--wrapper'>
                    <div className='pub-content--wrapper--tab'>
                      <div
                        className={`tab--div ${
                          typee === 'create' ? 'tab-div--active' : null
                        }`}
                        onClick={() => setTypee('create')}
                      >
                        <h1>Publication</h1>
                      </div>
                      <div
                        className={`tab--div ${
                          typee === 'pn' ? 'tab-div--active' : null
                        }`}
                        onClick={() => setTypee('pn')}
                      >
                        <h1>Price in Naira</h1>
                      </div>
                      {/* <div
                        className={`tab--div ${
                          typee === "pu" ? "tab-div--active" : null
                        }`}
                        onClick={() => setTypee("pu")}
                      >
                        <h1>Price in USD</h1>
                      </div> */}
                    </div>
                    {typee === 'create' && (
                      <div className='pub-content--wrapper--content'>
                        <div className='wrapper--content-1'>
                          <div className='wrapper--content-2'>
                            <h2>Publication Name</h2>
                          </div>
                          <div className='wrapper--content-3'>
                            <input
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              name='name'
                              type='text'
                            />
                          </div>
                        </div>

                        <div className='wrapper--content-1'>
                          <div className='wrapper--content-2'>
                            <h2>Official Contact</h2>
                          </div>
                          <div className='wrapper--content-3'>
                            <input
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              name='phone'
                              type='number'
                            />
                          </div>
                        </div>

                        <div className='wrapper--content-1'>
                          <div className='wrapper--content-2'>
                            <h2>Website URL</h2>
                          </div>
                          <div className='wrapper--content-3'>
                            <input
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              name='website'
                              type='text'
                            />
                          </div>
                        </div>

                        <div className='wrapper--content-1'>
                          <div className='wrapper--content-2'>
                            <h2>Select Publication Type</h2>
                          </div>
                          <div className='wrapper--content-3'>
                            <select
                              value={selectedCat}
                              onChange={handleChange}
                              style={{ textTransform: 'capitalize' }}
                            >
                              <option>--Choose--</option>
                              {pubType.map((el, i) => {
                                return (
                                  <option value={el.id} key={i}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {selectedCat !==
                          'eb244fbc-ab2c-4a51-9b6f-034d987f352e' && (
                          <div className='wrapper--content-1'>
                            <div className='wrapper--content-2'>
                              <h2>How Often Do You Publish?</h2>
                            </div>
                            <div className='wrapper--content-3'>
                              <select
                                value={selectedOften}
                                name='often'
                                onChange={(e) =>
                                  setSelectedOften(
                                    e.target.value.toLocaleLowerCase()
                                  )
                                }
                                style={{ textTransform: 'capitalize' }}
                              >
                                <option>--Choose--</option>
                                {[
                                  'one-off',
                                  'Weekly',
                                  'Monthly',
                                  'Quarterly',
                                  'Annually',
                                  'Biannually',
                                ].map((el, i) => {
                                  return (
                                    <option
                                      value={el.toLocaleLowerCase()}
                                      key={i}
                                    >
                                      {el.toLocaleLowerCase()}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        )}

                        <div className='wrapper--content-1'>
                          <div className='wrapper--content-2'>
                            <h2>Set Publication Logo</h2>
                          </div>
                          <div className='wrapper--content-3'>
                            <input
                              onChange={(e) => setLogo(e.target.file[0])}
                              name='name'
                              type='file'
                              accept='image/*'
                            />
                          </div>
                        </div>
                        <div className='wrapper--content-1'>
                          <div className='wrapper--content-2'>
                            <h2>Publication Description</h2>
                          </div>
                          <div className='wrapper--content-3'>
                            <textarea
                              placeholder='Description can not be more than 150 characters'
                              name='description'
                              rows='4'
                              cols='50'
                              maxlength='150'
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        <div className='wrapper--content-1'>
                          <div className='wrapper--content-2'>
                            <h2>Paid Publication</h2>
                          </div>
                          <div className='wrapper--content-3'>
                            <Switch
                              checked={twoFactorAuth}
                              onChange={(e) => {
                                setTwoFactorAuth(e);
                              }}
                              onColor='#EE7F0D'
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={24}
                              width={50}
                            />
                          </div>
                        </div>

                        {!twoFactorAuth && (
                          <div className='wrapper--content-1'>
                            <div className='wrapper--content-2'>
                              <h2>End date</h2>
                            </div>
                            <div className='wrapper--content-3'>
                              <input
                                type='date'
                                value={moment(freeEndDate).format('yyyy-MM-DD')}
                                onChange={(e) => {
                                  setEndDate(new Date(e.target.value));
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {typee === 'pn' && (
                      <div className='pub-content--wrapper--content'>
                        <h2
                          className='text-center my-2'
                          style={{
                            fontSize: '12px',
                            color: 'grey',
                            letterSpacing: 0.1,
                            lineHeight: '15px',
                          }}
                        >
                          Kindly ignore any price section your publication
                          doesn't apply to, for example if your publication
                          comes out weekly, only set prices for weekly and below
                          values leaving out daily.
                        </h2>

                        {pub.type.name === 'books' && (
                          <div className='wrapper--content-1'>
                            <div className='wrapper--content-2'>
                              <h2>One off (Book)</h2>
                            </div>
                            <div className='wrapper--content-3'>
                              <input
                                type='number'
                                value={oneTime}
                                onChange={(e) => {
                                  setOneTime(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {pub.type.name !== 'books' && (
                          <>
                            {pub.subscriptionType === 'one-off' && (
                              <div className='wrapper--content-1'>
                                <div className='wrapper--content-2'>
                                  <h2>Daily(One off)</h2>
                                </div>
                                <div className='wrapper--content-3'>
                                  <input
                                    type='number'
                                    value={oneTime}
                                    onChange={(e) => {
                                      setOneTime(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {pub.subscriptionType ===
                              ('weekly' || 'one-off') && (
                              <div className='wrapper--content-1'>
                                <div className='wrapper--content-2'>
                                  <h2>Weekly</h2>
                                </div>
                                <div className='wrapper--content-3'>
                                  <input
                                    type='number'
                                    value={weekly}
                                    onChange={(e) => {
                                      setWeekly(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {(pub.subscriptionType === 'one-off' ||
                              pub.subscriptionType === 'weekly' ||
                              pub.subscriptionType === 'monthly') && (
                              <div className='wrapper--content-1'>
                                <div className='wrapper--content-2'>
                                  <h2>Monthly</h2>
                                </div>
                                <div className='wrapper--content-3'>
                                  <input
                                    type='number'
                                    value={monthly}
                                    onChange={(e) => {
                                      setMonthly(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {(pub.subscriptionType === 'one-off' ||
                              pub.subscriptionType === 'weekly' ||
                              pub.subscriptionType === 'monthly' ||
                              pub.subscriptionType === 'quarterly') && (
                              <div className='wrapper--content-1'>
                                <div className='wrapper--content-2'>
                                  <h2>Quaterly</h2>
                                </div>
                                <div className='wrapper--content-3'>
                                  <input
                                    type='number'
                                    value={quarterly}
                                    onChange={(e) => {
                                      setQuarterly(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {(pub.subscriptionType === 'one-off' ||
                              pub.subscriptionType === 'weekly' ||
                              pub.subscriptionType === 'monthly' ||
                              pub.subscriptionType === 'quarterly' ||
                              pub.subscriptionType === 'annually' ||
                              pub.subscriptionType === 'biannually') && (
                              <div className='wrapper--content-1'>
                                <div className='wrapper--content-2'>
                                  <h2>Biannual</h2>
                                </div>
                                <div className='wrapper--content-3'>
                                  <input
                                    type='number'
                                    value={biannually}
                                    onChange={(e) => {
                                      setBiannually(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            {(pub.subscriptionType === 'one-off' ||
                              pub.subscriptionType === 'weekly' ||
                              pub.subscriptionType === 'monthly' ||
                              pub.subscriptionType === 'quarterly' ||
                              pub.subscriptionType === 'annually') && (
                              <div className='wrapper--content-1'>
                                <div className='wrapper--content-2'>
                                  <h2>Annual</h2>
                                </div>
                                <div className='wrapper--content-3'>
                                  <input
                                    type='number'
                                    value={yearly}
                                    onChange={(e) => {
                                      setYearly(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className='last-container'>
                      <div className='pub-link-container'>
                        <div className='link-tab1'>
                          <h2>Publication Link: </h2>
                        </div>

                        <div className='link-tab2'>
                          <h2>
                            {/* {process.env.REACT_APP_BASE_URL}/ */}/
                            {name.toLowerCase().trim().replace(/ /g, '-')}
                          </h2>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {!copied && (
                              <CopyToClipboard
                                text={`${
                                  process.env.REACT_APP_USER_BASE_URL
                                }/publication/${name
                                  .toLowerCase()
                                  .trim()
                                  .replace(/ /g, '-')}`}
                                onCopy={(e) => {
                                  setCopied(true);
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 500);
                                }}
                              >
                                <i className='fa fa-copy' />
                              </CopyToClipboard>
                            )}

                            {copied && (
                              <span
                                style={{
                                  color: 'var(--mainColor)',
                                  fontSize: '12px',
                                }}
                              >
                                Copied
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='last-container2'>
                        <button onClick={() => updatePublication()}>
                          <h2>Save</h2>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '4%' }}></div>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Home;

const Wrapper = style.div`
    .pub-wrapper{
        height: 95%;
        display: flex;
        flex-direction: column;
        overflow: hidden !important;

        .pub-content {
            flex: 1;
            display: flex;
            // border: 1px solid red;
            // justify-content: center;
            overflow: hidden !important;

            .logo-container {
                // border: 1px solid red;
                padding-top: 2%;
                display: flex;
                justify-content: center;

                .logo-container-content {
                    border: 1px solid #FFFFFF;
                    box-shadow: 3px 5px 8px 1px #888888;
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                }
            }

            .pub-content--wrapper--main {
                flex: 1;
                display: flex;
                justify-content: center;

                .pub-content--wrapper {
                    display: flex;
                    flex-direction: column;
                    padding-top: 2%;
                    width: 60%;

                    .pub-content--wrapper--tab {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;

                        .tab--div {
                            cursor: pointer;
                            padding-bottom: 15px;
                            flex: 1;
                            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                            &:hover {
                            border-bottom: 2px solid var(--mainColor);
                                h1 {
                                    color: #002233;
                                }
                            }
                            &:nth-child(2) {
                                display: flex;
                                justify-content: center;
                            }

                            &:nth-child(3) {
                                display: flex;
                                justify-content: flex-end;
                            }

                            h1 {
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 1px;
                                color: #8692A6;
                                margin: 0;
                            }
                        }

                        .tab-div--active {
                            border-bottom: 2px solid var(--mainColor);
                            h1 {
                                color: #002233;
                            }
                        }
                    }

                    .pub-content--wrapper--content {
                        flex: 1;
                        .wrapper--content-1 {
                            margin-top: 25px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 2px dashed rgba(0, 0, 0, 0.07);
                            padding-bottom: 10px;
                            .wrapper--content-2 {
                                width: 35%;

                                h2 {
                                    font-size: 12px;
                                    margin: 0;
                                    letter-spacing: .5px;
                                }
                            }
                            .wrapper--content-3 {
                                flex: 1;

                                input, select, textarea {
                                    width: 100%;
                                    padding: 10px;
                                    font-weight: 400 !important;
                                    font-size: 12px;
                                    color: var(--appText);
                                    letter-spacing: 1px;
                                    line-height: 20px;
                                    border-radius: 8px;
                                    border: none;
                                    background: var(--appInput);

                                    &:focus {
                                        outline-color: var(--bgColor);
                                    }
                                }  
                            }
                            
                        
                        }
                    }

                    .last-container {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        padding: 20px 0;

                        .last-container1 {
                            display: flex;
                            justify-content: flex-end;

                            h2 {
                                font-size: 12px;
                                cursor: pointer;

                                &:hover {
                                    color: var(--mainColor);
                                }
                            }
                        }

                        .last-container2 {
                            display: flex;
                            justify-content: center;

                            button {
                                margin-top: 20px;
                                background: var(--mainColor);
                                padding: 12px;
                                border-radius: 5px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                width: 30%;
                                border: none !important;
                                    outline: none !important;
                                h2 {
                                    margin: 0;
                                    font-size: 12px;
                                    color: #fff;
                                }

                                &:focus {
                                    border: none !important;
                                    outline: none !important;
                                }
                            }
                        }
                    }

                    .pub-link-container {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        


                        h2 {
                            margin: 0;
                            font-size: 12px;
                            letter-spacing: .5px;
                        }

                        .link-tab1 {
                            flex: 1;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        .link-tab2 {
                            flex: 2;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;

                            &:hover {
                                

                                i {
                                    color: var(--mainColor);
                                }
                            }
                        }
                    }
            }
            }
            
            
        }
    }
    .bg-container {
        margin-top: 30px;
        overflow-y: hidden;
        height: 25%;
    }    
`;
