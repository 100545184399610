import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-responsive-modal";
import {
  axiosCalls,
  axiosFormData,
} from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import {
  setLocalItem,
  getLocalItem,
  removeItem,
} from "../../components/helpers/authService";

import { Animated } from "react-animated-css";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";
import "./onboard.css";
import { NotificationManager } from "react-notifications";
import { DashboardContext } from "../../context/DashboardContext";
import { home, Read } from "../../assets/icons/svgs";
import swal from "sweetalert";
// import Read from '../../assets/images/Initial.png';
import { Scrollbars } from "react-custom-scrollbars";

const Onboard = (props) => {
  const { renderOpt, setRenderOpt } = useContext(DashboardContext);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(true);

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState("profile");
  const [cacNo, setCacNo] = useState("");

  const [answered, setAnswered] = useState({
    profile: 0,
    owner: 0,
    bank: 0,
  });

  const [done, setDone] = useState([]);

  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [tin, setTin] = useState("");

  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bvn, setBvn] = useState("");

  const [companyDetails, setCompanyDetails] = useState({});

  const [banksData, setBanksData] = useState([]);

  const [abbrName, setAbbrName] = useState("");
  const [cacDoc, setCacDoc] = useState({});

  const [shortInfo, setShortInfo] = useState([
    {
      title: "Create Publication",
      desc: "Start publishing your Newspapers, Magazines, Books or White papers in minutes and reach millions of readers across the globe.",
    },
    {
      title: "Real-time Tracking",
      desc: "Track sales and subscribers' action real-time with our easy to use portal.",
    },
    {
      title: "Make More Money",
      desc: "Sell a single edition or subscription of your publication.",
    },
    // { title: 'Subscribe to Managed Services', desc: 'As a publisher you can subscribe to some of our add-on services such as Change of Name, publication archive request and many more. ' },
  ]);

  useEffect(() => {
    getCompanyDetails();
    getBanks();
    getOnboardLength();
  }, []);

  const getCompanyDetails = async () => {
    let company = await getLocalItem("company");
    if (company) {
      setCompanyDetails(JSON.parse(company));
      var key;
      var arr = [];
      let str = JSON.parse(company).businessName;
      for (key in str.split(" ")) {
        arr.push(str.split(" ")[key][0]);
      }
      setAbbrName(arr.join(""));
    }
  };

  const abbr = async (str) => {
    var key;
    var arr = [];
    for (key in str.split(" ")) {
      arr.push(str.split(" ")[key][0]);
    }
    return <p style={{ margin: 0 }}>{arr.join("")}</p>;
  };

  const getOnboardLength = async () => {
    let d = await getLocalItem("onboard");
    if (d) {
      setDone(JSON.parse(d));
    }
  };

  const getBanks = async () => {
    let res = await axiosCalls("/banks");
    if (res) {
      if (res.er) {
        return;
      }
      console.log("banks", res.data.bankList);
      setBanksData(res.data.bankList);
    }
  };

  const updateDetails = async (e) => {
    e.preventDefault();
    console.log(cacDoc);
    // return;
    if (
      address === "" ||
      phone === "" ||
      accountName === "" ||
      tin === "" ||
      !cacDoc
    ) {
      return NotificationManager.error("All fields are required");
    }
    showLoader();

    let fd = new FormData();

    fd.append("file", cacDoc);
    fd.append("address", address);
    fd.append("phone", phone);
    fd.append("bankName", bankCode.split("/")[0]);
    fd.append("accountNumber", accountNumber);
    fd.append("accountName", accountName);
    fd.append("bankCode", bankCode.split("/")[1]);
    fd.append("tin", tin);
    fd.append("cacNumber", cacNo);

    let res = await axiosFormData("/auth/onboard_company", "PATCH", fd);
    if (res) {
      if (res.er) {
        console.log("my err", res.er);
        hideLoader();
        return NotificationManager.error(res.er.message);
      }
      hideLoader();
      console.log("success", res);
      updateCount();
    }
  };

  const verify = async () => {
    console.log("bank", accountNumber, bankCode);

    let res = await axiosCalls(
      `/bank/resolve?accountNumber=${accountNumber}&bankCode=${
        bankCode.split("/")[1]
      }`
    );
    console.log("RESPONSE", res);

    if (res) {
      if (res.er) {
        console.log(res);
        if(res.er.message === "Request failed with status code 422"){
          NotificationManager.error("Account Details Not Found");
        } else {
          NotificationManager.error(res.er.message);
        }
        return setLoading(false);
      }
      setAccountName(res.data.account_name);

      setLoading(false);
    }
  };

  const fake = (sec) => {
    showLoader();
    setTimeout(async () => {
      swal("TheNewsPaperStand!", "Successfully saved", "success");
      setDone([...done, sec]);
      await setLocalItem("onboard", [...done, sec]);
      setType("bank");
      hideLoader();
    }, 3000);
  };

  const getfile = (e) => {
    console.log(e.target.files[0]);
    setCacDoc(e.target.files[0]);
  };

  const updateCount = async () => {
    let res = await axiosCalls(`/auth/onboard_count`, "PATCH");
    if (res) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }
      let compay = await getLocalItem("company");
      if (compay) {
        let updated = { ...JSON.parse(compay), onBoardCount: 1 };
        await setLocalItem("company", updated);
      }

      setRenderOpt("create");
      swal(
        "TheNewsPaperStand!",
        "You have Successfully completed your onboarding",
        "success"
      );
      props.history.push("/home");
    }
  };

  return (
    <>
      <React.Fragment>
        <div className="onbording border">
          <div className="onboarding--side">
            <div className="side-wrapper">
              <div>
                <div className="logo-container">{home}</div>

                {/* <div className="login--image" >
                            <img src={AppLogo} className="login--image_logo" />
                        </div> */}
                <div>
                  <div className="side-content">
                    <Scrollbars
                      style={{ flex: 1 }}
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                    >
                      {shortInfo.map((el, i) => {
                        return (
                          <div key={i} className="d-flex mb-3">
                            <div className="mr-3">
                              <Read />
                            </div>
                            <div style={{ flex: 1 }}>
                              <h1 className="mt-1 font-weight-bold">
                                {el.title}
                              </h1>
                              <h3>{el.desc}</h3>
                            </div>
                          </div>
                        );
                      })}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="onboarding--main">
            <Scrollbars
              style={{ flex: 1 }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <h3 className="onboarding-welcome">Welcome Back</h3>
              <h1>{companyDetails.businessName}</h1>
              <div className="onboarding-info">
                <h3>
                  We need a little more information from you to <br /> complete
                  your onboarding.
                </h3>
              </div>

              <div className="input-wrapper">
                <div className="input-wrapper-item1">
                  <h1 className="input-wrapper-title">Business details</h1>
                  <div className="mt-3 mb-4">
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Enter Head office Address"
                    />
                  </div>

                  <div className="mb-4">
                    <input
                      type="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      name="phone"
                      id="businessPhone"
                      placeholder="Enter Business contact number"
                    />
                  </div>

                  <div className="mb-4">
                    <input
                      type="text"
                      value={tin}
                      onChange={(e) => setTin(e.target.value)}
                      placeholder="Enter Tax Identification Number(TIN)"
                    />
                  </div>
                </div>

                <div className="input-wrapper-item1">
                  <h1 className="input-wrapper-title">Bank details</h1>
                  <div className="mt-3 mb-4">
                    <select
                      value={bankCode}
                      onChange={(e) => setBankCode(e.target.value)}
                    >
                      <option>Select Bank</option>
                      {banksData
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((el, i) => {
                          return (
                            <option value={`${el.name}/${el.code}`} key={i}>
                              {el.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="mb-4">
                    <input
                      type="text"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      name="account number"
                      id="acctNumber"
                      onBlur={() => verify()}
                      placeholder="Enter Bank account number"
                    />
                  </div>

                  <div className="mb-4">
                    <input
                      type="text"
                      name="account name"
                      value={accountName}
                      placeholder="Bank account Name"
                    />
                  </div>
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-wrapper-item1">
                  <h1 className="input-wrapper-title">CAC documents</h1>
                  <div className="input-wrapper mb-4">
                    <div style={{ flex: 1 }} className="mt-3">
                      <input
                        value={cacNo}
                        onChange={(e) => setCacNo(e.target.value)}
                        placeholder="Enter number"
                      />
                    </div>
                    <div style={{ flex: 1 }} className="mt-3">
                      <input
                        type="file"
                        onChange={getfile}
                        placeholder="Enter Head office Address"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-wrapper my-3">
                <button onClick={updateDetails}>
                  <h3>Submit</h3>
                </button>
              </div>
            </Scrollbars>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default Onboard;

const SizedBox = (props) => {
  return (
    <div
      style={{
        height: props.height,
        width: props.width,
      }}
    ></div>
  );
};
