import style from 'styled-components';

export const ArticlesWrapper = style.div`
    width: 100%;
    height: 100%;

    .article--header {
    // border: 1px solid red;
    margin: 40px 0;
    display: flex;
    justify-content: space-between;

    .article--header__title-box {
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
        font-family: 'Merriweather', serif;
        font-weight: bold;
        letter-spacing: 0.75px;
        font-size: 25px;
        }
    }

    .article--header__btn-box {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .Pub--btn.publish-btn {
            margin-left: 20px;
            background: var(--appGreen);

            h2 {
                color: #fff;
            }
        }

        .save-btn {
            margin-left: 17px !important;
            height: 50px !important;
        }

        .Pub--btn {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 40px;
        color: var(--mainColor);
        background: #fff;
        width: 15%;
        transition: width 1s;

        @media (max-width: 1440px) {
            width: 20%;
        }

        @media (max-width: 1115px) {
            width: 25%;
        }

        @media (max-width: 585px) {
            width: 35%;
        }

        border: 1px solid var(--bgColor);
        // box-shadow: 1px 1px 1px #888888;
        box-shadow: 0px 16px 24px rgba(238, 127, 13, 0.16), 0px 6px 12px rgba(238, 127, 13, 0.16);

        h2 {
            font-size: 16px;
            margin: 0;
        }

        p {
            color: #8692A6;
            font-size: 12px;
        }

        &:hover {
            box-shadow: 0px 18px 24px rgba(238, 127, 13, 0.16), 0px 18px 12px rgba(238, 127, 13, 0.16);
        }
        }
    }
    }

`;

export const EditionInfoWrapper = style.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edition-info--cover-image-box {
        width: 20%;
        h2 {
            color: #6E7191;
            margin: 0;
            font-size: 16px;
        }
        &__image-container {
            position: relative;
            margin-top: 15px;
            cursor: pointer;

            .input-wrapper {
                position: absolute;
                top: -18%;
                width: 100%;
                height: 100%;

                input {
                    width: 100%;
                    opacity: 0;
                }
            }
            

            &:hover {
                .box__image-container__overlay {
                    opacity: 1;
                }
            }
            img {
                width: 100%;
                border-radius: 5px;
                transition: .5s ease;
                backface-visibility: hidden;
                opacity: 1;
                display: block;
            }

            .box__image-container__overlay {
                position: absolute;
                opacity: 0;
                transition: .5s ease;
                background: rgba(0, 0, 0, 0.25);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 0;
                width: 100%;
                height: 100%;
                left: 0%;

                .overlay__title-box {
                    width: 50%;
                    h2 {
                        color: #fff;
                        margin: 0;
                        text-align: center;
                    }
                }

                
            }
        }
    }

    .edition-info--input-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .edition-info--input-box__container {
            
            width: 75%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .input-box__container-box {
                flex: 1;
                h2 {
                    font-size: 13px;
                    letter-spacing: 0.75px;
                    color: #4E4B66;
                    margin: 0;
                }

                input {
                    margin-top: 20px;
                    border: 1px solid #E5E5E5;
                    border-radius: 5px;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    width: 85%;
                    padding: 10px;
                    font-size: 13px;
                    letter-spacing: 0.75px;
                    &:focus {
                        outline: none;
                    }
                }
            }

            
        }
        
        .input-box__container-box1 {
                width: 75%;
                flex: 1;
                margin-top: 40px;
                h2 {
                    font-size: 13px;
                    letter-spacing: 0.75px;
                    color: #4E4B66;
                    margin: 0;
                }

                input {
                    margin-top: 10px;
                    border: 1px solid #E5E5E5;
                    border-radius: 5px;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    width: 55%;
                    padding: 10px;
                    font-size: 13px;
                    letter-spacing: 0.75px;
                    &:focus {
                        outline: none;
                    }
                }

                select {
                    border-radius: 5px;
                    margin-top: 20px;
                    font-size: 13px;
                    padding: 10px 10px;
                    letter-spacing: 1px;
                    color: #8692A6;
                    border: 1px solid #E5E5E5;
                    width: 55%;

                    &:focus {
                        outline: none;
                    }
                }
            }
    }
`;

export const ArticleGridWrapper = style.div`
    margin-top: 40px;

    .article-grid--body {
        width: 100%;
        margin-top: 30px;

        .article-grid--body__row {
            display: flex;
            justify-content: space-between;
            @media (max-width: 585px) {
                justify-content: center;
            }
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 20px;

            .article-grid--body__row-item {
                width: 19%;
                margin-right: 30px;
                margin-bottom: 40px;
                cursor: pointer;
                height: 430px;

                @media (max-width: 1440px) {
                    width: 24%;
                }
                @media (max-width: 1303px) {
                    width: 30%;
                }
        
                @media (max-width: 1115px) {
                    width: 31%;
                }
        
                @media (max-width: 585px) {
                    width: 90%;
                }
        

                .grid--body__row-item__image-container {
                    width: 100%;
                    overflow: hidden !important;
                    height: 260px;

                    img {
                        float: left;
                        width:  100%;
                        height: 300px;
                        object-fit: cover;
                    }
                }

                .row-item__image-container--content {
                    margin-top: 15px;
                    h2 {
                        font-size: 15px;
                    }

                    p {
                        color: #6E7191;
                        font-size: 13px;
                    }

                    h3 {
                        font-size: 12px;
                        margin-top: 10px;
                    }

                    .image-container--content__btn {
                        width: 30%;
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h2 {
                            font-size: 12px;
                            margin: 0;
                            color: var(--mainColor);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .article-grid--header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            color: #4E4B66;
            font-size: 16px;
            letter-spacing: 0.75px;
            margin: 0;
        }

        .article-grid--header--btn-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            .active {
                background: var(--mainColor);

                h2 {
                    color: #fff;
                }

            }
            
            button {
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                padding: 10px 15px;
                border-radius: 5px;
                margin-left: 15px;
                width: 10%;

                @media (max-width: 1511px) {
                    width: 14%;
                }
        
                @media (max-width: 1440px) {
                    width: 14%;
                }
        
                @media (max-width: 1381px) {
                    width: 16%;
                }
                @media (max-width: 1257px) {
                    width: 18%;
                }
                @media (max-width: 1201px) {
                    width: 18%;
                }

                @media (max-width: 1155px) {
                    width: 20%;
                }
        
                @media (max-width: 1115px) {
                    width: 22%;
                }
        
                @media (max-width: 1047px) {
                    width: 22%;
                }
                @media (max-width: 1017px) {
                    width: 24%;
                }
                @media (max-width: 621px) {
                    width: 65%;
                }
                border: 1px solid #BDBDBD;

                h2 {
                    font-size: 13px;
                    margin: 0;
                    color: var(--mainColor);
                }

                &:focus {
                    outline: none;
                }
            }


        }
    }

    .image-container--content__btn-list {
        h2 {
            font-size: 16px;
            margin: 0;
            color: var(--mainColor);
        }
    }
`;
