import React, { useState, useEffect } from 'react';
import { AddArticleWrapper, EditBreadCrum, AddArticleBody } from './addCss';
import { Scrollbars } from 'react-custom-scrollbars';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import arrRight from '../../../../assets/icons/arrRight.png';
import { Cloud } from '../../../../assets/icons/svgs';
import { NotificationManager } from 'react-notifications';
import {
  axiosFormData,
  httpPost,
} from '../../../../components/helpers/httpMethods';
import swal from 'sweetalert';
import MyCustomUploadAdapterPlugin from '../../../../components/ckEditor/MyUploadAdapter';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import EditorContainer from '../editor';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

const AddArticle = (props) => {
  const initialState = EditorState.createWithContent(
    ContentState.createFromText('')
  );
  const id = props.match.params.id.split('_');
  const [tags, setTags] = useState([]);
  const [articleObj, setArticleObj] = useState({
    title: '',
    javascript: '',
    // author: '',
  });
  const [articleBody, setArticleBody] = useState('');
  const [file, setFile] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [articleId, setArticleId] = useState('');
  const [byline, setByline] = useState('');
  const [editorState, setEditorState] = useState(initialState);
  useEffect(() => {
    console.log(props.match.params.id.split('_'));
  });

  // console.log(editorState);
  console.log(props);

  const handleChange = (e) => {
    let update = { ...articleObj, [e.target.name]: e.target.value };
    setArticleObj(update);
  };

  const getfile = (e) => {
    const objectURL = URL.createObjectURL(e.target.files[0]);
    setPreviewImage(objectURL);
    setFile(e.target.files[0]);
  };

  const validate = async () => {
    let errors = [];
    for (let key in articleObj) {
      if (articleObj[key] === '' && key !== 'javascript') {
        errors.push(key);
        // return;
      }
    }

    if (errors.length) {
      NotificationManager.info(`${errors[0]} field is required.`);
      return false;
    }
    if (articleBody === '') {
      NotificationManager.info(`Article body cannot be empty`);
      return false;
    }
    return true;
  };

  const saveToDraft = async () => {
    let validated = await validate();
    if (!validated) {
      return;
    }

    let fd = new FormData();
    fd.append('file', file);
    fd.append('title', articleObj.title);
    // fd.append('author', articleObj.author);
    fd.append('byLine', byline);
    fd.append('javascript', articleObj.javascript);
    fd.append('body', articleBody);

    // ANCHOR APPEND ARRAY TO FORMDATA
    for (var i = 0; i < tags.length; i++) {
      fd.append('tag[]', tags[i]);
    }

    let res = await axiosFormData(
      `/save_article_draft/?editionId=${id[0]}`,
      'PATCH',
      fd
    );
    if (res) {
      if (res.er) {
        console.log(res.er);
        NotificationManager.error(res.er.message);
        return;
      }
      console.log(res.data);
      swal(
        'TheNewspaperStand',
        'You have successfully created your article',
        'success'
      ).then(() => {
        props.history.goBack();
      });
    }
  };

  const saveComplete = async () => {
    let validated = await validate();
    if (!validated) {
      return;
    }

    let fd = new FormData();
    fd.append('file', file);
    fd.append('title', articleObj.title);
    fd.append('author', articleObj.author);
    fd.append('byLine', byline);
    fd.append('javascript', articleObj.javascript);
    fd.append('body', articleBody);

    // ANCHOR APPEND ARRAY TO FORMDATA
    for (var i = 0; i < tags.length; i++) {
      fd.append('tag[]', tags[i]);
    }

    let res = await axiosFormData(`/create_article/${id[0]}`, 'POST', fd);
    if (res) {
      if (res.er) {
        console.log(res.er);
        NotificationManager.error(res.er.message);
        return;
      }
      console.log(res.data);
      swal(
        'TheNewspaperStand',
        'You have successfully created your article',
        'success'
      ).then(() => {
        props.history.goBack();
      });
    }
  };

  const onEditorStateChange = (editorState) => {
    // handleDescriptionValidation(editorState);

    const plainText = editorState.getCurrentContent().getPlainText();
    const richText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState);
    // setReplyTicket({ plainText, richText });
    console.log('>>>>', richText);
    setArticleBody(richText);
  };

  const _uploadImageCallBack = async (file) => {
    console.log(file);
    let fd = new FormData();
    fd.append('file', file);
    const res = await httpPost(`auth/file_upload`, fd);
    console.log(res.data);

    let uploadedImages = [];
    const imageObject = {
      file: file,
      localSrc: res.data.imagePath,
    };
    uploadedImages.push(imageObject);
    console.log(imageObject);
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  return (
    <AddArticleWrapper>
      <Scrollbars
        style={{ flex: 1 }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <div className='edit-article'>
          <EditBreadCrum className='edit-article__breadcrum'>
            <div
              className='edit-article__breadcrum__title'
              onClick={() => props.history.goBack()}
            >
              <img src={arrRight} className='img-fluid' width='20vw' />
              <div className='ml-3'>
                <h2>Back</h2>
              </div>
            </div>
            <div className='edit-article__breadcrum__body'>
              {/* <button>
                <h2>Preview</h2>
              </button> */}
              <button
                className='edit-article__breadcrum__body__btn--save'
                onClick={saveComplete}
              >
                <h2>Save</h2>
              </button>
            </div>
          </EditBreadCrum>

          <AddArticleBody>
            <div className='article__body'>
              <div className='article__body__title-box'>
                <h2>Title</h2>
                <input
                  value={articleObj.title}
                  onChange={handleChange}
                  name='title'
                />
              </div>
              <div className='article__body__title-box'>
                <h2>Byline</h2>
                <input
                  value={byline}
                  onChange={({ target }) => setByline(target.value)}
                  name='byline'
                />
                <div style={{ fontSize: '12px', color: 'rgba(255,0,0,0.8)' }}>
                  For Books Byline is equal to Quotes
                </div>
              </div>
              <div className='article__body__title-box body-editor'>
                <h2>Body</h2>
                <div className='mt-3 editor'>
                  <EditorContainer
                    onEditorStateChange={onEditorStateChange}
                    editorState={editorState}
                    uploadImageCallBack={_uploadImageCallBack}
                  />
                  {/* <CKEditor
                    editor={ClassicEditor}
                    onInit={(editor) => {}}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                      //  ckfinder:{uploadUrl: "https://ckeditor.com/apps/ckfinder/3.5.0/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json"}
                      // ckfinder:{uploadUrl: "https://api.cloudinary.com/v1_1/lms-center/upload"}
                    }}
                    onChange={(e, editor) => {
                      setArticleBody(editor.getData());
                      console.log(editor.getData());
                    }}
                  /> */}
                </div>
              </div>

              <div className='article__body__title-box'>
                <h2>Javascript</h2>
                <input
                  type='textarea'
                  value={articleObj.javascript}
                  onChange={handleChange}
                  name='javascript'
                />
              </div>

              {/* <div style={{ width: "40%", height: "30vh" }}>
                
              </div> */}
            </div>
            <div className='article__sidebar'>
              <div className='article__sidebar__tag'>
                <h2>Tags</h2>
                {/* <div className="article__sidebar__tag-box">
                  {tags.map((el, i) => {
                    return (
                      <div className="article__sidebar__tag-box__item">
                        <h2>{el}</h2>
                      </div>
                    );
                  })}
                </div> */}

                <TagsInput value={tags} onChange={(e) => setTags(e)} />

                <div className='article__sidebar__tag-suggested '>
                  <h2>SUGGESTED TAGS</h2>
                  <div className='suggestion-box'>
                    <div
                      className='suggestion-box__item'
                      onClick={() => {
                        setTags([...tags, 'lifestyle']);
                      }}
                    >
                      <h2>lifestyle</h2>
                    </div>
                    <div
                      className='suggestion-box__item'
                      onClick={() => {
                        setTags([...tags, 'fashion']);
                      }}
                    >
                      <h2>fashion</h2>
                    </div>
                    <div
                      className='suggestion-box__item'
                      onClick={() => {
                        setTags([...tags, 'politics']);
                      }}
                    >
                      <h2>politics</h2>
                    </div>
                    <div
                      className='suggestion-box__item'
                      onClick={() => {
                        setTags([...tags, 'family']);
                      }}
                    >
                      <h2>family</h2>
                    </div>
                  </div>

                  {/* <div className="article__body__title-box author">
                    <h2>Author</h2>
                    <input
                      type="textarea"
                      value={articleObj.author}
                      onChange={handleChange}
                      name="author"
                    />
                  </div> */}
                </div>

                <div className='article__body mt-5'>
                  <div className='article__body__title-box featured'>
                    <h2>Featured Image</h2>
                    <div className='overlay__input'>
                      <div className='overlay__input__input'>
                        <img src='/assets/cloud_upload.png' />
                        <h2>Drag and drop or Tap to choose</h2>
                      </div>
                      <div className='overlay__input__input1'>
                        <input
                          type='file'
                          accept='image/png, image/jpeg'
                          onChange={getfile}
                        />
                      </div>
                    </div>

                    {previewImage !== '' && (
                      <div className='preview-image__box'>
                        <img src={previewImage} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </AddArticleBody>
        </div>
      </Scrollbars>
    </AddArticleWrapper>
  );
};

export default AddArticle;
