import React from 'react';
import style from 'styled-components';

export const AddArticleWrapper = style.div`
    // border: 1px solid red;
    height: 100%;
    // border: 1px solid red;
    display: flex;

    .ck-content {
        height: 400px !important;
    }

    .edit-article {
        // border: 1px solid blue;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
`;

export const EditBreadCrum = style.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .edit-article__breadcrum__title {
        display: flex;
        // border: 1px solid red;
        align-items: center;
        cursor: pointer;

        h2 {
            font-size: 16px;
            margin: 0;
            color: #66738F;
        }
    }
    
    .edit-article__breadcrum__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 23%;
        // border: 1px solid red;

        @media (max-width: 1511px) {
            width: 28%;
        }

        @media (max-width: 1440px) {
            width: 23%;
        }

        @media (max-width: 1313px) {
            width: 36%;
        }
        @media (max-width: 1201px) {
            width: 43%;
        }

        @media (max-width: 1115px) {
            width: 33%;
        }

        @media (max-width: 1047px) {
            width: 35%;
        }
        @media (max-width: 621px) {
            width: 65%;
        }

        button {
            border: 1px solid rgba(189, 189, 189, 0.44);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 20px;
            background: #fff;

            h2 {
                font-size: 14px;
                margin: 0;
                color: var(--mainColor);
            }
        }

        button.edit-article__breadcrum__body__btn--save {
            background: var(--mainColor);

            h2 {
                color: #fff;
            }
        }

        button.edit-article__breadcrum__body__btn--save.complete {
            background: green;
        }
    }
`;

export const AddArticleBody = style.div`
    
    flex: 1;
    display: flex;
    margin-top: 30px;
    .article__body {
        // border: 1px solid red;
        flex: 1;

        .article__body__title-box {
            margin-bottom: 40px;
            h2 {
                margin: 0;
                color: #4E4B66;
                font-size: 15px;
            }
            input {
                width: 90%;
                @media (max-width: 585px) {
                    width: 98%;
                } 
                margin-top: 14px;
                background: #FFFFFF;
                border: 1px solid #F2F2F2;
                box-sizing: border-box;
                padding: 15px 35px;
                font-weight: 600;
                font-size: 13px;
                letter-spacing: 0.75px;
                color: #4E4B66;

                &:focus {
                    outline: none;
                }
            }
        }

        .body-editor {
            margin-top: 60px !important;

            .editor {
                width: 90%;

                @media (max-width: 585px) {
                    width: 98%;
                }        
            }
        }

        .featured {

            .overlay__input {
                overflow: hidden;
                position: relative;
                margin-top: 20px;
                height: 60px;
                width: 90%;
                @media (max-width: 585px) {
                    width: 98%;
                } 
                .overlay__input__input {
                    // margin-top: 15px;
                    position: relative;
                    padding: 15px 35px;
                    border: 1.5px dashed #F2F2F2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 60px;

                    img {
                        width: 3%;
                        margin-right: 10px;
                    }

                    h2 {
                        letter-spacing: 0.75px;
                        margin: 0;
                        color: #6E7191;
                        font-weight: 400;
                        font-size: 14px;
                    }
                }

                .overlay__input__input1 {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    opacity: 0;
                    input {
                        width: 100%;
                        margin: 0;
                        height: 60px;
                    }
                }
            }

            .preview-image__box {
                width: 100%;
                margin: 10px auto;
                img {
                    width: 100%;
                }
            }
        }
    }

    .article__sidebar {
        width: 450px;
        @media (max-width: 1440px) {
            width: 350px;
        }

        @media (max-width: 1115px) {
            // width: 45%;
            width: 300px;
        }

        @media (max-width: 585px) {
            width: 250px;
            display: none;
        }

        .article__sidebar__tag {
            h2 {
                margin: 0;
                color: #4E4B66;
                font-size: 15px;
            }


            .article__sidebar__tag-box {
                margin-top: 15px;
                background: #FFFFFF;
                border: 1px solid #F2F2F2;
                box-sizing: border-box;
                border-radius: 6px;
                height: 60px;
                padding: 10px;
                display: flex;
                align-items: center;

                .article__sidebar__tag-box__item {
                    background: var(--mainColor);
                    border-radius: 10px;
                    padding: 10px;
                    width: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                    cursor: pointer;

                    h2 {
                        margin: 0;
                        color: #fff;
                        font-size: 13px;
                    }
                }
            }

            .article__sidebar__tag-suggested {
                padding-top: 35px;
                h2 {
                    color: #4E4B66;
                    letter-spacing: 0.75px;
                    margin: 0;
                    font-size: 14px;
                }

                .suggestion-box {
                    margin-top: 15px;
                    display: flex;


                    .suggestion-box__item {
                        margin-right: 5px;
                      h2 {
                        font-size: 14px;
                        margin: 0;
                        color: var(--mainColor);
                        cursor: pointer;
                      }  
                    }
                    
                }

                .author {
                    margin-top: 60px;
                    h2 {
                        margin: 0;
                        color: #4E4B66;
                        font-size: 14px;
                    }
                    input {
                        width: 90%;
                        @media (max-width: 585px) {
                            width: 98%;
                        } 
                        margin-top: 14px;
                        background: #FFFFFF;
                        border: 1px solid #F2F2F2;
                        box-sizing: border-box;
                        padding: 15px 35px;
                        font-weight: 600;
                        font-size: 13px;
                        letter-spacing: 0.75px;
                        color: #4E4B66;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
    }

`;
