import React, { createContext, useState, useEffect } from 'react';
import { getLocalItem, getToken } from '../components/helpers/authService';

export const SettingsContext = createContext();

export const SettingsContextProvider = (props) => {
    const [activeState, setActiveState] = useState('preference');
    const [activeState2, setActiveState2] = useState('roles');


    return <SettingsContext.Provider value={{
        activeState,
        activeState2,
        setActiveState,
        setActiveState2,
    }}>
        {props.children}
    </SettingsContext.Provider>
}