import React, { useState, useEffect, useContext } from "react";
import { useRecoilState } from "recoil";
import { toggleSidebar } from "../../globalState/localData";
import Navbar from "./Navbar.jsx";
import Sidebar from "./Sidebar";
import "./layout.css";
import style from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import { Modal } from "react-responsive-modal";

import { ReactInternetSpeedMeter } from "react-internet-meter";
import "react-internet-meter/dist/index.css";
import { SessionExpiredModal } from "../modals";
import { AuthContext } from "../../context/AuthContext";
import ReducerAction from "../../context/ReducerAction";
import Notif from "../../assets/notif.svg";
import Mail from "../../assets/mail.png";
import AppSocket from "../../methods/AppSocket";
import { dateFormater2 as dateFormater } from "../../components/helpers/dateFormater";
import { httpDelete } from "../../components/helpers/httpMethods";

export default function Layout(props) {
  let appState = useContext(AuthContext).state;
  const { dropdown, setDropdown } = useContext(AuthContext);
  let [sidebarState, setSidebarState] = useRecoilState(toggleSidebar);
  const [LastScrool, setLastScrool] = useState(0);
  const [ticking, setticking] = useState(false);
  const [Hidenav, setHideNav] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    AppSocket.createConnection();
  }, [Hidenav]);

  const clearNotification = async () => {
    let res = await httpDelete(`notification/delete_notifications`);
    if (res) {
      setLoading(false);
    }
  };

  console.log("Notif", appState);

  return (
    <React.Fragment>
      <LayoutWrapper>
        <div id="hideNav">
          <Navbar browserRouter={props.browserRouter} />
        </div>
        <Sidebar
          browserRouter={props.browserRouter}
          currentRoute={props.currentRoute}
        />
        <div>
          <section
            onClick={() =>
              setSidebarState({ ...sidebarState, openSidebar: false })
            }
            className="app-container"
          >
            <Scrollbars
              style={{ flex: 1 }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              {props.children}
            </Scrollbars>
          </section>
        </div>
        {/* modals */}
        <SessionExpiredModal
          open={appState.modals === ReducerAction.modals.expiredToken}
          navigation={props.browserRouter}
        ></SessionExpiredModal>
        {dropdown && (
          <div className="dropdown">
            <div className="dropdown__header">
              <div className="dropdown__header1">
                <svg
                  className="mr-2"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="20"
                    cy="20"
                    r="19.5556"
                    fill="white"
                    stroke="#EE7F0D"
                    stroke-width="0.888889"
                  />
                  <path
                    d="M24.8887 22.7637L26.6803 24.5553V25.4303H12.4303V24.5553L14.222 22.7637V18.347C14.222 16.9581 14.5692 15.7498 15.2637 14.722C15.9859 13.6942 16.972 13.0276 18.222 12.722V12.097C18.222 11.7359 18.347 11.4303 18.597 11.1803C18.847 10.9026 19.1664 10.7637 19.5553 10.7637C19.9442 10.7637 20.2637 10.9026 20.5137 11.1803C20.7637 11.4303 20.8887 11.7359 20.8887 12.097V12.722C22.1387 13.0276 23.1109 13.6942 23.8053 14.722C24.5276 15.7498 24.8887 16.9581 24.8887 18.347V22.7637ZM19.5553 28.097C19.0553 28.097 18.6248 27.9303 18.2637 27.597C17.9303 27.2359 17.7637 26.8192 17.7637 26.347H21.347C21.347 26.8192 21.1664 27.2359 20.8053 27.597C20.4442 27.9303 20.0276 28.097 19.5553 28.097Z"
                    fill="#4E4B66"
                  />
                </svg>
                <h2>Notifications</h2>
              </div>

              <div className="dropdown__header1">
                {/* <div className="clear-all" onClick={clearNotification}>
                  <h2>Clear</h2>
                </div> */}
                <svg
                  onClick={() => {
                    setDropdown(false);
                  }}
                  style={{ cursor: "pointer" }}
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_b)">
                    <circle cx="20" cy="20" r="20" fill="#F7F7FC" />
                  </g>
                  <path
                    d="M14 14L26.7742 26.7742"
                    stroke="#14142B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 26.7734L26.7742 13.9992"
                    stroke="#14142B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_b"
                      x="-11"
                      y="-11"
                      width="62"
                      height="62"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImage" stdDeviation="5.5" />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>

            <div className="dropdown-body">
              {appState?.notifications?.map((el, i) => {
                return (
                  <div key={i} className="dropdown-body__title mb-4">
                    <img src={Mail} />
                    <div>
                      <h2>
                        From{" "}
                        <span>
                          TNS Admin -{` `}
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                            className="text-black-50"
                          >
                            {dateFormater(el?.createdAt)}
                          </span>
                        </span>
                      </h2>
                      <div className="mt-2">
                        <p>
                          <span className="text-black-50">{el.title}</span>-{" "}
                          {el?.body}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </LayoutWrapper>
    </React.Fragment>
  );
}

const LayoutWrapper = style.div`
  position: relative;

  .dropdown {
    position: fixed;
    right: 0;
    top: 80px;
    width: 420px;
    background: #fff;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    padding: 30px;

    .dropdown-body {
      margin-top: 30px;

      height: 300px;

      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .dropdown-body__title {
        display: flex;

        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }

        h2 {
          font-size: 14px;
          color: #4E4B66;
          font-weight: 500;
          margin: 0;

          span {
            font-weight: bold;
            font-size: 15px;
          }
        }
        p {
          font-size: 12px;
          color: #4E4B66;
          
        }
      }
    }

    .dropdown__header {
      display: flex;
      justify-content: space-between;

      .dropdown__header1 {
        display: flex;
        align-items: center;

        h2 {
          font-size: 16px;
          margin: 0;
        }

        .clear-all {
          background: #F7F7FC;
          border-radius: 25px;
          padding: 10px;
          margin-right: 10px;
        }
      }
    }
  }
`;
