import React, { useState, useEffect } from "react";
import style from "styled-components";
import ArticlesTable from "../../../components/tables/articles";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Add,
  Download,
  Cloud,
  List,
  GripImg,
} from "../../../assets/icons/svgs";
import {
  ArticlesWrapper,
  EditionInfoWrapper,
  ArticleGridWrapper,
} from "./articleCss";
import {
  axiosCalls,
  axiosFormData,
  httpDelete,
  httpPatch,
} from "../../../components/helpers/httpMethods";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import logo from "../../../assets/images/img3.png";
import swal from "sweetalert";
import { Table } from "reactstrap";
import parse from "html-react-parser";
import truncateWithEllipses from "../../../helpers/truncate";
import Dragable from "./dragable";
import "./draggable.css";
import { hideLoader, showLoader } from "../../../components/helpers/loader";
const Articles = (props) => {
  const id = props.match.params.id;
  const [editionId, setEditionId] = useState("");
  const [edition, setEdition] = useState({});
  const [editionName, setEditionName] = useState("");
  const [pubedDate, setPubedData] = useState(new Date());
  const [selectedCat, setSelectedCat] = useState("");
  const [cats, setCats] = useState([]);
  const [editionUrl, setEditionUrl] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [article, setArticles] = useState([]);
  const [file, setFile] = useState(null);
  const [grid, setGrid] = useState(true);
  const [editionStatus, setEditionStatus] = useState("");
  const [sortArticles, setSortArticles] = useState([]);
  const [sortState, setSortState] = useState(true);
  useEffect(() => {
    getSingleEdition();
    getCats();
    getArticles();
    hideLoader();
  }, []);

  const getSingleEdition = async () => {
    let res = await axiosCalls(`/edition/${id}`);
    if (res) {
      hideLoader();
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }

      console.log("succcess", res.data);
      setEditionStatus(res.data.edition.status);
      setEditionId(res.data.edition.editionId);
      setEditionName(res.data.edition.editionName);
      setEditionUrl(res.data.edition.image);
      setPubedData(new Date(res.data.edition.publishedDate));
      setSelectedCat(res.data.edition.categoryId);
      setEdition(res.data.edition);
    }
  };

  const getCats = async () => {
    setCats([]);

    let res = await axiosCalls(`/categories`, "GET", null, "load");
    if (res) {
      hideLoader();
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }

      console.log("cats", res.data.categories);
      setCats(res.data.categories);
    }
  };

  const getfile = (e) => {
    const objectURL = URL.createObjectURL(e.target.files[0]);
    // setPreviewImage(objectURL);
    setPreviewImage(objectURL);
    setFile(e.target.files[0]);
  };

  const getArticles = async () => {
    let res = await axiosCalls(`/get_articles/${id}`, "GET", "load");
    if (res) {
      hideLoader();
      if (res.er) {
        console.log(res.er);
        setArticles([]);
        return;
      }
      console.log("hgjg", res.data);
      setArticles(res.data.articles);
    }
  };

  const updateEdition = async () => {
    console.log(pubedDate);

    if (selectedCat === "") {
      return NotificationManager.info("Please select edition category");
    }
    let data = {
      editionName,
      categoryId: selectedCat,
      status: "active",
      publishedDate: pubedDate,
    };
    let res = await axiosCalls(`/update_edition/${id}`, "PATCH", data);
    if (res) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }
      swal("Congratulations!", "Your Edition has been updated", "success");
      props.history.push("/home");
    }
  };

  const updateEditionWithoutActivating = async () => {
    let fd = new FormData();
    file && fd.append("file", file);
    fd.append("editionName", editionName);
    fd.append("publishedDate", pubedDate);
    fd.append("categoryId", selectedCat);

    let res = await axiosFormData(
      `/update_edition_without_activating/${id}`,
      "PATCH",
      fd
    );
    if (res) {
      hideLoader();
      if (res.er) {
        console.log(res.er);
        if (res.er.data) {
          return NotificationManager.error(
            Object.values(res.er.data).join("   ")
          );
        }
        return NotificationManager.error(res.er.message);
      }
      swal(
        "TheNewspaperStand",
        "You have successfully updated your Edition",
        "success"
      ).then(() => {
        props.history.goBack();
      });
    }
  };

  const publishEdition = async () => {
    if (editionStatus === "active") {
      return updateEdition();
    }
    let fd = new FormData();
    fd.append("file", file);
    fd.append("editionName", editionName);
    fd.append("publishedDate", pubedDate);
    fd.append("categoryId", selectedCat);

    let res = await axiosFormData(`/publish_digital_edition/${id}`, "POST", fd);
    if (res) {
      hideLoader();
      if (res.er) {
        console.log(res.er);
        if (res.er.data) {
          return NotificationManager.error(
            Object.values(res.er.data).join("   ")
          );
        }
        return NotificationManager.error(res.er.message);
      }
      swal(
        "TheNewspaperStand",
        "You have successfully created your Edition",
        "success"
      ).then(() => {
        props.history.goBack();
      });
    }
  };

  const handleSortArticle = async () => {
    let sortErticleData = sortArticles.items.map((data, index) => {
      let orderNo = (data["orderNo"] = index);
      let newData = {
        orderNo,
        id: data.id,
      };

      return newData;
    });
    let setD = { orderList: sortErticleData };
    console.log(">>>", setD);
    showLoader();
    let res = await httpPatch(
      `save_articles_order/${props.match.params.id}`,
      setD
    );
    if (res) {
      hideLoader();
      if (res.code == 200) {
        swal("Success", "Article updated successfully.", "success");
      }
    }
  };

  const handleDeleteArticle = async (id) => {
    showLoader();
    let res = await httpDelete(`delete_article/${id}`);
    if (res.data) {
      hideLoader();
      if (res.data.code == 200) {
        getArticles();
        swal("Success", res.data.message, "success");
      }
    }
  };

  return (
    <ArticlesWrapper>
      <Scrollbars
        className="article--scrolbar"
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <div className="article--header">
          <div className="article--header__title-box">
            <h2>{edition?.editionName}</h2>
          </div>

          <div className="article--header__btn-box">
            <div
              className="Pub--btn"
              onClick={() =>
                props.history.push(
                  `/publications/articles/add/${id}_${editionId}`
                )
              }
            >
              <Add />
              <h2>Add article</h2>
            </div>
            <div
              className="Pub--btn save-btn"
              onClick={updateEditionWithoutActivating}
            >
              <h2>Save</h2>
            </div>
            <div className="Pub--btn publish-btn" onClick={publishEdition}>
              <Download />
              <h2>Publish</h2>
            </div>
          </div>
        </div>
        <EditionInfoWrapper>
          <div className="edition-info--cover-image-box">
            <h2>Cover Image</h2>
            <div className="edition-info--cover-image-box__image-container">
              <img
                src={
                  editionUrl ? editionUrl : previewImage ? previewImage : logo
                }
              />
              <div className="box__image-container__overlay">
                <Cloud />
                <div className="overlay__title-box">
                  <h2>Change Cover photo</h2>
                </div>
              </div>
              <div
                className="input-wrapper"
                onClick={() => {
                  console.log("i have been clicked");
                }}
              >
                <input
                  type="file"
                  accept="image/png, image/jpeg, HEIC"
                  onChange={getfile}
                />
              </div>
            </div>
          </div>

          <div className="edition-info--input-box">
            <div className="edition-info--input-box__container">
              <div className="input-box__container-box">
                <h2>Edition Name</h2>
                <input
                  value={editionName}
                  onChange={(e) => setEditionName(e.target.value)}
                />
              </div>
              <div className="input-box__container-box">
                <h2>Published Date</h2>
                <input
                  type="date"
                  value={moment(pubedDate).format("yyyy-MM-DD")}
                  defaultValue={pubedDate}
                  onChange={(e) => {
                    setPubedData(new Date(e.target.value));
                  }}
                />
              </div>
            </div>

            <div className="input-box__container-box1">
              <h2>Category</h2>
              <select
                value={selectedCat}
                onChange={(e) => setSelectedCat(e.target.value)}
                style={{ textTransform: "capitalize" }}
              >
                <option>Select category</option>
                {cats.map((el, i) => {
                  return (
                    <option value={el.id} key={i}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </EditionInfoWrapper>

        {article.length > 0 && (
          <ArticleGridWrapper>
            <div className="article-grid--header">
              <h2>Articles</h2>
              <div className="article-grid--header--btn-container">
                <button
                  className={grid ? "active" : null}
                  onClick={() => setGrid(true)}
                >
                  <div className="mr-2">
                    <List active={grid ? "df" : null} />
                  </div>
                  <h2>List view</h2>
                </button>
                <button
                  className={!grid ? "active" : null}
                  onClick={() => setGrid(false)}
                >
                  <div className="mr-2">
                    <GripImg active={!grid} />
                  </div>
                  <h2>Grid view</h2>
                </button>
              </div>
            </div>
            {!grid && (
              <div className="article-grid--body">
                <div className="article-grid--body__row">
                  {article?.map((el, i) => {
                    return (
                      <div key={i} className="article-grid--body__row-item">
                        <div className="grid--body__row-item__image-container">
                          {el?.image == null ? (
                            <p
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "1px solid grey",
                                borderRadius: "5px",
                                textAlign: "center",
                                padding: "10px",
                                paddingTop: "40px",
                              }}
                            >
                              No Featured Image present
                              <br />
                              <span
                                onClick={() => {
                                  console.log("clicked");
                                  props.history.push(
                                    `/publications/articles/edit/${el.id}`
                                  );
                                }}
                                style={{
                                  marginTop: "10px",
                                  color: "orange",
                                  cursor: "pointer",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                }}
                              >
                                Edit
                              </span>
                            </p>
                          ) : (
                            <img src={el?.image} />
                          )}
                        </div>
                        <div className="row-item__image-container--content">
                          <div className="d-flex justify-content-between">
                            <h2>{el.title.substr(0, 24)}...</h2>
                            <p className="text-capitalize">{el.status}</p>
                          </div>

                          <p>{el.byLine}</p>
                          <h3>{moment(el.createdAt).format("DD/MM/yyy")}</h3>
                          <div className="image-container--content__btn">
                            {/* <h2>View</h2> */}
                            <h2
                              onClick={() => {
                                console.log("clicked");
                                props.history.push(
                                  `/publications/articles/edit/${el.id}`
                                );
                              }}
                            >
                              Edit
                            </h2>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div style={{ flex: 1 }}></div>
                </div>
              </div>
            )}

            {grid && (
              <div className="article-list__table-view mt-5 mb-5">
                <Table hover responsive borderless>
                  <thead className="dragElementHeader">
                    <th>
                      <td>N/N</td>
                    </th>

                    <th>
                      <td>Title</td>
                    </th>
                    <th>
                      <td>Byline</td>
                    </th>
                    <th>
                      <td>Created</td>
                    </th>
                    <th>
                      <td>Status</td>
                    </th>
                    <th>
                      <td>Action</td>
                    </th>
                  </thead>

                  <Dragable
                    article={article}
                    history={props.history}
                    setSortArticles={setSortArticles}
                    setSortState={setSortState}
                    handleDeleteArticle={handleDeleteArticle}
                  />
                </Table>
                <button
                  style={{
                    background: "#ee7f0d",
                    color: "white",
                    padding: "12px",
                    borderRadius: "5px",
                  }}
                  onClick={handleSortArticle}
                  disabled={sortState}
                >
                  Save sort
                </button>
              </div>
            )}
          </ArticleGridWrapper>
        )}
        {/* <ArticlesTable nav={props.history} /> */}
      </Scrollbars>
    </ArticlesWrapper>
  );
};

export default Articles;
