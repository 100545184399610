import React, { useState, useEffect, useContext } from "react";
import DashboardOverview from "./overviewChart";
import ReachedBy from "./reachedByDevice";
import "./index.css";
import DatePicker from "react-datepicker";
import PopularEditions from "../../components/tables/popularEditions";
import PublicationTable from "../../components/tables/publications";
import Breadcrum from "../../components/shared/breadcrum";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { getPublicationTypes } from "../../controllers/publication/getPublications";
import { Down } from "../../assets/down";
import { Reload, ReloadCol } from "../../assets/reload";
import { CopyToClipboard } from 'react-copy-to-clipboard';

// contexts
import { AuthContext } from "../../context/AuthContext";
import { PublicationContext } from "../../context/PublicationContext";
import { DashboardContext } from "../../context/DashboardContext";
import { SettingsContext } from "../../context/SettingsContext";
import numeral from "numeral";
import swal from "sweetalert";
import bg2 from "../../assets/images/bgPub.jpg";

import Switch from "react-switch";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-toastify/dist/ReactToastify.css";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import {
  axiosCalls,
  httpGet,
  axiosFormData,
} from "../../components/helpers/httpMethods";
import {
  getLocalItem,
  setLocalItem,
} from "../../components/helpers/authService";
import { Modal } from "react-responsive-modal";

import { Withdraw } from "../../assets/icons/svgs";
import moment from "moment";
import style from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import AppSocket from "../../methods/AppSocket";
import { DashWrapper, WalletHistoryWrapper } from "./indexCss";
import _ from "lodash";

const Home = (props) => {
  let appState = useContext(AuthContext).state;
  
  const [startDate, setStartDate] = useState(new Date());
  const [visitCount, setVisitCount] = useState(0)
  const [pubType, setPubType] = useState([]);
  const { setAuth, onBording, setOnBording, auth } = useContext(AuthContext);
  const { renderOpt, setRenderOpt } = useContext(DashboardContext);
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const { setPubReload, pubReload, currentPublication } =
    useContext(PublicationContext);
  const { activeState, setActiveState } = useContext(SettingsContext);
  const [wallet, setWallet] = useState({});
  // const [renderOpt, setRenderOpt] = useState('create');
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedOften, setSelectedOften] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [copied, setCopied] = useState(false);

  const [pub, setPub] = useState({});

  const [withdrawalAmount, setWithdrawalAmount] = useState(0);

  const [modal, setModal] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [company, setCompany] = useState({});
  const [twoFactorAuth, setTwoFactorAuth] = useState(true);

  const [walletHistory, setWalletHistory] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [withdrawalHistoryModal, setWithdrawalHistoryModal] = useState(false);

  const [stats, setStats] = useState({});
  const [statsCurrent, setStatsCurrent] = useState({});

  const [logo, setLogo] = useState({});

  const [typee, setTypee] = useState("create");

  const [oneTime, setOneTime] = useState(0);
  const [weekly, setWeekly] = useState(0);
  const [monthly, setMonthly] = useState(0);
  const [yearly, setYearly] = useState(0);

  const [oneTimed, setOneTimed] = useState(0);
  const [weeklyd, setWeeklyd] = useState(0);
  const [monthlyd, setMonthlyd] = useState(0);
  const [yearlyd, setYearlyd] = useState(0);
  const [freeEndDate, setEndDate] = useState(new Date());

  const [quarterly, setQuarterly] = useState(0);
  const [biannually, setBiannually] = useState(0);

  const [dropdownItem, setDropdrow] = useState([]);

  const [pubConGraphData, setPubConGraphData] = useState([]);
  const [pubLabels, setPubLabels] = useState([]);
  const [pubGraphData, setPubGraphData] = useState([]);

  const [busGraphData, setBusGraphData] = useState([]);
  const [busLabels, setBusLabels] = useState([]);

  const [busConGraphData, setBusConGraphData] = useState([]);

  const [activeCur, setActiveCur] = useState("NGN");

  const [groupPubBy, setGroupPubBy] = useState("month");
  const [groupBusBy, setGroupBusBy] = useState("month");

  const [groupPubByLoading, setGroupPubByLoading] = useState(false);
  const [groupBusByLoading, setGroupBusByLoading] = useState(false);

  const [statsRawaData, setStatsRawData] = useState({});

  useEffect(() => {
    // AppSocket.createConnection();
    // if (!auth) {
    //   return props.history.push("/");
    // }

    getSinglePub();
    getWalletHistory();
    getCompanyData();
    getDashoardStats();
    getVisitCount()
    if (currentPublication !== "" || onBording === true) {
      // setRenderOpt('create');
    }
    getTypes();
  }, [reload, id]);

  // useEffect(() => {
  //   getGraphStats();
  // }, []);

  useEffect(() => {
    getGraphStats();
  }, [groupBusBy]);

  useEffect(() => {
    filterData();
  }, [groupPubBy]);

  const filterData = async () => {
    setGroupPubByLoading(true);
    let res = await axiosCalls(
      `/current_graph_stats?filter=${groupPubBy}`,
      "GET",
      null,
      "noload"
    );
    if (res) {
      if (res.er) {
        setGroupPubByLoading(false);
        return;
      }
      console.log(groupPubBy);
      console.log(res);
      console.log("Total pub", res?.data?.totalPublicationSales);

      let manipulatedData = res?.data?.totalPublicationSales
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((el) => {
          console.log("this is the el", el);
          el["month"] = moment(el.createdAt).format("MMMM yyyy");
          return el;
        });

      console.log("manipulated", manipulatedData);

      let grouped_data = _.groupBy(manipulatedData, "month");

      let dd = Object.keys(grouped_data).map((el) => {
        return grouped_data[el]
          .map((item) => item.amount)
          .reduce((a, b) => +a + +b);
      });

      let pubMonths = getGraphDataMonths(Object.keys(grouped_data));
      setPubLabels(pubMonths);
      setGroupPubByLoading(false);
      return setPubGraphData(dd);
    }
  };

  const getGraphStats = async () => {
    setGroupBusByLoading(true);
    let res = await axiosCalls(
      `/graph_stats?filter=${groupBusBy}`,
      "GET",
      null,
      "noload"
    );
    console.log("bus", res);
    if (res) {
      if (res.er) {
        setGroupBusByLoading(false);
        return;
      }
      // totalPublicationSales
      console.log(groupBusBy, res);
      // console.log(res);
      // console.log('raw', res?.data);
      let manipulatedData = res?.data?.totalSales
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((el) => {
          console.log("this is the el", el);
          el["month"] = moment(el.createdAt).format("MMMM yyyy");
          return el;
        });

      console.log("manipulated sales", manipulatedData);

      let grouped_data = _.groupBy(manipulatedData, "month");

      let dd = Object.keys(grouped_data).map((el) => {
        return grouped_data[el]
          .map((item) => item.amount)
          .reduce((a, b) => +a + +b);
      });

      let pubMonths = getGraphDataMonths(Object.keys(grouped_data));
      setBusLabels(pubMonths);
      setGroupBusByLoading(false);
      return setBusGraphData(dd);
    }
  };

  const groupData = (data, el, type) => {
    // sort data in ascending order then append date to group by
    let conData = data
      ?.sort((a, b) => b.createdAt - a.createdAt)
      .map((itm) => {
        // appending date
        if (type === "bus") {
          switch (groupBusBy) {
            case "year":
              itm[groupBusBy] = moment(itm.createdAt).format("yyyy");
              break;
            case "month":
              itm[groupBusBy] = moment(itm.createdAt).format("MMMM yyyy");
              break;
            case "today":
              itm[groupBusBy] =
                moment(itm.createdAt).format("DD MMMM yyyy") ===
                moment(new Date()).format("DD MMMM yyyy")
                  ? moment(new Date()).format("DD")
                  : "";
              break;
          }
        } else {
          switch (groupPubBy) {
            case "year":
              itm[groupPubBy] = moment(itm.createdAt).format("yyyy");
              break;
            case "month":
              itm[groupPubBy] = moment(itm.createdAt).format("MMMM yyyy");
              break;
            case "today":
              itm[groupPubBy] =
                moment(itm.createdAt).format("DD MMMM yyyy") ===
                moment(new Date()).format("DD MMMM yyyy")
                  ? moment(new Date()).format("DD")
                  : null;
              break;
          }
        }

        return itm;
      });
    // group by date
    let grouped_data = _.groupBy(conData, groupPubBy);
    console.log("grouped", grouped_data, type);
    // console.log(
    //   "con",
    // return;
    let dd = Object.keys(grouped_data).map((el) => {
      return grouped_data[el]
        .map((item) => item.amount)
        .reduce((a, b) => +a + +b);
    });

    if (el === "totalPublicationSales") {
      let pubMonths = getGraphDataMonths(Object.keys(grouped_data));
      setPubLabels(pubMonths);
      return setPubGraphData(dd);
    }

    if (el === "totalSales") {
      let busMonths = getGraphDataMonths(Object.keys(grouped_data));
      setBusLabels(busMonths);
      return setBusGraphData(dd);
    }

    setPubConGraphData(dd);
  };

  const getGraphDataMonths = (data) => {
    return data.map((el) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[new Date(el).getMonth()];
    });
  };

  const getSinglePub = async () => {
    setOneTime(0);
    setOneTimed(0);
    setWeekly(0);
    setWeeklyd(0);
    setMonthly(0);
    setMonthlyd(0);
    setYearly(0);
    setYearlyd(0);
    let companyData = await getLocalItem("company");
    // console.log("triggered2");
    setCompany(JSON.parse(companyData));
    if (id) {
      let res = await httpGet(`publication/${id}`);
      if (res) {
        if (!res.success) {
          return;
        }
        setPub(res.data.publication);
        setName(res?.data?.publication.name);

        // setTwoFactorAuth(res.data.publication.isPaid);

        if (res.data.publication.oneOffFee) {
          setOneTime(res.data.publication.oneOffFee);
        }
        if (res.data.publication.oneOffDollarFee) {
          setOneTimed(res.data.publication.oneOffDollarFee);
        }
        if (res.data.publication.weeklyFee) {
          setWeekly(res.data.publication.weeklyFee);
        }
        if (res.data.publication.quarterlyFee) {
          setQuarterly(res.data.publication.quarterlyFee);
        }
        if (res.data.publication.biannuallyFee) {
          setBiannually(res.data.publication.biannuallyFee);
        }
        if (res.data.publication.weeklyDollarFee) {
          setWeeklyd(res.data.publication.weeklyDollarFee);
        }
        if (res.data.publication.monthlyFee) {
          setMonthly(res.data.publication.monthlyFee);
        }
        if (res.data.publication.monthlyDollarFee) {
          setMonthlyd(res.data.publication.monthlyDollarFee);
        }
        if (res.data.publication.annualFee) {
          setYearly(res.data.publication.annualFee);
        }
        if (res.data.publication.annualDollarFee) {
          setYearlyd(res.data.publication.annualDollarFee);
        }
      }
    }
  };

  const getWalletHistory = async () => {
    let res = await axiosCalls(`/vendor_wallet_history`, "GET", null, "load");
    if (res) {
      if (res.er) {
        return;
      }
      setWalletHistory(res.data.walletHistory);
      setHistoryData(res.data);
    }
  };

  const getCompanyData = async () => {
    let res = await axiosCalls(`/vendor_wallet_details`, "GET", null, "load");
    if (res) {
      if (res.er) {
        return;
      }
      setWallet(res.data);
    }
  };

  const getTypes = async () => {
    const data = await getPublicationTypes();
    if (data) {
      if (data.er) {
        // NotificationManager.error(data.er.data.message);
        if (
          data.er.data.message ===
          "Unauthorized, Your token is invalid or expired"
        ) {
          setAuth(false);
          props.history.push("/");
        }
        return;
      }
      setPubType(data.data.publicationTypes);
    }
  };

  console.log("how often", selectedCat);
  console.log("often", selectedOften);
  console.log(pub);
  console.log(typee);
  console.log(twoFactorAuth);

  const handleChange = (e) => {
    if (e.target?.name) {
      return setSelectedOften(e.target.value);
    }
    if (e.target.value === "eb244fbc-ab2c-4a51-9b6f-034d987f352e") {
      setSelectedOften("one-off");
      return setSelectedCat(e.target.value);
    }
    setSelectedOften("");
    setSelectedCat(e.target.value);
  };

  const submit = async () => {
    // e.preventDefault();
    if (
      selectedCat === "" ||
      name === "" ||
      selectedOften === "" ||
      phone === "" ||
      website === "" ||
      description === ""
    ) {
      return NotificationManager.error("All fields are required");
    }

    let data = new FormData();

    if (twoFactorAuth) {
      data.append("name", name);
      data.append("officialContact", phone);
      data.append("websiteUrl", website);
      data.append("description", description);
      data.append("typeId", selectedCat);
      data.append("isPaid", twoFactorAuth);
      data.append("subscriptionType", selectedOften.toLocaleLowerCase());
      data.append("file", logo);
    } else {
      data.append("name", name);
      data.append("officialContact", phone);
      data.append("websiteUrl", website);
      data.append("description", description);
      data.append("typeId", selectedCat);
      data.append("subscriptionType", selectedOften.toLocaleLowerCase());
      data.append("isPaid", twoFactorAuth);
      data.append("freeEndDate", freeEndDate);
      data.append("file", logo);
    }

    let res = await axiosFormData(`/create_publication`, "POST", data);
    if (res) {
      if (res.er) {
        hideLoader();
        return NotificationManager.error(res.er.message);
      }
      let companyData = await getLocalItem("company");
      setLocalItem("company", {
        ...JSON.parse(companyData),
        currentPublication: res.data.id,
      });
      setPubReload(!pubReload);

      setTypee("pn");
    }
  };

  const getDashoardStats = async () => {
    let res = await axiosCalls(`/dashboard_stats`, "GET", null, "load");
    if (res) {
      if (res.er) {
        return;
      }
      setStatsCurrent(res.data.current);
      setStats(res.data.all);
    }
  };

  const withdrawAmount = async () => {
    if (!withdrawalAmount) {
      return;
    }
    let res = await axiosCalls(`/withdraw_wallet`, "POST", {
      amount: withdrawalAmount,
    });
    if (res) {
      if (res.er) {
        return swal("TheNewsPaperStand!", `${res.er.message}`, "error");
      }
      swal(
        "TheNewsPaperStand!",
        `You request has been sent, expect you funds within 48 hours`,
        "success"
      );
      setWalletModal(false);
      getCompanyData();
      getDashoardStats();
      getWalletHistory();
    }
  };

  const updatePublication = async (e) => {
    if (typee === "create") {
      return submit();
    }
    let data = null;
    if (typee === "pn") {
      data = {
        oneOffFee: oneTime,
        weeklyFee: weekly,
        quarterlyFee: quarterly,
        biannuallyFee: biannually,
        monthlyFee: monthly,
        annualFee: yearly,
      };
    } else {
      data = {
        oneOffDollarFee: oneTimed,
        weeklyDollarFee: weeklyd,
        monthlyDollarFee: monthlyd,
        annualDollarFee: yearlyd,
      };
    }

    let res = await axiosCalls(`/update_publication/${id}`, "PATCH", data);
    if (res) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }
      NotificationManager.success(res.message);
      if (typee === "pn") {
        // setTypee("pu");
        setRenderOpt("normal");
        swal(
          "Congratulations",
          "You have successfully created a publication. Proceed to manage -> edition to create your first edition",
          "success"
        );
        setTypee("create");
      }
      // if (typee === "pu") {
      //   setRenderOpt("normal");
      //   swal(
      //     "Congratulations",
      //     "You have successfully created a publication. Proceed to manage -> edition to create your first edition",
      //     "success"
      //   );
      //   setTypee("create");
      // }
      getSinglePub();
    }
  };

  const getVisitCount = async () => {
    if (id) {
      let res = await axiosCalls(`/publication/visit_count/${id}`, "GET", null, "load");
      if (res.code === 200) { 
        setVisitCount(res.data.visitCount || 0)
      }
    }
  }

  return (
    <>
      <Wrapper>
        <Scrollbars
          style={{ flex: 1 }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {!renderOpt === "create" && (
            <Breadcrum
              title={renderOpt !== "complete" ? "Get Started" : "Dashboard"}
            />
          )}
          {renderOpt === "create" && (
            <div className="mod">
              <h2 className="text-center pub-title">Create a Publication</h2>
              <div className="pub-wrapper">
                <div className="bg-container">
                  <img src={bg2} alt="pub image" className="img-fluid" />
                </div>

                <Scrollbars
                  style={{ flex: 1 }}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                >
                  <div className="pub-content ">
                    <div className="pub-content--wrapper">
                      <div className="pub-content--wrapper--tab">
                        <div
                          className={`tab--div ${
                            typee === "create" ? "tab-div--active" : null
                          }`}
                        >
                          <h1>Create Publication</h1>
                        </div>
                        <div
                          className={`tab--div ${
                            typee === "pn" ? "tab-div--active" : null
                          }`}
                        >
                          <h1>Price in Naira</h1>
                        </div>
                      </div>
                      {typee === "create" && (
                        <div className="pub-content--wrapper--content">
                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Publication Name</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                name="name"
                                type="text"
                              />
                              <div className="text-small">
                                For Books, Publication Name is equal to Book
                                Title
                              </div>
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Official Contact</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                name="phone"
                                type="number"
                              />
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Website URL</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                name="website"
                                type="text"
                              />
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Select Publication Type</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <select
                                value={selectedCat}
                                onChange={handleChange}
                                style={{ textTransform: "capitalize" }}
                              >
                                <option>--Choose--</option>
                                {pubType.map((el, i) => {
                                  return (
                                    <option value={el.id} key={i}>
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          {selectedCat !==
                            "eb244fbc-ab2c-4a51-9b6f-034d987f352e" && (
                            <div className="wrapper--content-1">
                              <div className="wrapper--content-2">
                                <h2>How Often Do You Publish?</h2>
                              </div>
                              <div className="wrapper--content-3">
                                <select
                                  value={selectedOften}
                                  name="often"
                                  onChange={handleChange}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <option>--Choose--</option>
                                  {[
                                    "one-off",
                                    "Weekly",
                                    "Monthly",
                                    "Quarterly",
                                    "Biannually",
                                    "Annually",
                                  ].map((el, i) => {
                                    return (
                                      <option
                                        value={el.toLocaleLowerCase()}
                                        key={i}
                                      >
                                        {el.toLocaleLowerCase()}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )}

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Set Publication Logo</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                onChange={(e) => setLogo(e.target.files[0])}
                                name="name"
                                type="file"
                                accept="image/*"
                              />
                              <div className="text-small">
                                For Books Publication Logo is equal to Author
                                Image
                              </div>
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Publication Description</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <textarea
                                placeholder="Description can not be more than 150 characters"
                                name="description"
                                rows="4"
                                cols="50"
                                maxlength="150"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              ></textarea>
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Paid Publication</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <Switch
                                checked={twoFactorAuth}
                                onChange={(e) => {
                                  setTwoFactorAuth(e);
                                }}
                                onColor="#EE7F0D"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={24}
                                width={50}
                              />
                            </div>
                          </div>

                          {!twoFactorAuth && (
                            <div className="wrapper--content-1">
                              <div className="wrapper--content-2">
                                <h2>End date</h2>
                              </div>
                              <div className="wrapper--content-3">
                                <input
                                  type="date"
                                  value={moment(freeEndDate).format(
                                    "yyyy-MM-DD"
                                  )}
                                  onChange={(e) => {
                                    setEndDate(new Date(e.target.value));
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {typee === "pn" && (
                        <div className="pub-content--wrapper--content">
                          <h2
                            className="text-center my-2"
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              letterSpacing: 0.1,
                              lineHeight: "15px",
                            }}
                          >
                            Kindly ignore any price section your publication
                            doesn't apply to, for example if your publication
                            comes out weekly, only set prices for weekly and
                            below values leaving out daily.
                          </h2>

                          {pub.type.name === "books" && (
                            <div className="wrapper--content-1">
                              <div className="wrapper--content-2">
                                <h2>One off (Book)</h2>
                              </div>
                              <div className="wrapper--content-3">
                                <input
                                  type="number"
                                  value={oneTime}
                                  onChange={(e) => {
                                    setOneTime(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          {pub.type.name !== "books" && (
                            <>
                              {pub.subscriptionType === "one-off" && (
                                <div className="wrapper--content-1">
                                  <div className="wrapper--content-2">
                                    <h2>Daily(One off)</h2>
                                  </div>
                                  <div className="wrapper--content-3">
                                    <input
                                      type="number"
                                      value={oneTime}
                                      onChange={(e) => {
                                        setOneTime(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {pub.subscriptionType ===
                                ("weekly" || "one-off") && (
                                <div className="wrapper--content-1">
                                  <div className="wrapper--content-2">
                                    <h2>Weekly</h2>
                                  </div>
                                  <div className="wrapper--content-3">
                                    <input
                                      type="number"
                                      value={weekly}
                                      onChange={(e) => {
                                        setWeekly(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {(pub.subscriptionType === "one-off" ||
                                pub.subscriptionType === "weekly" ||
                                pub.subscriptionType === "monthly") && (
                                <div className="wrapper--content-1">
                                  <div className="wrapper--content-2">
                                    <h2>Monthly</h2>
                                  </div>
                                  <div className="wrapper--content-3">
                                    <input
                                      type="number"
                                      value={monthly}
                                      onChange={(e) => {
                                        setMonthly(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {(pub.subscriptionType === "one-off" ||
                                pub.subscriptionType === "weekly" ||
                                pub.subscriptionType === "monthly" ||
                                pub.subscriptionType === "quarterly") && (
                                <div className="wrapper--content-1">
                                  <div className="wrapper--content-2">
                                    <h2>Quaterly</h2>
                                  </div>
                                  <div className="wrapper--content-3">
                                    <input
                                      type="number"
                                      value={quarterly}
                                      onChange={(e) => {
                                        setQuarterly(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {(pub.subscriptionType === "one-off" ||
                                pub.subscriptionType === "weekly" ||
                                pub.subscriptionType === "monthly" ||
                                pub.subscriptionType === "quarterly" ||
                                pub.subscriptionType === "annually" ||
                                pub.subscriptionType === "biannually") && (
                                <div className="wrapper--content-1">
                                  <div className="wrapper--content-2">
                                    <h2>Biannual</h2>
                                  </div>
                                  <div className="wrapper--content-3">
                                    <input
                                      type="number"
                                      value={biannually}
                                      onChange={(e) => {
                                        setBiannually(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {(pub.subscriptionType === "one-off" ||
                                pub.subscriptionType === "weekly" ||
                                pub.subscriptionType === "monthly" ||
                                pub.subscriptionType === "quarterly" ||
                                pub.subscriptionType === "annually") && (
                                <div className="wrapper--content-1">
                                  <div className="wrapper--content-2">
                                    <h2>Annual</h2>
                                  </div>
                                  <div className="wrapper--content-3">
                                    <input
                                      type="number"
                                      value={yearly}
                                      onChange={(e) => {
                                        setYearly(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}

                      {typee === "pu" && (
                        <div className="pub-content--wrapper--content">
                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>One time Fee</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                type="number"
                                value={oneTimed}
                                onChange={(e) => {
                                  setOneTimed(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Weekly</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                type="number"
                                value={weeklyd}
                                onChange={(e) => {
                                  setWeeklyd(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Monthly</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                type="number"
                                value={monthlyd}
                                onChange={(e) => {
                                  setMonthlyd(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="wrapper--content-1">
                            <div className="wrapper--content-2">
                              <h2>Yearly</h2>
                            </div>
                            <div className="wrapper--content-3">
                              <input
                                type="number"
                                value={yearlyd}
                                onChange={(e) => {
                                  setYearlyd(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="last-container">
                        <div className="last-container2">
                          <button onClick={() => updatePublication()}>
                            <h2>Save</h2>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          )}

          {renderOpt === "normal" && (
            <CardsWrapper>
              {/* <h2>{!appState?.loader ? "Hello i am null" : appState?.loader}</h2> */}
              <div className="card--title_box mt-5">
                <h2 className="cards--title">
                  Business Stats - All Publications
                </h2>
                <div className='pub-link-container'>
                        <div className='link-tab1'>
                          <h2>Publication Link: </h2>
                        </div>

                        <div className='link-tab2'>
                          <h2>
                            {/* {process.env.REACT_APP_BASE_URL}/ */}/
                            {name.toLowerCase().trim().replace(/ /g, '-')}
                          </h2>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {!copied && (
                              <CopyToClipboard
                                text={`${
                                  process.env.REACT_APP_USER_BASE_URL
                                }/publication/${name
                                  .toLowerCase()
                                  .trim()
                                  .replace(/ /g, '-')}`}
                                onCopy={(e) => {
                                  setCopied(true);
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 500);
                                }}
                              >
                                <i className='fa fa-copy' />
                              </CopyToClipboard>
                            )}

                            {copied && (
                              <span
                                style={{
                                  color: 'var(--mainColor)',
                                  fontSize: '12px',
                                }}
                              >
                                Copied
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
              </div>

              <div className="card--body">
                <div
                  style={{ marginTop: "25px" }}
                  className="card--body__item border"
                >
                  <h2>Total Earnings</h2>
                  <div className="card--body__item--body">
                    <h1>{stats.totalSales}</h1>
                  </div>
                </div>
                <div
                  style={{ marginTop: "25px" }}
                  className="card--body__item border"
                >
                  <h2>Edition Earnings</h2>
                  <div className="card--body__item--body">
                    <h1>{stats.totalEditionEarnings}</h1>
                  </div>
                </div>
                <div
                  style={{ marginTop: "25px" }}
                  className="card--body__item border"
                >
                  <h2>Change of Name Earnings</h2>
                  <div className="card--body__item--body">
                    <h1>{stats.totalConTransactions}</h1>
                  </div>
                </div>
                <div
                  style={{ marginTop: "25px" }}
                  className="card--body__item border"
                >
                  <h2>Total Subscribers</h2>
                  <div className="card--body__item--body">
                    <h1>{stats.totalSubscribers}</h1>
                  </div>
                </div>
                <div
                  style={{ marginTop: "25px" }}
                  className="card--body__item border"
                >
                  <h2>Active Subscribers</h2>
                  <div className="card--body__item--body">
                    <h1>{stats.activeSubscribers}</h1>
                  </div>
                </div>
              </div>
              {/* ================= */}
              <div className="card--title_box mt-5 mb-5">
                <h2 className="cards--title">Publication Stats - {pub.name}</h2>
              </div>
              <div className="card--body">
                <div style={{ width: "75%" , marginBottom: "50px"}} className="card--body">
                  <div className="card--body__item border">
                    <h2>Total Earnings</h2>
                    <div className="card--body__item--body">
                      <h1>{statsCurrent.totalPublicationSales}</h1>
                    </div>
                  </div>
                  <div className="card--body__item border">
                    <h2>Edition Earnings</h2>
                    <div className="card--body__item--body">
                      <h1>{statsCurrent.editionEarnings}</h1>
                    </div>
                  </div>
                  <div className="card--body__item border">
                    <h2>Change of Name Earnings</h2>
                    <div className="card--body__item--body">
                      <h1>{statsCurrent.totalConTransactions}</h1>
                    </div>
                  </div>
                  <div className="card--body__item border">
                    <h2>Total Subscribers</h2>
                    <div className="card--body__item--body">
                      <h1>{statsCurrent.totalPublicationSubscribers}</h1>
                    </div>
                  </div>
                  <div
                    // style={{ marginTop: "-100px" }}
                    className="card--body__item border"
                  >
                    <h2>Active Subscribers</h2>
                    <div className="card--body__item--body">
                      <h1>{statsCurrent.activePublicationSubscribers}</h1>
                    </div>
                  </div>
                  <div
                    // style={{ marginTop: "-100px" }}
                    className="card--body__item border"
                  >
                    <h2>Visit Count</h2>
                    <div className="card--body__item--body">
                      <h1>{visitCount}</h1>
                    </div>
                  </div>
                </div>
                <DashWrapper style={{ marginTop: "-1px" }}>
                  {/* <div className="mr-4"></div>
                  <div className="mr-4"></div> */}
                  <div className="dash__wallet border mb-5">
                    <div className="dash__wallet-content">
                      <div style={{ height: "10px" }}></div>
                      <div className="dash__wallet-content-item">
                        <div className="dash__wallet-content-item-cur">
                          <div
                            className={`content-item-cur__container ${
                              activeCur === "NGN" && "active"
                            }`}
                            onClick={() => {
                              setWalletHistory(historyData?.walletHistory);
                              setActiveCur("NGN");
                            }}
                          >
                            <p>NGN</p>
                          </div>
                          <div
                            className={`content-item-cur__container ${
                              activeCur === "USD" && "active"
                            }`}
                            onClick={() => {
                              setWalletHistory(
                                historyData?.dollarWalletHistory
                              );
                              setActiveCur("USD");
                            }}
                          >
                            <p>USD</p>
                          </div>
                        </div>
                        <div>
                          <h2>Wallet Balance</h2>
                        </div>

                        <h1>
                          {activeCur === "NGN"
                            ? `₦${numeral(wallet?.wallet?.amount).format(
                                "0,0"
                              )}`
                            : `$${numeral(wallet?.usdWallet.amount).format(
                                "0,0"
                              )}`}
                        </h1>
                        <div className="mt-1">
                          <h3>{activeCur === "NGN" ? `Naira` : `Dollar`}</h3>
                        </div>

                        <div>
                          <button onClick={() => setWalletModal(true)}>
                            <h3>Request Withdraw</h3>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className=" dash__wallet-footer">
                      <div className=" dash__wallet-footer-body">
                        <h2>Amount Withdrawn</h2>
                        <div className="mt-2">
                          <h1>
                            {activeCur === "NGN"
                              ? wallet?.withrawnAmount?.total || 0
                              : wallet?.usdWithrawnAmount?.total || 0}
                          </h1>
                        </div>
                        <div className="mt-2">
                          <h3>{activeCur === "NGN" ? `Naira` : `Dollar`}</h3>
                        </div>
                      </div>
                      <div
                        className="dash_wallet-footer_base"
                        onClick={() => {
                          setWithdrawalHistoryModal(true);
                        }}
                      >
                        <h2>Withdrawal History</h2>
                        <div className="ml-2">
                          <Reload color="red" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </DashWrapper>
              </div>
            </CardsWrapper>
          )}

          {walletModal && (
            <div>
              <Modal
                open={walletModal}
                onClose={() => setWalletModal(false)}
                center
                animationDuration={400}
                closeOnOverlayClick={true}
                focusTrapped={true}
                showCloseIcon={true}
                styles={{
                  overlay: {
                    background: "none",
                  },
                }}
              >
                <div className="modal-body-rs p-3">
                  <div className="wallet-modal">
                    <h2>Enter the Amount you want to withdraw</h2>
                    <input
                      type="phone"
                      value={withdrawalAmount}
                      onChange={(e) => setWithdrawalAmount(e.target.value)}
                      placeholder="0,0"
                    />
                    {/* </div> */}
                    <div className="btn-box">
                      <button onClick={() => withdrawAmount()}>
                        <h2>Withdraw</h2>
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )}

          {withdrawalHistoryModal && (
            <div>
              <Modal
                open={withdrawalHistoryModal}
                onClose={() => setWithdrawalHistoryModal(false)}
                center
                animationDuration={400}
                closeOnOverlayClick={true}
                focusTrapped={true}
                showCloseIcon={true}
                styles={{
                  overlay: {
                    // background: "none",
                  },
                }}
              >
                <WalletHistoryWrapper>
                  <div className="wallet-history__header">
                    <ReloadCol />
                    <div className="mt-3">
                      <h2>Withdrawal History</h2>
                    </div>
                  </div>
                  <div className="wallet-history__content">
                    <div className="wallet-history__content-header">
                      <div className="wallet-history__content-header-item">
                        <p>Amount</p>
                      </div>
                      <div className="wallet-history__content-header-item">
                        <p>Date</p>
                      </div>
                      <div className="wallet-history__content-header-odd">
                        <p>Status</p>
                      </div>
                    </div>
                  </div>
                  <div className="wallet-history__content-container">
                    {walletHistory
                      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                      .map((el, i) => {
                        return (
                          <div className="wallet-history__content item">
                            <div className="wallet-history__content-header">
                              <div className="wallet-history__content-header-item">
                                <p>
                                  {activeCur === "NGN" ? `₦` : `$`}
                                  {numeral(el.amount).format("0,0")}
                                </p>
                              </div>
                              <div className="wallet-history__content-header-item">
                                <p>
                                  {moment(el.createdAt).format("DD/MM/yyy")}
                                </p>
                              </div>
                              <div className="wallet-history__content-header-odd">
                                <p>{el?.paymentStatus}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </WalletHistoryWrapper>
              </Modal>
            </div>
          )}

          <div>
            <Modal
              open={modal}
              onClose={() => setModal(false)}
              center
              animationDuration={400}
              closeOnOverlayClick={true}
              focusTrapped={true}
              showCloseIcon={true}
              styles={{
                overlay: {
                  background: "none",
                },
              }}
            >
              <div className="modal-body-rs p-3">
                <div className="d-flex justify-content-center mb-4">
                  <img
                    src="../assets/newspaper.png"
                    className="img-fluid"
                    alt="newspaper"
                    width="15%"
                  />
                </div>

                <h3 className="mb-4">Upload your latest Edition</h3>

                <h4 className="mb-4 text-center">
                  It takes less than 10 minute to upload and sell your
                  publications. Start here!{" "}
                </h4>

                <div className="mt-5">
                  <Button
                    className="d-block btn-onbording"
                    onClick={() => {
                      setModal2(true);
                      setModal(false);
                    }}
                    width="100vw"
                  >
                    Got it
                  </Button>
                </div>
              </div>
            </Modal>
          </div>

          <div>
            <Modal
              open={modal2}
              onClose={() => setModal2(false)}
              center
              animationDuration={400}
              closeOnOverlayClick={true}
              focusTrapped={true}
              showCloseIcon={true}
              styles={{
                overlay: {
                  background: "none",
                },
              }}
            >
              <div className="modal-body-rs p-3">
                <div className="d-flex img--container border justify-content-center mb-4">
                  <img
                    src="../assets/wizard.png"
                    className="img-fluid"
                    alt="newspaper"
                    width="15%"
                  />
                </div>

                <h3 className="mb-4">THE PUNCH MAGAZINE</h3>

                <h2 className="mb-3 text-center" style={{ fontSize: "1vw" }}>
                  Welcome to{" "}
                  <span style={{ color: "var(--mainColor)" }}>
                    TheNewsPaperStand
                  </span>
                </h2>

                <h4 className="mb-4 text-center">
                  Your publication is currently not live. You have to complete
                  your setup and publish your first edition.
                </h4>

                <div className="mt-5">
                  <Button
                    onClick={() => {
                      setActiveState("preference");
                      props.history.push("settings");
                    }}
                    color="#EE7F0D"
                    style={{
                      background: "#EE7F0D",
                      fontWeight: "bold",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      fontSize: "12px",
                    }}
                    width="100vw"
                  >
                    Complete setup
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </Scrollbars>
      </Wrapper>
    </>
  );
};

export default Home;

const CardsWrapper = style.div`

margin-top: 20px;

.card--title_box {
display: flex;
justify-content: space-between;
  padding-left: 30px;
  padding-bottom: 10px;
  
  .cards--title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
}

.card--body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  

  .card--body__item {
    height: 130px;
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  
    h2 {
      margin: 0;
      font-size: 10px;
      font-weight: 600;
      text-align: center;
    }

    .card--body__item--body {
      margin-top: 10px;

      h1 {
        margin: 0;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
}
 .pub-link-container {
                      
                        display: flex;
                        align-items: center;
      gap: 10px;
                        


                        h2 {
                            margin: 0;
                            font-size: 12px;
                            letter-spacing: .5px;
                        }

                        .link-tab1 {
                            display: flex;
                            align-items: center;
                        }

                        .link-tab2 {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            gap: 10px;


                            &:hover {
                                

                                i {
                                    color: var(--mainColor);
                                }
                            }
                        }
                    }
  
`;
const Wrapper = style.div`


	height: 100%;

    .pub-wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;

        .pub-content {
            flex: 1;
            display: flex;
            justify-content: center;
            overflow: hidden !important;

            .pub-content--wrapper {
                display: flex;
                flex-direction: column;
                padding-top: 2%;
                width: 60%;

                .pub-content--wrapper--tab {
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-between;

                    .tab--div {
                        cursor: pointer;
                        padding-bottom: 15px;
                        flex: 1;
                        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                        // &:hover {
                        // border-bottom: 2px solid var(--mainColor);
                        //     h1 {
                        //         color: #002233;
                        //     }
                        // }
                        &:nth-child(2) {
                            display: flex;
                            justify-content: center;
                        }

                        &:nth-child(3) {
                            display: flex;
                            justify-content: flex-end;
                        }

                        h1 {
                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 1px;
                            color: #8692A6;
                            margin: 0;
                        }
                    }

                    .tab-div--active {
                        border-bottom: 2px solid var(--mainColor);
                        h1 {
                            color: #002233;
                        }
                    }
                }

                .pub-content--wrapper--content {
                    flex: 1;
                    .wrapper--content-1 {
                        margin-top: 25px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 2px dashed rgba(0, 0, 0, 0.07);
                        padding-bottom: 10px;
                        .wrapper--content-2 {
                            width: 35%;

                            h2 {
                                font-size: 12px;
                                margin: 0;
                                letter-spacing: .5px;
                            }
                        }
                        .wrapper--content-3 {
                            flex: 1;

                            input, select, textarea {
                                width: 100%;
                                padding: 10px;
                                font-weight: 400 !important;
                                font-size: 12px;
                                color: var(--appText);
                                letter-spacing: 1px;
                                line-height: 20px;
                                border-radius: 8px;
                                border: none;
                                background: var(--appInput);

                                &:focus {
                                    outline-color: var(--bgColor);
                                }
                            }
                            
                            .text-small {
                              font-size: 12px;
                              color: rgba(255,0,0,.8)
                            }
                        }
                        
                       
                    }
                }

                .last-container {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    padding: 20px 0;

                    .last-container1 {
                        display: flex;
                        justify-content: flex-end;

                        h2 {
                            font-size: 12px;
                            cursor: pointer;

                            &:hover {
                                color: var(--mainColor);
                            }
                        }
                    }

                    .last-container2 {
                        display: flex;
                        justify-content: center;

                        button {
                            margin-top: 20px;
                            background: var(--mainColor);
                            padding: 12px;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            width: 30%;
                            border: none !important;
                                outline: none !important;
                            h2 {
                                margin: 0;
                                font-size: 12px;
                                color: #fff;
                            }

                            &:focus {
                                border: none !important;
                                outline: none !important;
                            }
                        }
                    }
                }
            }
            
        }
    }
    .bg-container {
        margin-top: 30px;
        overflow: hidden !important;
        height: 25%;
    }    
`;
