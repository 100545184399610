import React, { useState, useEffect } from "react";
import "./index.css";
import Breadcrum from "../../components/shared/breadcrum";
import DashboardOverview from "../dashboard/overviewChart";
import ReachedBy from "../dashboard/reachedByDevice";
import BarChart from "../../components/shared/barChart";

const Analytics = (props) => {
  return (
    <div className="analytics">
      <Breadcrum title="Analytics" />
      <div className="analytics--card container">
        <div className="row">
          {[..."1234"].map((el, i) => {
            return (
              <div
                key={i}
                className="analytics--card_item col-lg-3 col-md-3 col-10 mx-auto"
              >
                <div className="analytics--card_item--child">
                  <h1>2,223,214</h1>
                  <div style={{ width: "100%" }}>
                    <h2>Total Impression</h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "60%" }}>
                        <div
                          style={{
                            padding: "0 5px",
                            background:
                              i == 1 || i == 2 ? "#FE9F99" : "#C3EE83",
                            display: "flex",
                            borderRadius: "10px",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flexGrow: 1 }}>
                            <img
                              src={
                                i == 1 || i == 2
                                  ? "../assets/down.png"
                                  : "../assets/up.png"
                              }
                              className="img-fluid"
                              width="20px"
                            />
                          </div>
                          <div style={{ flexGrow: 1, marginTop: "9px" }}>
                            <h3
                              style={{
                                fontSize: "14px",
                                fontFamily: "semiBoldM",
                              }}
                            >
                              +33
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="dashboard-layout mt-5" style={{ position: "relative" }}>
        <div className="dashboard-overview">
          <DashboardOverview />
        </div>

        {/* <div className="dashboard-right-sidebar">
                <h1>Reach by device</h1>
                <div className="reachedby-chart-wrap">
                    <ReachedBy />
                    <div className="reachedby-labels">
                        <div>
                            <div style={{ backgroundColor: "#F14A06" }} className="reached-by-solid-color"></div>
                            <div className="reachedby-label-name">Mobile Users</div>
                        </div>

                        <div>
                            <div style={{ backgroundColor: "#EE7F0D" }} className="reached-by-solid-color"></div>
                            <div className="reachedby-label-name">Desktop Users</div>
                        </div>
                    </div>


                </div>


            </div> */}
      </div>

      <div className="barChart-overview mt-3">
        <BarChart />
      </div>
    </div>
  );
};

export default Analytics;
