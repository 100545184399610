import React from 'react';
import { httpGet } from '../../components/helpers/httpMethods';

export const getPublicationTypes = async () => {
    let data = await httpGet(`publication_type`);
    if (data) {
        if (data.response) {
            return { er: data.response }
        }

        return data;
    }

}

export const createPublication = async () => {
    console.log("this creates publication");

}