import React, { useState, useEffect, useContext } from "react";
import { useRecoilState } from "recoil";
import { toggleSidebar } from "../../globalState/localData";
import AppLogo from "../../assets/images/appLogo.png";
import User from "../../assets/images/user.png";
import { getLocalItem } from "../../components/helpers/authService";
import Switch from "react-switch";
import { home, user, notif } from "../../assets/icons/svgs";
import "./layout.css";
import { AuthContext } from "../../context/AuthContext";
import style from "styled-components";

export default function Navbar({ browserRouter }) {
  let [sidebarState, setSidebarState] = useRecoilState(toggleSidebar);
  const [switche, setSwite] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const { dropdown, setDropdown } = useContext(AuthContext);

  useEffect(() => {
    companyDetails();
  }, []);

  const companyDetails = async () => {
    let company = await getLocalItem("company");
    if (company) {
      setCompanyData(JSON.parse(company));
    }
  };

  return (
    <div className="navbar-position">
      <div className="navbar-wrap">
        <div className="navbar-pad">
          <div className="app-logo-wrap-layout-page">{home}</div>

          <div className="flex-fill d-flex justify-content-center align-items-center">
            <div className="ml-5">
              <div className="company-name border">
                <h2>{companyData ? companyData.businessName : ""}</h2>
              </div>
            </div>
          </div>

          <div className="nav-actions">
            <div className="d-flex align-items-center nav-cog" onClick={() => browserRouter("/business_settings")}>
              <i class="fa fa-cog" style={{ color: "var(--mainColor)", fontSize: "20px", margin: 0 }} aria-hidden="true"></i>
            </div>

            <div
              className="ml-4"
              onClick={() => {
                setDropdown(!dropdown);
              }}
              style={{ cursor: "pointer" }}>
              <svg className="mr-2" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="19.5556" fill="white" stroke="#EE7F0D" stroke-width="0.888889" />
                <path d="M24.8887 22.7637L26.6803 24.5553V25.4303H12.4303V24.5553L14.222 22.7637V18.347C14.222 16.9581 14.5692 15.7498 15.2637 14.722C15.9859 13.6942 16.972 13.0276 18.222 12.722V12.097C18.222 11.7359 18.347 11.4303 18.597 11.1803C18.847 10.9026 19.1664 10.7637 19.5553 10.7637C19.9442 10.7637 20.2637 10.9026 20.5137 11.1803C20.7637 11.4303 20.8887 11.7359 20.8887 12.097V12.722C22.1387 13.0276 23.1109 13.6942 23.8053 14.722C24.5276 15.7498 24.8887 16.9581 24.8887 18.347V22.7637ZM19.5553 28.097C19.0553 28.097 18.6248 27.9303 18.2637 27.597C17.9303 27.2359 17.7637 26.8192 17.7637 26.347H21.347C21.347 26.8192 21.1664 27.2359 20.8053 27.597C20.4442 27.9303 20.0276 28.097 19.5553 28.097Z" fill="#4E4B66" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
