import React, { createContext, useState, useEffect } from 'react';
import { getLocalItem, getToken } from '../components/helpers/authService';

export const PublicationContext = createContext();

export const PublicationContextProvider = (props) => {
    const [pubReload, setPubReload] = useState('');
    const [reload, setReload] = useState(false);
    const [currentPublication, setCurrentPublication] = useState('');


    return <PublicationContext.Provider value={{
        pubReload,
        currentPublication,
        reload,
        setReload,
        setCurrentPublication,
        setPubReload,
    }}>
        {props.children}
    </PublicationContext.Provider>
}