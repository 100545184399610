import { baseUrl } from "../components/helpers/httpMethods";
import axios from "axios";

export let timoutReq = 60000;

export const LoginServices = async (data) => {
  return await axios({
    method: "POST",
    url: `${baseUrl}/api/auth/company_login`,
    data,
    timeout: timoutReq,
  });
};

export const SignUpServices = async (data) => {
  return await axios({
    method: "POST",
    url: `${baseUrl}/api/auth/company_login`,
    data,
    timeout: timoutReq,
  });
};
