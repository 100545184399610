import React, { useState, useEffect } from 'react';
import style from 'styled-components';
import { Container, SizedBox, H1, H3 } from '../../lib/main';
import AppColors from '../../helpers/_colors';
import arrRight from '../../assets/icons/arrRight.png'
import vd from '../../assets/images/vd.png';
import like from '../../assets/icons/like.png';
import comment from '../../assets/icons/comment.png';
import next from '../../assets/icons/next.png';
import dots from '../../assets/icons/dots.png'
import icon1 from '../../assets/icons/icon1.png'
import icon2 from '../../assets/icons/icon2.png'
import icon3 from '../../assets/icons/icon3.png'
import icon4 from '../../assets/icons/icon4.png'
import icon5 from '../../assets/icons/icon5.png';
import Breadcrum from '../../components/shared/breadcrum';
import ReactPlayer from 'react-player'
import './index.css'

export default function Academy() {
    const [progress, setProgress] = useState([
        { title: 'Your First Publication', lesson: 'Lesson 4', icon: icon1 },
        { title: 'Make More Sales', lesson: 'Lesson 4', icon: icon2 },
        { title: 'Statistics', lesson: 'Lesson 4', icon: icon3 },
        { title: 'Payouts', lesson: 'Lesson 4', icon: icon4 },
        { title: 'Change of Name', lesson: 'Lesson 4', icon: icon5 }
    ])
    return (
        <AcademyWrapper className="hide-scrollbar" id="academy">
            <Container background={`${AppColors.bgColorLight}`} position="relative">
                <Breadcrum title="Course" />
                <SizedBox height="4vh" />
                <Container className=" container" height="100%">
                    <Container className="row">
                        <Container className="col-xl-8 col-lg-8 col-md-8 col-10 py-5">
                            <ReactPlayer url='https://www.youtube.com/watch?v=_5EIzNwpNUQ' />
                            {/* <img src={vd} className="" style={{ display: 'block', borderRadius: '40px', width: '100%', }} /> */}
                            <SizedBox height="4vh" />
                            <H1 fontWeight="bold">Guide To Being An Effective Vendor </H1>
                            <SizedBox height="1vh" />
                            <H3 fontWeight="500" fontFamily="generalFont" color="var(--appGrey)" fontSize="13px">In this lesson, you use navigation controllers and segues to create the navigation flow
of the FoodTracker app. At the end of the lesson, you’ll have a complete navigation scheme and interaction flow for the app. See more...</H3>
                            <Container display="flex" justifyContent="flex-end">
                                <Container display="flex">
                                    <Container height='5vh' width="6vw" display="flex" alignItems="center">
                                        <img src={like} className="img-fluid" width="20%" />
                                        <SizedBox width="12px" />
                                        <Container className="mt-2">
                                            <H3 fontWeight="bold" fontSize="12px">100</H3>
                                        </Container>
                                    </Container>
                                    <Container height='5vh' width="6vw" display="flex" alignItems="center">
                                        <img src={comment} className="img-fluid" width="20%" />
                                        <SizedBox width="12px" />
                                        <Container className="mt-2">
                                            <H3 fontWeight="bold" fontSize="12px">4</H3>
                                        </Container>
                                    </Container>
                                    <Container height='5vh' width="6vw" display="flex" alignItems="center">
                                        <img src={next} className="img-fluid" width="20%" />
                                        <SizedBox width="12px" />
                                        <Container className="mt-2">
                                            <H3 fontWeight="bold" fontSize="12px">Next</H3>
                                        </Container>
                                    </Container>

                                </Container>
                            </Container>
                        </Container>
                        <Container className="col-xl-3 col-lg-3 col-md-3 col-10 " width={'20vw'} right="7vw" position="fixed">
                            <Container>
                                <Container display="flex" justifyContent="space-between" alignItems="center">
                                    <H1 fontWeight="bold" fontSize="1vw">Lessons</H1>
                                    <img src={dots} className="img-fluid" width="10%" />
                                </Container>
                                <SizedBox height="1vh" />
                                <Container className="container">
                                    {progress.map((el, i) => {
                                        return <Container className="row mb-2">
                                            <Container className="" width="15%">
                                                <img src={el.icon} className="img-fluid" width="100%" />
                                            </Container>
                                            <SizedBox width="3%" />
                                            <Container width="78%">
                                                <H1 fontSize="14px" fontFamily="boldFontM" fontWeight="500">{el.title}</H1>
                                                <H3 fontSize="14px" color={AppColors.solidGrey}>{el.lesson}</H3>
                                            </Container>
                                        </Container>
                                    })}
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </AcademyWrapper >
    )
}




const AcademyWrapper = style.div`
    overflow-y: scroll;
    #academy::-webkit-scrollbar{
        opacity:0;
    }
   
`;