import React, { useState, useEffect, useContext } from "react";
import icons from "./icons";
import MaterialTable, { Column } from "material-table";
import { Paper } from "@material-ui/core";
import { getAllPubEditions } from "../../controllers/publication/getEditions";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { PublicationContext } from "../../context/PublicationContext";
import "./table.css";
import swal from "sweetalert";
import { Modal } from "react-responsive-modal";
import { Document, Page } from "react-pdf";
import { axiosCalls } from "../helpers/httpMethods";

export default function DataTable(props) {
  const { reload } = useContext(PublicationContext);

  const [popOver, setPopOver] = useState([]);
  const [show, setShow] = useState(false);

  const [editionData, setEditionData] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfLoader, setPdfLoader] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getEditions();
  }, [reload]);

  const getEditions = async () => {
    setEditionData([]);
    let data = await getAllPubEditions();
    if (data) {
      if (data.er) {
        return;
        // return NotificationManager.info("No Edition found for this publication");
      }
    }
    setEditionData(data.editions);
  };

  const reqDelete = (id) => {
    swal(
      "TheNewspaperStand",
      `Are you sure you want to delete this edition?`,
      "warning",
      {
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
        closeOnClickOutside: true,
      }
    ).then((val) => {
      if (!val) {
        return;
      }
      deleteEdition(id);
    });
  };

  const deleteEdition = async (id) => {
    let res = await axiosCalls(`/delete_edition/${id}`, "DELETE");
    if (res) {
      if (res.er) {
        if (res.er.message === "Validation Error!") {
          return swal("Error!", Object.values(res.er.data)[0], "error");
        }
        return swal("Error!", res.er.message, "error");
      }
      swal("Deleted!", res.message, "success");
      let filtered = [...editionData].filter((el) => el.id != id);
      setEditionData(filtered);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          // { title: "", field: "Check" },
          { title: "ID", field: "ID" },
          { title: "Editions", field: "Editions" },
          // { title: 'Link', field: 'Link' },
          // { title: "Read", field: "Read" },
          { title: "Date", field: "Date" },
          { title: "Action", field: "Action" },
        ]}
        data={editionData
          .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          .map((data) => {
            return {
              ID: (
                <div>
                  <div className="">{data.editionId}</div>
                </div>
              ),
              Editions: data.editionName,
              Types: data.Types,
              // Link: (
              //   <div
              //     className="edition-link"
              //     onClick={() =>
              //       props.nav.push(`/publications/edition/${data.id}`)
              //     }
              //   >
              //     {data.pdfPath}
              //   </div>
              // ),
              Read: data.count,
              Date: (
                <div className="edition-status-tab">
                  <h2
                    style={
                      data.status.toLowerCase() === "active" &&
                      moment() >= moment(data.publishedDate)
                        ? { color: "green" }
                        : { color: "red" }
                    }
                  >
                    {data.status.toLowerCase() === "active" &&
                    moment() >= moment(data.publishedDate)
                      ? "Published"
                      : moment() < moment(data.publishedDate)
                      ? "Scheduled"
                      : "Draft"}
                  </h2>
                  {data.status.toLowerCase() !== "pending" &&
                    moment(data.publishedDate).fromNow()}
                </div>
              ),
              Action: (
                <div className="optionsTab">
                  <h2
                    onClick={() => {
                      if (data.type === "digital") {
                        return props.nav.push(
                          `/publications/articles/${data.id}`
                        );
                      }
                      props.nav.push(`/publications/edition/${data.id}`);
                    }}
                  >
                    Edit
                  </h2>
                  <h2 className="mx-1">|</h2>
                  <h2
                    onClick={() => {
                      console.log(data);
                      reqDelete(data.id);
                    }}
                  >
                    Trash
                  </h2>
                </div>
              ),
              // Check: (
              //   <div className="publicationCheckbox">
              //     <input id="c1" type="checkbox" />
              //   </div>
              // ),
            };
          })}
        title=""
      />

      <div className="rs-modal-wrap ">
        <Modal
          open={pdfLoader}
          onClose={() => setPdfLoader(false)}
          center
          animationDuration={400}
          closeOnOverlayClick={true}
          focusTrapped={true}
        >
          <div className="modal-container">
            <div className="control-section">
              <div className="control-section-col1"></div>
              <div className="control-section-col2 ">
                {/* <i class="fa fa-angle-left" onClick={() => getPage('previous')} aria-hidden="true"></i> */}
                {/* <h2 className="mx-3" style={{ margin: 0, color: '#fff', fontSize: '15px' }}>{pageNumber}</h2> */}
                {/* <i class="fa fa-angle-right " onClick={() => getPage('next')} aria-hidden="true"></i> */}
              </div>
              <div className="control-section-col3 ">
                {/* <button onClick={() => uploadPdf()} >Next</button> */}
              </div>

              {/* <button onClick={() => getPage('previous')}>previous</button>
                                <button className="ml-2" onClick={() => getPage('next')}>Next</button> */}
            </div>
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </div>
        </Modal>
      </div>
    </div>
  );
}
