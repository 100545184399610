

import React from "react";
import { Bar as LineChart } from "react-chartjs-2";

function chartData() {
    return {
        labels: ["Monday", "Tuesday", "Wednesday", "Friday", "Saturday", "Sunday"],


        datasets: [

            {
                label: "Revenue",
                data: [70, 59, 80, 60, 56, 80, 100],
                fill: true,
                backgroundColor: "#FEF3E9",
                borderColor: "#FDAD00",
                pointStyle: "rectRounded",
            }
        ],
    };
}

const options = {
    responsive: true,
    maintainAspectRatio: false
};

class OverviewChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: chartData(),
        };
    }

    render() {
        return (
            <div className="chart-canvas">
                {" "}
                <LineChart options={options} data={this.state.data} />
            </div>
        );
    }
}

export default OverviewChart;
