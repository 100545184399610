import React, { createContext, useState, useEffect } from 'react';
import { getLocalItem, getToken } from '../components/helpers/authService';

export const DashboardContext = createContext();

export const DashboardContextProvider = (props) => {
    const [renderOpt, setRenderOpt] = useState('normal');


    return <DashboardContext.Provider value={{
        renderOpt,
        setRenderOpt,
    }}>
        {props.children}
    </DashboardContext.Provider>
}