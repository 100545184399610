import { baseUrl } from "../helpers/httpMethods";
import { getToken } from "../helpers/authService";
import axios from "axios";
class MyUploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file.then((uploadedFile) => {
            return new Promise(async(resolve, reject) => {
                const data = new FormData();
                data.append("file", uploadedFile);
                let userToken = await getToken();
                console.log("df", userToken);

                axios({
                        url: `${baseUrl}/api/auth/profile_image_upload`,
                        method: "POST",
                        data,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${userToken}`,
                        },
                        withCredentials: false,
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data.code == 201) {
                            resolve({
                                default: response.data.data.imagePath,
                            });
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch((response) => {
                        console.log("the err res", response);
                        reject("Upload failed");
                    });
            });
        });
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());

        // Note that your request may look different. It is up to you and your editor
        // integration to choose the right communication channel. This example uses
        // a POST request with JSON as a data structure but your configuration
        // could be different.
        // xhr.open( 'POST', 'https://api.cloudinary.com/v1_1/lms-center/upload', true );
        xhr.open("POST", `${baseUrl}/api/auth/profile_image_upload`, true);
        xhr.responseType = "json";
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
            const response = xhr.response;

            // This example assumes the XHR server's "response" object will come with
            // an "error" which has its own "message" that can be passed to reject()
            // in the upload promise.
            //
            // Your integration may handle upload errors in a different way so make sure
            // it is done properly. The reject() function must be called when the upload fails.
            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            // This URL will be used to display the image in the content. Learn more in the
            // UploadAdapter#upload documentation.
            //console.log(response.url);
            resolve({
                default: response.url,
            });
        });

        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if (xhr.upload) {
            xhr.upload.addEventListener("progress", (evt) => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    _sendRequest(file) {
        // Prepare the form data.
        const data = new FormData();

        const cloudinaryUploadPreset = "qzkwxm8f";
        data.append("file", file);
        // data.append('upload_preset', cloudinaryUploadPreset);

        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.

        // Send the request.
        // this.xhr.send( data );
    }
}

// ...

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter(loader);
    };
}

export default MyCustomUploadAdapterPlugin;