import React from 'react'
import Breadcrum from '../../components/shared/breadcrum';
import CommentsTable from '../../components/tables/comments'
export default function Comments() {
    return (
        <React.Fragment>
            <Breadcrum title="Comments" />
            <h1>COMING SOON</h1>
            {/* <CommentsTable /> */}


        </React.Fragment>
    )
}
