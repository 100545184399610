import React, { useEffect, useState, useContext } from "react";
import Breadcrum from "../../components/shared/breadcrum";
import "./settings.css";
import { Button, Form, FormGroup, Label, Input, FormText, Spinner } from "reactstrap";
import { Table } from "reactstrap";
import Iframe from "react-iframe";
import { PublicationContext } from "../../context/PublicationContext";
import { httpGet, axiosCalls } from "../../components/helpers/httpMethods";
import { NotificationManager } from "react-notifications";
import { getLocalItem, setLocalItem } from "../../components/helpers/authService";
import { SettingsContext } from "../../context/SettingsContext";
import swal from "sweetalert";
import style from "styled-components";
import bg2 from "../../assets/images/bgPub.jpg";
import { Modal } from "react-responsive-modal";

import Switch from "react-switch";
import { SettingsCellRounded } from "@material-ui/icons";

export default function Settings(props) {
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const { activeState2 } = useContext(SettingsContext);
  const [pub, setPub] = useState({});
  const [company, setCompany] = useState({});

  const [companyName, setCompanyName] = useState("");
  const [companyAcctName, setCompanyAcctName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companySupportEmail, setCompanySupportEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  const [companyAcctNumber, setCompanyAcctNumber] = useState("");
  const [companyBankName, setCompanyBankName] = useState("");
  // const []

  const [changeOfName, setChangeOfName] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [archive, setArchive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const [oneTime, setOneTime] = useState(0);
  const [weekly, setWeekly] = useState(0);
  const [monthly, setMonthly] = useState(0);
  const [yearly, setYearly] = useState(0);

  const [oneTimed, setOneTimed] = useState(0);
  const [weeklyd, setWeeklyd] = useState(0);
  const [monthlyd, setMonthlyd] = useState(0);
  const [yearlyd, setYearlyd] = useState(0);

  const [currency, setCurrency] = useState("NGN");
  const [roles, setRoles] = useState([]);
  const [rolesModal, setRolesModal] = useState(false);
  const [rolesModalEdit, setRolesModalEdit] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [selectedRoleId, setSelectedId] = useState("");
  const [editId, setEditId] = useState("");
  const [allStaff, setAllStaff] = useState([]);

  useEffect(() => {
    getAllTeam();
    getRoles();
    getSinglePub();
  }, [reload]);

  const getSinglePub = async () => {
    setOneTime(0);
    setOneTimed(0);
    setWeekly(0);
    setWeeklyd(0);
    setMonthly(0);
    setMonthlyd(0);
    setYearly(0);
    setYearlyd(0);
    let companyData = await getLocalItem("company");
    let userData = await getLocalItem("user");
    console.log("triggered2", JSON.parse(companyData));
    setCompanyEmail(JSON.parse(userData).email);
    setCompanyAddress(JSON.parse(companyData).address);
    setCompanyName(JSON.parse(companyData).businessName);
    setCompanyPhone(JSON.parse(companyData).phone);
    setCompanyAcctNumber(JSON.parse(companyData).accountNumber);
    setCompanyAcctName(JSON.parse(companyData).accountName);
    setCompanyBankName(JSON.parse(companyData).bankName);
    setCompany(JSON.parse(companyData));
    if (id) {
      let res = await httpGet(`/publication/${id}`);
      if (res) {
        if (!res.success) {
          return NotificationManager.error("Errror");
        }
        setPub(res.data.publication);
        console.log("sindkfsndfnsdfs", res.data.publication);

        setTwoFactorAuth(res.data.publication.isPaid);

        if (res.data.publication.oneOffFee) {
          setOneTime(res.data.publication.oneOffFee);
        }
        if (res.data.publication.oneOffDollarFee) {
          setOneTimed(res.data.publication.oneOffDollarFee);
        }
        if (res.data.publication.weeklyFee) {
          setWeekly(res.data.publication.weeklyFee);
        }
        if (res.data.publication.weeklyDollarFee) {
          setWeeklyd(res.data.publication.weeklyDollarFee);
        }
        if (res.data.publication.monthlyFee) {
          setMonthly(res.data.publication.monthlyFee);
        }
        if (res.data.publication.monthlyDollarFee) {
          setMonthlyd(res.data.publication.monthlyDollarFee);
        }
        if (res.data.publication.annualFee) {
          setYearly(res.data.publication.annualFee);
        }
        if (res.data.publication.annualDollarFee) {
          setYearlyd(res.data.publication.annualDollarFee);
        }
      }
    }
  };

  const editBusiness = async () => {
    let data = {
      address: companyAddress,
      phone: companyPhone,
      supportEmail: companySupportEmail,
    };
    let res = await axiosCalls(`/auth/edit_company`, "PATCH", data);
    if (res) {
      if (res.er) {
        console.log(res.er.message);
        return NotificationManager.error(res.er.message);
      }
      console.log(res);
      let companyData = await getLocalItem("company");
      let newCompany = {
        ...JSON.parse(companyData),
        supportEmail: companySupportEmail,
        phone: companyPhone,
        address: companyAddress,
      };
      await setLocalItem("company", newCompany);
      swal("TheNewsPaparStand", "Successfully Updated", "success");
    }
  };

  const getRoles = async () => {
    let res = await axiosCalls(`/company_staff_roles`);
    if (res) {
      if (res.er) {
        return;
      }

      setRoles(res.data.companyRoles);
    }
  };

  const getAllTeam = async () => {
    let res = await axiosCalls(`/auth/all_company_staffs`);
    if (res) {
      if (res.er) {
        console.log("====================================");
        console.log(res.er.message);
        console.log("====================================");
        return;
      }

      console.log("====================================");
      console.log("all staff", res.data);
      setAllStaff(res.data.staffs);
      console.log("====================================");
    }
  };

  const inviteUser = async () => {
    console.log("====================================");
    console.log(selectedRoleId);
    console.log("====================================");

    if (firstname === "" || lastname === "" || email === "" || selectedRoleId === "") {
      return NotificationManager.info("All fields are required");
    }

    let res = await axiosCalls(`/auth/create_staff`, "POST", {
      firstName: firstname,
      lastName: lastname,
      email,
      roleId: selectedRoleId,
    });
    if (res) {
      if (res.er) {
        console.log("====================================");
        console.log(res.er);
        if (res.er.data) {
          NotificationManager.error(Object.values(res.er.data).join("   "));
          return;
        }
        NotificationManager.error(res.er.message);
        console.log("====================================");
        return;
      }
      getAllTeam();
      swal("Invite sent!", `The invite has been successfully sent to ${email}`, "success").then(() => {
        setRolesModal(false);
        setFirstname("");
        setLastname("");
        setEmail("");
        setSelectedId("");
      });
    }
  };

  const editUser = async () => {
    if (firstname === "" || lastname === "" || email === "" || selectedRoleId === "") {
      return NotificationManager.info("All fields are required");
    }

    let res = await axiosCalls(`/auth/vendor_edit_profile/${editId}`, "PATCH", {
      firstName: firstname,
      lastName: lastname,
      email,
      roleId: selectedRoleId,
    });
    if (res) {
      if (res.er) {
        console.log("====================================");
        console.log(res.er);
        if (res.er.data) {
          NotificationManager.error(Object.values(res.er.data).join("   "));
          return;
        }
        NotificationManager.error(res.er.message);
        console.log("====================================");
        return;
      }
      getAllTeam();
      swal("Thenewspaperstand", `User successfully updated`, "success").then(() => {
        setRolesModalEdit(false);
        setFirstname("");
        setLastname("");
        setEmail("");
        setEditId("");
        setSelectedId("");
      });
    }
  };

  const reqDelete = (id, status) => {
    swal("TheNewspaperStand", `Are you sure you want to ${status.toLowerCase() === "active" ? "deactivate" : "activate"} this user?`, "warning", {
      buttons: ["Cancel", `${status.toLowerCase() === "active" ? "Deactivate" : "Activate"}`],
      dangerMode: true,
      closeOnClickOutside: true,
    }).then((val) => {
      if (!val) {
        return;
      }
      deleteEdition(id, status);
    });
  };

  const deleteEdition = async (id, dstatus) => {
    let res = await axiosCalls(`/auth/delete_user/${id}`, "DELETE", {
      status: dstatus.toLowerCase() === "active" ? "inactive" : "active",
    });
    if (res) {
      if (res.er) {
        if (res.er.message === "Validation Error!") {
          return swal("Error!", Object.values(res.er.data)[0], "error");
        }
        return swal("Error!", res.er.message, "error");
      }
      swal("Thenewspaperstand!", `successfully ${dstatus.toLowerCase() === "active" ? "Deactivated" : "Activated"}`, "success");
      getAllTeam();
    }
  };

  return (
    <SettingsWrapper className="settings">
      <Breadcrum nav={props.history} general={true} />

      {activeState2 === "details" && (
        <div className="settings-block2">
          <div className="bg-container">
            <img src={bg2} alt="pub image" className="img-fluid" />
          </div>
          <div className="input-wrapper">
            <div className="input-wrapper-container">
              <div className="input-wrapper-item1">
                {/* <h1 className="input-wrapper-title">Business details</h1> */}
                <div className="mt-3 mb-4">
                  <input value={companyName} disabled={true} type="text" placeholder="Business Name" />
                </div>

                <div className="mb-4">
                  <input type="email" value={companyEmail} name="emailAddress" id="emailAddress" placeholder="Email address" />
                </div>

                <div className="mb-4">
                  <input value={companySupportEmail} onChange={(e) => setCompanySupportEmail(e.target.value)} type="email" placeholder="Support Email" />
                </div>
              </div>

              <div className="input-wrapper-item1">
                {/* <h1 className="input-wrapper-title">Bank details</h1> */}
                <div className="mt-3 mb-4">
                  <input type="phone" value={companyPhone} onChange={(e) => setCompanyPhone(e.target.value)} placeholder="Phone number" />
                </div>

                <div className="mb-4">
                  <input type="text" value={company ? company.country : ""} placeholder="Country" disabled={true} />
                </div>

                <div className="mb-4">
                  <input type="text" value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)} name="companyAddress" placeholder="Business address" />
                </div>
              </div>
            </div>
          </div>
          <div className="btn-btn">
            <button onClick={() => editBusiness()}>
              <h2>Save</h2>
            </button>
          </div>
        </div>
      )}

      {activeState2 === "payout" && (
        <div className="mt-4 p-5" style={{ border: "1px solid var(--bgColor)", padding: "4px" }}>
          <div className="roles">
            <div className="role--title mb-5 mt-2">
              <h2 style={{ margin: 0 }}>Payout Account</h2>
            </div>
          </div>

          <Table hover responsive borderless>
            <thead>
              <tr>
                <th>Currency</th>
                <th>Bank</th>
                <th>Account Details</th>
                <th>Verification</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>NGN</td>
                <td>{companyBankName}</td>
                <td>
                  {companyAcctNumber} ({companyAcctName})
                </td>
                <td>Verified</td>
                <td>Default</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {activeState2 === "roles" && (
        <div className="mt-4 p-5" style={{ border: "1px solid var(--bgColor)", padding: "4px" }}>
          <div className="roles">
            <div className="role--title">
              <h2 style={{ margin: 0 }}>Team</h2>
            </div>

            <div className="team-box">
              <button onClick={() => setRolesModal(true)}>
                <h2>+</h2>
                <h2>Team</h2>
              </button>
            </div>
          </div>

          <Table hover responsive borderless>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Role</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allStaff.map((el, i) => {
                console.log("====================================");
                console.log(el);
                console.log("====================================");
                return (
                  <tr>
                    <td>{el.firstName && el.lastName ? `${el.firstName} ${el.lastName}` : companyName}</td>
                    <td>{el.email}</td>
                    <td style={{ textTransform: "capitalize" }}>{el.role.name}</td>
                    <td>{el.role.description}</td>
                    <td>
                      {el.firstName && el.lastName && (
                        <div className="cell-btn-box">
                          <button
                            onClick={() => {
                              setEditId(el.id);
                              setFirstname(el.firstName);
                              setLastname(el.lastName);
                              setEmail(el.email);
                              setSelectedId(el.roleId);
                              setRolesModalEdit(true);
                            }}>
                            <h2>Edit</h2>
                          </button>
                          <button className="del-btn" onClick={() => reqDelete(el.id, el.status)}>
                            <h2>{el.status.toLowerCase() === "active" ? "Deactivate" : "Activate"}</h2>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      {rolesModal && (
        <div>
          <Modal
            open={rolesModal}
            onClose={() => setRolesModal(false)}
            center
            animationDuration={400}
            closeOnOverlayClick={true}
            focusTrapped={true}
            showCloseIcon={true}
            styles={{
              overlay: {
                background: "#00000001",
              },
            }}>
            <div className="modal-body-rs p-3">
              <ModalWrapper>
                <div className="roles-container">
                  <div className="title--box">
                    <h2>Invite User</h2>
                  </div>

                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Firstname</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <input value={firstname} type="text" onChange={(e) => setFirstname(e.target.value)} />
                    </div>
                  </div>
                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Lastname</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <input value={lastname} type="text" onChange={(e) => setLastname(e.target.value)} />
                    </div>
                  </div>
                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Email</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>

                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Choose your role</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <select value={selectedRoleId} onChange={(e) => setSelectedId(e.target.value)}>
                        <option value="">Select role</option>
                        {roles.map((el, i) => {
                          return (
                            <option value={el.id} key={i}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="btns-box">
                    <div className="btn-gutter"></div>
                    <div className="btns-box__container">
                      <button onClick={() => setRolesModal(false)}>
                        <h2>Cancel</h2>
                      </button>
                      <button className="cta-btn" onClick={inviteUser}>
                        <h2>Invite User</h2>
                      </button>
                    </div>
                  </div>
                </div>
              </ModalWrapper>
            </div>
          </Modal>
        </div>
      )}

      {rolesModalEdit && (
        <div>
          <Modal
            open={rolesModalEdit}
            onClose={() => setRolesModalEdit(false)}
            center
            animationDuration={400}
            closeOnOverlayClick={true}
            focusTrapped={true}
            showCloseIcon={true}
            styles={{
              overlay: {
                background: "#00000001",
              },
            }}>
            <div className="modal-body-rs p-3">
              <ModalWrapper>
                <div className="roles-container">
                  <div className="title--box">
                    <h2>Edit User</h2>
                  </div>

                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Firstname</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <input value={firstname} type="text" onChange={(e) => setFirstname(e.target.value)} />
                    </div>
                  </div>
                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Lastname</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <input value={lastname} type="text" onChange={(e) => setLastname(e.target.value)} />
                    </div>
                  </div>
                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Email</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <input value={email} disabled={true} type="email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>

                  <div className="roles-container-item">
                    <div className="roles-container-item__title">
                      <h2>Choose your role</h2>
                    </div>

                    <div className="roles-container-item__input">
                      <select value={selectedRoleId} onChange={(e) => setSelectedId(e.target.value)}>
                        <option value="">Select role</option>
                        {roles.map((el, i) => {
                          return (
                            <option value={el.id} key={i}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="btns-box">
                    <div className="btn-gutter"></div>
                    <div className="btns-box__container">
                      <button onClick={() => setRolesModalEdit(false)}>
                        <h2>Cancel</h2>
                      </button>
                      <button className="cta-btn" onClick={editUser}>
                        <h2>Edit User</h2>
                      </button>
                    </div>
                  </div>
                </div>
              </ModalWrapper>
            </div>
          </Modal>
        </div>
      )}
    </SettingsWrapper>
  );
}

const SettingsWrapper = style.div`
    // border: 1px solid red;

    .team-box {
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--mainColor);
        border-radius: 5px;
        border: none;
        padding: 10px 15px;

        h2 {
          margin: 0;
          color: #fff;
        }
      }
    }

    Table {
      td {
        .cell-btn-box {
          flex: 1;
          display: flex;
          justify-content: space-between;

          button {
            background: #228C22;
            border: none;
            padding: 10px 15px;
            border-radius: 5px;

            h2 {
              margin: 0;
              color: #fff;
            }
          }

          .del-btn {
            background: var(--mainColor);
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .settings-block2 {
        // background: linear-gradient(to bottom, var(--bgColor),  #fff);
        height: 100%;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center !important;

        .input-wrapper {

            // border: 1px solid red;
            width: 100%;
            display: flex;
            justify-content: center;

            .input-wrapper-container {
                // border: 1px solid red;
                width: 75%;
                display: flex;
            }
        }
    }

    .btn-btn {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        button {
            background: var(--mainColor);
            border: none;
            padding: 10px 20px;
            border-radius: 5px;

            h2 {
                margin: 0;
                color: #fff;
            }
        }
    }
`;

const ModalWrapper = style.div`
    width: 100%;
    height: 100%;
    display: flex;
    .title--box {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      h2{
        margin: 0;
      }
    }

    .btns-box {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .btn-gutter {
        width: 50%;
      }

      .btns-box__container {
        flex: 1;
        display: flex;
        justify-content: space-between;

        .cta-btn {
          background: var(--appText);
          color: #fff;
        }
        button {
          width: 40%;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 5px;
          border: none !important;
          // outline: none !important;

          &:focus {
            // border: none !important;
            // outline: none !important;
          }
          h2 {
            font-size: 12px;
            margin: 0;
          }
        }
      }
    }

    .roles-container {
        flex: 1;
        display: flex;
        flex-direction: column;

        .roles-container-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .roles-container-item__title {
                flex: 1;
                h2 {
                    font-size: 12px;
                    margin: 0;
                }
            }

            .roles-container-item__input {
                width: 65%;
                display: flex;
                input {
                    flex: 1;
                    padding: 10px;
                    font-family: 'Poppins', sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 12px;
                    color: var(--appText);
                    letter-spacing: 1px;
                    line-height: 20px;
                    border-radius: 8px;
                    border: none;
                    background: var(--appInput);

                    &:focus {
                        outline-color: var(--mainColor);
                    }
                }

                select {
                    flex: 1;
                    padding: 13px 10px;
                    border-radius: 8px;
                    border: none;
                    background: var(--appInput);
                    font-family: 'Poppins', sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 12px;
                    color: var(--appText2);
                    letter-spacing: 1px;
                    line-height: 20px;
                    text-transform: capitalize;
                    

                    &:focus {
                        outline-color: var(--mainColor);
                    }
                }
            }
        }


    }
`;
