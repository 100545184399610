

import React from "react";
import { Pie as LineChart } from "react-chartjs-2";

function chartData() {
	return {
        // labels: ["Desktop" , "Mobile views"],
        
        
		datasets: [
            {
                data: [133.3, 86.2],
                backgroundColor: [
                    "#EE7F0D",
                    "#F14A06",
                    
                ]
            }
		],
	};
}

const options = {
    responsive: true,
    maintainAspectRatio: false
};

class OverviewChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: chartData(),
		};
	}

	render() {
		return (
			<div className="chart-canvas">
				{" "}
				<LineChart options={options}  data={this.state.data} />
			</div>
		);
	}
}

export default OverviewChart;
