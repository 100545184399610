import React from 'react'
import Breadcrum from '../../components/shared/breadcrum';
import SubscribersTable from '../../components/tables/subscribers'
export default function Subscribers(props) {
    return (
        <React.Fragment>
            <Breadcrum title="Subscribers" hide="dhh" nav={props.history} />
            <SubscribersTable />
        </React.Fragment>
    )
}
