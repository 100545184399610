import React from 'react'
import Breadcrum from '../../components/shared/breadcrum';
import ReviewTable from '../../components/tables/Reviews'
export default function Subscribers() {
    return (
        <React.Fragment>
            <Breadcrum title="Reviews" />
            <h1>COMING SOON</h1>
            {/* <ReviewTable /> */}


        </React.Fragment>
    )
}
