import React, { Component, useState } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./draggable.css";
import arrayMove from "./arrayMove";
import truncateWithEllipses from "../../../helpers/truncate";
import moment from "moment";

//Drag handler
const DragHandle = sortableHandle(() => (
  <span className="dragHandler">
    <p>Sort Data</p>
  </span>
));

//Draggable elements
const SortableItem = sortableElement(
  ({ value, i, history, handleDeleteArticle }) => (
    <tr className="dragElement">
      <td>
        <div className="td-container">
          <p>{Number(i) + 1}</p>
        </div>
      </td>
      <td>
        <div className="td-container">
          <p>{value.title}</p>
        </div>
      </td>
      <td>
        <div className="td-container">
          <p>{truncateWithEllipses(value.byLine, 30)}</p>
        </div>
      </td>
      {/* <td>
        <div className="td-container">
          <div>{truncateWithEllipses(value.body, 30)}</div>
        </div>
      </td> */}
      <td>
        <div className="td-container">
          <p>{moment(value.createdAt).format("DD/MM/yyy")}</p>
        </div>
      </td>

      <td>
        <div className="td-container">
          {/* <p>{value.status}</p> */}
          <p>Completed</p>
        </div>
      </td>
      <td>
        <div
          className="image-container--content__btn-list"
          onClick={() => {
            console.log("clicked");
            history.push(`/publications/articles/edit/${value.id}`);
          }}
          style={{
            cursor: "pointer",
            fontSize: "14px",
            paddingleft: "20px",
            color: "orange ",
            // opacity: "0",
          }}
        >
          edit
        </div>
      </td>

      <td
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => handleDeleteArticle(value.id)}
      >
        Delete
      </td>
      <td>
        <div className="td-container">
          <DragHandle />
        </div>
      </td>
    </tr>
  )

  // <div className="dragElement">
  //   {value}
  //   <DragHandle />
  // </div>
);

//Drag area
const SortableContainer = sortableContainer(({ children }) => {
  return (
    <tbody className="watch-table__body-row dragContainer">{children}</tbody>
  );
});

export default function SortableItems({
  article,
  history,
  setSortArticles,
  setSortState,
  handleDeleteArticle,
}) {
  const [items, setItems] = useState({ items: article });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    setSortArticles(items);
    setSortState(false);
  };
  return (
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      {items.items.map((data, index) => (
        <SortableItem
          key={`item-${index}`}
          i={index}
          index={index}
          value={data}
          history={history}
          helperClass="sortableHelper"
          handleDeleteArticle={handleDeleteArticle}
        />
      ))}
    </SortableContainer>
  );
}

// class SortableItems extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       items: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"],
//     };
//   }

//   render() {
//     const { items } = this.state;

//     return (

//     );
//   }
// }

// export default SortableItems;
