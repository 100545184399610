import React, { useState, useEffect, useContext } from 'react';
import { PublicationContext } from '../../context/PublicationContext';
import icons from './icons';
import MaterialTable, { Column } from 'material-table';
import { Paper } from '@material-ui/core';
import Avatar from './avatar.svg';
import { axiosCalls } from '../helpers/httpMethods';
import moment from 'moment';
import './table.css';
export default function DataTable(props) {
  const { currentPublication: id, reload } = useContext(PublicationContext);
  const [popOver, setPopOver] = useState([]);
  const [show, setShow] = useState(false);
  const [dData, setData] = useState([]);

  useEffect(() => {
    getSubcribers();
  }, [reload]);

  const getSubcribers = async () => {
    let res = await axiosCalls(`/subscribers`, 'GET', null, 'load');
    if (res) {
      if (res.er) {
        console.log('====================================');
        console.log('there is an error', res.er.message);
        console.log('====================================');
        return;
      }

      console.log('====================================');
      console.log('gfhfh', res.data);
      setData(res.data.subscribers);
      console.log('====================================');
    }
  };

  const handlePopOver = (id) => {
    console.log('CLicking>>>');
    setShow(!show);
    if (show) {
      setPopOver([id]);
    }
  };

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: 'User Image', field: 'UserImg' },
          { title: 'Full Name', field: 'userName' },
          { title: 'Payment Date', field: 'paymentDate' },
          { title: ' Subscription', field: 'Subscription' },
          { title: ' Status', field: 'status' },
        ]}
        data={dData.map((data) => {
          return {
            UserImg: (
              <img className="table-avatar" src={data.user.avatar || Avatar} />
            ),
            userName: `${data.user.firstName} ${data.user.lastName}`,
            paymentDate: moment(data.paymentDate).format('DD/MM/yy'),
            Subscription: data.subType,
            status: <a className="pending-action-table-btn">{data.status}</a>,
          };
        })}
        title="Subscribers"
      />
    </div>
  );
}
