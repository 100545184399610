let error = "";
export const ValidateInput = (expression) => {
  switch (true) {
    case expression === "":
      error = "Opps, input can't be blank";
      return error;

    case expression.length < 2 || expression.length > 20:
      error = "Value must not be longer than 20 digits or shorter than 3 digits";
      return error;

    default:
      return (error = "Valid");
  }
};

export const ValidateEmail = (expression) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(expression)) {
    return (error = "Valid");
  }
  return (error = "Invalid email format");
};
