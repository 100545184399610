import React, { useContext } from "react";
import axios from "axios";
import { getToken } from "./authService";
import { appState } from "../../context/AuthContext";
import { hideLoader, showLoader } from "../helpers/loader";
import { NotificationManager } from "react-notifications";
import JWT from "jwt-decode";
import swal from "sweetalert";
import ReducerAction from "../../context/ReducerAction";
import AppHelpers from "../../methods/AppHelper";

const token = getToken();
const AUTHORIZATION = "authorization";

export let timoutReq = 60000;

export let baseUrl = process.env.REACT_APP_BASE_URL;
if (process.env.REACT_APP_NODE_ENV === "development") {
  baseUrl = "http://localhost:8080";
}

export const httpPost = async (url, postBody) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  try {
    const res = await axios.post(`${baseUrl}/api/${url}`, postBody, {
      timeout: timoutReq,
      headers: { Authorization: `Bearer ${localStorage.api_token}` },
    });
    return res.data;
  } catch (err) {
    hideLoader();
    if (err.message) {
      if (err.message === `timeout of ${timoutReq}ms exceeded`) {
        hideLoader();
        return { er: err.message };
      }
    }
    if (err.response) {
      return { er: err.response.data };
    }
  }
};

export const httpPostData = async (url, postBody) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  try {
    showLoader();
    let userToken = await getToken();

    const res = await axios.post(`${baseUrl}/api${url}`, postBody, {
      timeout: timoutReq,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (err) {
    hideLoader();
    if (err.message) {
      if (err.message === `timeout of ${timoutReq}ms exceeded`) {
        hideLoader();
        return { er: err.message };
      }
    }
    if (err.response) {
      return { er: err.response.data };
    }
  }
};

export const httpGet = async (url) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  try {
    let userToken = await getToken();
    const res = await axios.get(`${baseUrl}/api/${url}`, {
      // timeout: 6,
      headers: { Authorization: `Bearer ${userToken}` },
    });
    // console.log(res);
    return res.data;
  } catch (error) {
    // hideLoader();
    return error;
  }
};

export const httpPut = async (url, postBody) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  try {
    const res = await axios.put(`${baseUrl}/api/${url}`, postBody, {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    });
    return res.data;
  } catch (error) {
    hideLoader();
    return error;
  }
};

export const httpPatch = async (url, postBody) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  try {
    const res = await axios.patch(`${baseUrl}/api/${url}`, postBody, {
      headers: { Authorization: `Bearer ${localStorage.api_token}` },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const httpDelete = async (url, postBody) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  try {
    const res = await axios.delete(`${baseUrl}/api/${url}`, {
      headers: { Authorization: `Bearer ${localStorage.api_token}` },
    });
    return res;
  } catch (error) {
    hideLoader();
    return error;
  }
};

export const axiosCalls = async (path, method, data = null, load = null) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  if (!load) {
    showLoader();
  }
  try {
    let userToken = await getToken();
    let res = await axios({
      method,
      url: `${baseUrl}/api${path}`,
      data,
      timeout: timoutReq,
      headers: { Authorization: `Bearer ${userToken}` },
    });
    if (res) {
      hideLoader();
      return res.data;
    }
  } catch (err) {
    hideLoader();

    if (err.message) {
      if (err.message === `timeout of ${timoutReq}ms exceeded`) {
        return { er: err.message };
      }
    }

    if (err?.response?.data) {
      if (
        err?.response?.data?.message === "session expired" ||
        err?.response?.data?.message ===
          "Unauthorized, Your token is invalid or expired"
      ) {
        appState.modals = ReducerAction.modals.expiredToken;
        return AppHelpers.updateAppState();
      }
      return { er: err.response.data };
    }
  }
};

export const axiosFormData = async (
  path,
  method,
  fd,
  load = null,
  setMethod = null
) => {
  if (!navigator.onLine) {
    return NotificationManager.error(
      "Please check your internet",
      "Opps!",
      3000
    );
  }
  if (!load) {
    showLoader();
  }
  let newTimer = path === "/create_edition" ? 120000 : timoutReq;
  try {
    let userToken = await getToken();
    let decodedToken = JWT(userToken);
    let currentDate = new Date();

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return { er: { message: "session expired" } };
    }

    let res = await axios({
      method: `${method}`,
      url: `${baseUrl}/api${path}`,
      data: fd,
      timeout: newTimer,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        if (setMethod) {
          setMethod(Math.round((100 * data.loaded) / data.total));
          console.log(
            "upload data",
            Math.round((100 * data.loaded) / data.total)
          );
        }
      },
    });

    if (res) {
      hideLoader();
      return res.data;
    }
  } catch (err) {
    hideLoader();

    if (err.message) {
      if (err.message === `timeout of ${timoutReq}ms exceeded`) {
        return { er: err.message };
      }
      return;
    }

    if (err?.response?.data) {
      if (
        err?.response?.data?.message === "session expired" ||
        err?.response?.data?.message ===
          "Unauthorized, Your token is invalid or expired"
      ) {
        appState.modals = ReducerAction.modals.expiredToken;
        return AppHelpers.updateAppState();
      }
    }
    return { er: err.response.data };
  }
};
