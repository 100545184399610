import React, { useState, useEffect, useContext } from "react";
import icons from "./icons";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import moment from "moment";
import "./table.css";
import more from "../../assets/More.svg";
// import truncate from "../../utils/truncate";

export default function index(props) {
  const { applications, setPnStatus, setData } = props;

  return (
    <div className="table-wrap border styleTable">
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        icons={icons}
        options={{
          exportButton: true,
        }}
        columns={[
          { title: "Ticket number", field: "id" },
          { title: "Notice Type", field: "category" },
          { title: "Name", field: "name" },
          { title: "Status", field: "status" },
          { title: "Date Submitted", field: "createdAt" },
          { title: "More", field: "upload" },
        ]}
        data={applications.map((data) => {
          return {
            id: data?.id,
            name: (
              <span>{`${data.user?.firstName} ${data.user?.lastName}`}</span>
            ),

            category: <span className="w-100">{data?.category}</span>,
            status: (
              <span
                style={{
                  background:
                    data.status.toLowerCase() === "paid"
                      ? "#FFECD8"
                      : "rgb(187 249 176)",
                  color:
                    data.status.toLowerCase() === "paid"
                      ? "rgb(241 79 17)"
                      : "rgb(47 218 92)",
                  textTransform: "capitalize",
                  padding: "4px 8px",
                }}
              >
                {data?.status === "paid" ? "Scheduled" : "Published"}
              </span>
            ),
            createdAt: moment(data?.createdAt).format("MMMM Do YYYY"),
            upload: (
              <span className="d-flex align-items-center ">
                <img
                  src={more}
                  alt=""
                  onClick={() => {
                    setData(data);
                    setPnStatus(data?.category);
                  }}
                />
              </span>
            ),
          };
        })}
        title="Public Notice"
      />
    </div>
  );
}
