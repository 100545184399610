import React from "react";
import ChangeOfNameTable from "./changeOfNameRequestTable";
export default function changeOfNameRequest({applications,setgetDocsPreview,toggleCONDocsModal,history}) {
  return (
    <div>
      <ChangeOfNameTable applications={applications} 
      setgetDocsPreview={setgetDocsPreview} 
      toggleCONDocsModal={toggleCONDocsModal} 
      history={history}/>
    </div>
  );
}
