import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { toggleSidebar } from '../../globalState/localData';
import { httpGet, axiosCalls } from '../../components/helpers/httpMethods';
import './layout.css';
import { PublicationContext } from '../../context/PublicationContext';
import { DashboardContext } from '../../context/DashboardContext';
import { AuthContext } from '../../context/AuthContext';
import {
  getLocalItem,
  setLocalItem,
  setToken,
  removeItem,
} from '../../components/helpers/authService';
import { NotificationManager } from 'react-notifications';
import {
  Dash,
  Accademy,
  Publication,
  Analytics,
  Subscribers,
  Review,
  Comments,
  Sales,
  Settings,
  Dropdown,
  Archives,
} from '../../assets/icons/svgs';
import ReactTooltip from 'react-tooltip';
import style from 'styled-components';
import Collapsible from 'react-collapsible';
import { Scrollbars } from 'react-custom-scrollbars';

export default function Sidebar(props) {
  const {
    pubReload,
    currentPublication,
    setPubReload,
    setReload,
    reload,
    setCurrentPublication,
  } = useContext(PublicationContext);
  const { auth, setAuth, loading, changeLoading } = useContext(AuthContext);
  const { renderOpt, setRenderOpt } = useContext(DashboardContext);
  let [sidebarState, setSidebarState] = useRecoilState(toggleSidebar);
  const [activeRoute, SetActiveRoute] = useState('naira');
  const [current, setCurrent] = useState({});
  const [publications, setPublications] = useState([]);
  const [pubVisible, setPubVisible] = useState(false);
  const [abbrName, setAbbrName] = useState('');

  const toolTip = useRef(null);

  let currentRoute = props.currentRoute;
  // console.log(props);

  useEffect(() => {
    getCurrentPub();
    getAllPub();
  }, [pubReload]);

  const getCurrentPub = async () => {
    let companyData = await getLocalItem('company');
    if (companyData) {
      let id = JSON.parse(companyData).currentPublication;
      setCurrentPublication(id);
      if (id) {
        let res = await httpGet(`publication/${id}`);
        if (res) {
          if (!res.success) {
            return;
          }

          setCurrent(res.data.publication);
          let str = res.data.publication.name;
          var key;
          let arr = [];
          for (key in str.split(' ')) {
            arr.push(str.split(' ')[key][0]);
          }
          setAbbrName(arr.join(''));
          setReload(!reload);
        }
      }
    }
  };

  console.log(current);

  const getAllPub = async () => {
    let res = await httpGet(`my_publications`);
    if (res) {
      if (!res.success) {
        return;
      }
      setPublications(res.data.publications);
    }
  };

  const changeCurrentPub = async (id) => {
    let companyData = await getLocalItem('company');
    let res = await axiosCalls(`/dashboard_publication/${id}`, 'PATCH');
    if (res) {
      if (res.er) {
        return NotificationManager.error(res.er.message);
      }
      setToken(res.data);
      setCurrentPublication(id);
      setLocalItem('company', {
        ...JSON.parse(companyData),
        currentPublication: id,
      });
      setPubReload(!pubReload);
      setPubVisible(false);
    }
  };

  return (
    <SidebarWrapper>
      <div
        className={`responsiveSideBar ${
          sidebarState.openSidebar === true
            ? 'responsiveSideBarOpen'
            : sidebarState.defaultSidebarApiState
        }`}
      >
        <div className='sidebar-wrap'>
          <div className='sidebar-wrap--container'>
            <div className='sidebar--body'>
              <div className='sidebar--body--content'>
                <Scrollbars
                  style={{ flex: 1 }}
                  autoHide
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                >
                  <div className='sidebar--header'>
                    {/* <Scrollbars
                                    style={{ height: '150px' }}
                                    autoHide
                                    autoHideTimeout={1000}
                                    autoHideDuration={200}
                                > */}
                    <Collapsible
                      trigger='PUBLICATIONS'
                      open={true}
                      triggerStyle={{
                        color: '#fff',
                        fontFamily: "'Poppins', sans-serif",
                        letterSpacing: '1px',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        padding: '0 20px',
                        cursor: 'pointer',
                      }}
                    >
                      <div className='header-items'>
                        {publications.map((el, i) => {
                          return (
                            <div
                              className={`header-items-container ${
                                el.id === currentPublication
                                  ? 'link-active'
                                  : null
                              }`}
                              onClick={() => changeCurrentPub(el.id)}
                            >
                              <div className='header-items--abbr'>
                                <h2>{el.name.charAt(0).toUpperCase()}</h2>
                              </div>
                              <div className='header-items--body'>
                                <h2>{el.name}</h2>
                              </div>
                            </div>
                          );
                        })}

                        <div
                          className='header-items-container'
                          onClick={() => {
                            setRenderOpt('create');
                            props.browserRouter(`/home`);
                            setPubVisible(false);
                          }}
                        >
                          <div className='add-new'>
                            <h2>+</h2>
                          </div>
                          <div className='header-items--body neww'>
                            <h2>New Publication</h2>
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                    {/* </Scrollbars> */}
                  </div>
                  <div className='mt-3'>
                    <Collapsible
                      trigger='MANAGE'
                      open={true}
                      // triggerWhenOpen="open"
                      triggerStyle={{
                        color: '#fff',
                        fontFamily: "'Poppins', sans-serif",
                        letterSpacing: '1px',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        padding: '0 20px',
                        cursor: 'pointer',
                      }}
                    >
                      <div className='body-items'>
                        <div
                          className={`body-items-container ${
                            currentRoute === '/home' ? 'link-active' : null
                          }`}
                          onClick={() => {
                            props.browserRouter(`/home`);
                            setRenderOpt('normal');
                          }}
                        >
                          <div className='body-items--abbr'>
                            <Dash
                              color={currentRoute === '/home' ? '#fff' : null}
                            />
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/home' ? 'link-active2' : null
                              }
                            >
                              Dashboard
                            </h2>
                          </div>
                        </div>

                        <div
                          className={`body-items-container ${
                            currentRoute === '/publications'
                              ? 'link-active'
                              : null
                          }`}
                          onClick={() => props.browserRouter(`/publications`)}
                        >
                          <div className='body-items--abbr'>
                            <Publication
                              color={
                                currentRoute === '/publications' ? '#fff' : null
                              }
                            />
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/publications'
                                  ? 'link-active2'
                                  : null
                              }
                            >
                              Editions
                            </h2>
                          </div>
                        </div>

                        <div
                          className={`body-items-container ${
                            currentRoute === '/transactions'
                              ? 'link-active'
                              : null
                          }`}
                          onClick={() => props.browserRouter(`/transactions`)}
                        >
                          <div className='body-items--abbr'>
                            <Publication
                              color={
                                currentRoute === '/transactions' ? '#fff' : null
                              }
                            />
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/transactions'
                                  ? 'link-active2'
                                  : null
                              }
                            >
                              Transactions
                            </h2>
                          </div>
                        </div>
                        {current?.type?.name !== 'books' && (
                          <>
                            <div
                              className={`body-items-container ${
                                currentRoute === '/change_of_name'
                                  ? 'link-active'
                                  : null
                              }`}
                              ref={toolTip}
                              data-tip=''
                              onClick={() =>
                                props.browserRouter(`/change_of_name`)
                              }
                            >
                              <div className='body-items--abbr'>
                                <Publication
                                  color={
                                    currentRoute === '/change_of_name'
                                      ? '#fff'
                                      : null
                                  }
                                />
                              </div>
                              <div className='body-items--body'>
                                <h2
                                  className={
                                    currentRoute === '/change_of_name'
                                      ? 'link-active2'
                                      : null
                                  }
                                >
                                  Change Of Name
                                </h2>
                              </div>
                              <ReactTooltip />
                            </div>
                            <div
                              className={`body-items-container ${
                                currentRoute === '/public_notice'
                                  ? 'link-active'
                                  : null
                              }`}
                              ref={toolTip}
                              data-tip=''
                              onClick={() =>
                                props.browserRouter(`/public_notice`)
                              }
                            >
                              <div className='body-items--abbr'>
                                <Publication
                                  color={
                                    currentRoute === '/public_notice'
                                      ? '#fff'
                                      : null
                                  }
                                />
                              </div>
                              <div className='body-items--body'>
                                <h2
                                  className={
                                    currentRoute === '/public_notice'
                                      ? 'link-active2'
                                      : null
                                  }
                                >
                                  Public Notice
                                </h2>
                              </div>
                              <ReactTooltip />
                            </div>
                            <div
                              className={`body-items-container ${
                                currentRoute === '/archive'
                                  ? 'link-active'
                                  : null
                              }`}
                              ref={toolTip}
                              data-tip=''
                              onClick={() => props.browserRouter(`/archive`)}
                            >
                              <div className='body-items--abbr'>
                                <Archives
                                  color={
                                    currentRoute === '/archive' ? '#fff' : null
                                  }
                                />
                              </div>
                              <div className='body-items--body'>
                                <h2
                                  className={
                                    currentRoute === '/archive'
                                      ? 'link-active2'
                                      : null
                                  }
                                >
                                  Archives
                                </h2>
                              </div>
                              <ReactTooltip />
                            </div>
                          </>
                        )}

                        <div
                          className={`body-items-container ${
                            currentRoute === '/subscribers'
                              ? 'link-active'
                              : null
                          }`}
                          onClick={() => props.browserRouter(`/subscribers`)}
                        >
                          <div className='body-items--abbr'>
                            <Subscribers
                              color={
                                currentRoute === '/subscribers' ? '#fff' : null
                              }
                            />
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/subscribers'
                                  ? 'link-active2'
                                  : null
                              }
                            >
                              Subscribers
                            </h2>
                          </div>
                        </div>

                        <div
                          className={`body-items-container ${
                            currentRoute === '/comments' ? 'link-active' : null
                          }`}
                          ref={toolTip}
                          data-tip='Coming Soon'
                        >
                          <div className='body-items--abbr'>
                            <Comments
                              color={
                                currentRoute === '/comments' ? '#fff' : null
                              }
                            />
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/comments'
                                  ? 'link-active2'
                                  : null
                              }
                            >
                              Reviews
                            </h2>
                          </div>
                          <ReactTooltip />
                        </div>

                        <div
                          className={`body-items-container ${
                            currentRoute === '/academy' ? 'link-active' : null
                          }`}
                          ref={toolTip}
                          data-tip='Coming Soon'
                        >
                          <div className='body-items--abbr'>
                            <Accademy
                              color={
                                currentRoute === '/academy' ? '#fff' : null
                              }
                            />
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/academy'
                                  ? 'link-active2'
                                  : null
                              }
                            >
                              Vendor Academy
                            </h2>
                          </div>
                          <ReactTooltip />
                        </div>

                        {/* <div className={`body-items-container ${currentRoute === "/archive" ? "link-active" : null}`} onClick={() => props.browserRouter(`/archive`)}>
                          <div className="body-items--abbr">
                            <Archive color={currentRoute === "/archive" ? "#fff" : null} />
                          </div>
                          <div className="body-items--body">
                            <h2 className={currentRoute === "/archive" ? "link-active2" : null}>Archive</h2>
                          </div>
                        </div> */}
                      </div>
                    </Collapsible>
                  </div>

                  <div className='mt-3'>
                    <Collapsible
                      trigger='ACCOUNT'
                      open={true}
                      triggerStyle={{
                        color: '#fff',
                        fontFamily: "'Poppins', sans-serif",
                        letterSpacing: '1px',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        padding: '0 20px',
                        cursor: 'pointer',
                      }}
                    >
                      <div className='body-items'>
                        <div
                          className={`body-items-container ${
                            currentRoute === '/settings' ? 'link-active' : null
                          }`}
                          onClick={() => props.browserRouter(`/settings`)}
                        >
                          <div className='body-items--abbr'>
                            <Settings
                              color={
                                currentRoute === '/settings' ? '#fff' : null
                              }
                            />
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/settings'
                                  ? 'link-active2'
                                  : null
                              }
                            >
                              Settings
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div
                        className='body-items'
                        style={{ marginTop: '-10px' }}
                      >
                        <div
                          className={`body-items-container `}
                          onClick={async () => {
                            await removeItem('user');
                            setAuth(false);
                            props.browserRouter(`/`);
                          }}
                        >
                          <div className='body-items--abbr'>
                            <i
                              class='fa fa-sign-out'
                              style={{ color: '#fff', fontSize: '15px' }}
                              aria-hidden='true'
                            ></i>
                          </div>
                          <div className='body-items--body'>
                            <h2
                              className={
                                currentRoute === '/settings'
                                  ? 'link-active2'
                                  : null
                              }
                            >
                              Logout
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarWrapper>
  );
}

const SidebarWrapper = style.div`
    .sidebar-wrap--container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .sidebar--header {
            margin-top: 20px;
            overflow: hidden !important;
            padding-bottom: 20px;
            border-bottom: 1px dashed #fff;
            .header-items {
                
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px;
                cursor: pointer;

                .header-items-container {
                    padding: 6px 20px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 6px;
                    cursor: pointer;

                    &:hover {
                        background: rgba(0, 0, 0, 0.2);
                    }

                    .add-new {
                        padding: 6px;
                        width: 15%;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                        

                        h2 {
                            color: #fff;
                            margin: 0;
                            letter-spacing: 1px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600 !important;
                            font-size: 25px;
                        }
                    }
                    .neww {
                        h2 {
                            color: #fff;
                            margin: 0;
                            letter-spacing: 1px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600 !important;
                            font-size: 15px;
                        }
                    }
                }

                .header-items--abbr {
                    background: #fff;
                    padding: 6px;
                    width: 15%;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;

                    h2 {
                        color: #000;
                        margin: 0;
                        letter-spacing: 1px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400 !important;
                        font-size: 13px;
                    }
                }

                .header-items--body {
                    h2 {
                        color: #fff;
                        margin: 0;
                        text-transform: capitalize;
                        letter-spacing: 1px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400 !important;
                        font-size: 15px;
                    }
                }
            }
        }

        .sidebar--body {
            // padding-top: 15px;
            flex: 1; 
            display: flex;
            flex-direction:  column;
            justify-content: space-between;

            .sidebar--body--content {
                flex: 1;
                margin-bottom: 10px;
                overflow: hidden !important;
            }


            .body-items {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px;
                cursor: pointer;
                

                .body-items-container {
                    padding: 6px 20px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 6px;
                    cursor: pointer;

                    &:hover {
                        background: rgba(0, 0, 0, 0.2);
                    }

                    .body-items--abbr {
                        // background: #fff;
                        padding: 6px;
                        width: 15%;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
    
                        h2 {
                            color: #000;
                            margin: 0;
                            letter-spacing: 1px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400 !important;
                            font-size: 13px;
                        }
                    }
    
                    .body-items--body {
                        h2 {
                            color: #fff;
                            margin: 0;
                            text-transform: capitalize;
                            letter-spacing: 1px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400 !important;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    .link-active {
        background: rgba(0, 0, 0, 0.2);

        .body-items--body {
            h2 {
                color: #fff;
                margin: 0;
                text-transform: capitalize;
                letter-spacing: 1px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600 !important;
                font-size: 16px;
            }
        }
    }

    
`;
