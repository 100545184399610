import React, { useState } from 'react';
import './index.css';
import { Container, SizedBox, H1, H3 } from '../../lib/main';
import Breadcrum from '../../components/shared/breadcrum';
import PublicationTable from '../../components/tables/publications';
import style from 'styled-components';
import AppColors from '../../helpers/_colors';
import { Scrollbars } from 'react-custom-scrollbars';
import { Add } from '../../assets/icons/svgs';
import { PubWrapper } from './css/pubCss';
import { axiosCalls } from '../../components/helpers/httpMethods';

const Home = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [pdf, setPdf] = useState('');

  const createDigitalEdition = async () => {
    let res = await axiosCalls(`/create_digital_edition`, 'POST');
    if (res) {
      if (res.er) {
        console.log(res.er);
        return;
      }
      console.log('new edition', res.data);
      props.history.push(`/publications/articles/${res.data.id}`);
    }
  };

  return (
    // <>
    <PubWrapper>
      <Scrollbars
        style={{ flex: 1 }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <Breadcrum hide="ir" title="Editions" nav={props.history} />
        <Container className="pub--btn-container">
          <Container
            onClick={() => props.history.push('/publications/upload_pdf')}
            className="Pub--btn"
            background={pdf === 'pdf' ? AppColors.mainColor : null}
          >
            <Add />
            <h2>Upload PDF</h2>
          </Container>
          <Container
            onClick={() => createDigitalEdition()}
            className="Pub--btn"
            background={pdf === 'pdf' ? AppColors.mainColor : null}
          >
            <Add />
            <div>
              <h2>Digital edition</h2>
            </div>
          </Container>
          {/* <Container onClick={() => setPdf('template')} className="Pub--btn" background={pdf === 'template' ? AppColors.mainColor : null}>
                    <H3 fontSize='1vw' color={pdf === 'template' ? '#fff' : null} fontWeight="bold">Use Template</H3>
                    
                </Container> */}
        </Container>
        <div className="pub-table-wrapper">
          <PublicationTable nav={props.history} />
        </div>
      </Scrollbars>
    </PubWrapper>
    // </>
  );
};

export default Home;
