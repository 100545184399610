import { httpGet } from "../../components/helpers/httpMethods";
import { getLocalItem } from "../../components/helpers/authService";

export const getAllPubEditions = async () => {
  let company = await getLocalItem("company");
  let data = await httpGet(
    `editions?type=publication&id=${JSON.parse(company)?.currentPublication}`
  );
  // console.log("these are the editions", data.data.editions);
  if (data) {
    if (data.response) {
      return { er: data.response };
    }
    return data.data;
  }
};
