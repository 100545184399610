import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import style from "styled-components";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
// import AppLogo from '../../assets/images/appLogo.png';
import { home } from '../../assets/icons/svgs';
import {
    httpPost,
    axiosCalls
} from '../../components/helpers/httpMethods';
import { ValidateEmail, ValidateInput } from '../../components/helpers/validateInput';
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import {
    setToken,
    setLocalItem,
    getLocalItem,
} from "../../components/helpers/authService";

import { AuthContext } from '../../context/AuthContext';
import swal from 'sweetalert';

const ResetPassword = (props) => {

    const id = props.match.params.id;

    const { setAuth, setUserToken, setOnBording } = useContext(AuthContext);

    const [userAccount, setUserAccount] = useState({
        password: "",
    });

    const [setValidatedForm, setValidated] = useState(false);

    const [errorInput, setErrorInput] = useState({
        email: "",
        password: "",
    });


    const handleChange = (e) => {
        if (e.target.name === "email") {
            setUserAccount({
                ...userAccount,
                [e.target.name]: e.target.value,
            });
            let testValid = ValidateEmail(e.target.value);
            setErrorInput({ ...errorInput, email: testValid });
        }

        if (e.target.name === "password") {
            setUserAccount({
                ...userAccount,
                [e.target.name]: e.target.value,
            });
            let testValid = ValidateInput(e.target.value);
            setErrorInput({ ...errorInput, password: testValid });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (errorInput.password === "Valid") {
            const data = {
                password: userAccount.password,
            };
            let res = await axiosCalls(`/auth/user/reset_password/${id}`, 'POST', data);
            if (res) {
                if (res.er) {
                    console.log("there is an error", res.er.message);
                    NotificationManager.error(res.er.message);
                    return;
                }

                swal('Successful', 'Password reset successful, you can now login', 'success').then(() => {
                    window.location.href('/');
                })
            }
        }
        else {
            NotificationManager.info("Please enter your new password");

        }
    };
    return <>
        <LoginWrapper className="login">
            {/* <div>
                <img src={AppLogo} style={{ display: 'block', marginRight: 'auto', width: '50%' }} />
            </div> */}

            <Container>
                <div className="d-flex justify-content-center mb-5">
                    {home}
                </div>
                {/* <SizedBox height='4vh' /> */}
                <div style={{ background: '#fff', padding: '10px 40px', borderRadius: '10px', width: '100%' }}>
                    <h1 className="my-3" style={{ textAlign: 'center', fontSize: '20px', color: '#002333', fontFamily: 'semiBold' }}>Password Reset</h1>
                    {/* <div className="mt-5" style={{ width: '20vw', margin: '0 auto' }}>
                        <h3 className="text-center" style={{ letterSpacing: '1px', lineHeight: '14px' }}>Enter your email, and we’ll send you instructions on how to reset your password.</h3>
                    </div> */}
                    <div className=" d-flex flex-column mt-5" style={{ width: '20vw' }}>
                        <Form style={{ display: 'flex', flexDirection: 'column', }}>
                            <FormGroup>
                                <Input type="password" value={userAccount.password} onChange={handleChange} name="password" placeholder="Enter new password" />
                                <span
                                    style={errorInput.password === "Valid" ? { color: "green" } : {}}
                                    className="AppError"
                                >
                                    {errorInput.password}
                                </span>
                            </FormGroup>

                        </Form>
                        <SizedBox height="1vh" />
                        <Button color="#EE7F0D" onClick={handleSubmit} style={{ background: '#EE7F0D', color: '#000', width: '100%', borderRadius: '10px', height: '37px' }} className="d-block" width="100vw">
                            <h2 style={{ fontSize: '15px', margin: 0, color: '#fff' }}>Submit</h2>
                        </Button>
                        <SizedBox height="1vh" />
                        <div className="" style={{ width: '20vw', margin: '0 auto' }}>
                            <h3 className="text-center" style={{ letterSpacing: '1px', lineHeight: '14px' }}>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</h3>
                        </div>
                        <SizedBox height="2vh" />
                        <div className="d-flex p-3" style={{ width: '20vw', margin: '0 auto', justifyContent: 'space-between' }}>
                            <h3 className="login--remember" onClick={() => props.history.push('/')} style={{ cursor: 'pointer' }}>Back to Login</h3>
                            {/* <SizedBox width="1.3vw" /> */}
                            <h3 className="login--remember" >
                                <Link className="login--remember" to='/'>Don't have an account?</Link>
                            </h3>
                        </div>
                    </div>
                </div>
            </Container>
        </LoginWrapper>
    </>
}

export default ResetPassword;

const SizedBox = (props) => {
    return <div style={{
        height: props.height,
        width: props.width,
    }}></div>
}

const Container = (props) => {
    return <div style={{
        background: props.background,
        width: props.width,
        height: props.height,
        border: props.border,
        borderRadius: props.borderRadius,
        padding: props.padding,
        padding: `${props.paddingVertical} 0`,

    }, props.style}>
        {props.children}
    </div>
}

const LoginWrapper = style.div`
    display: flex;
    background: rgba(238, 127, 13, 0.14);
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

   
    h3 {
        font-size: 10px !important;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: #666666;
    }

    Form Input {
        background: #EFF0F6;
        border-radius: 5px;
        width: 20vw;
        height: 50px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: #A0A3BD;
        border: none;
        ::placeholder {
            // color: red;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            color: #A0A3BD;
        }
    }

    .login--remember {
        &:hover {
            color: #EE7F0D;
        }
    }


    @media only screen and (max-width: 500px) {
       

        overflow-y: scroll;
    }
    @media only screen and (min-width: 500px) {
        // border: 3px solid blue;
        h1 {
            font-size: 1.2vw;
        }
        h3 {
            font-size: 1vw;
        }
    }
`;