import React, { useState, useContext, useEffect } from "react";
import { Container, SizedBox, H1, H3 } from "../../lib/main";
import arrRight from "../../assets/icons/arrRight.png";
import AppColors from "../../helpers/_colors";
import "./breadcrum.css";
import { SettingsContext } from "../../context/SettingsContext";
import style from "styled-components";

const Breadcrum = (props) => {
  const { activeState, setActiveState } = useContext(SettingsContext);
  const { activeState2, setActiveState2 } = useContext(SettingsContext);

  useEffect(() => {
    console.log("my props", props);
    return () => {};
  }, []);

  return (
    <BreadcrumWrapper>
      <Container className="breadcrum--wrapper">
        <Container className="breadcrum--prev" flex={1} display="flex" direction="row" alignItems="center" cursor="pointer" onClick={() => props.nav.goBack()}>
          <Container>
            <img src={arrRight} className="img-fluid" width="20vw" />
          </Container>
          <SizedBox width="1.2vw" />
          <h2>Back to Previous</h2>
        </Container>
        <Container flex={1} className="breadcrum--title-box">
          <h2>{props.title}</h2>
        </Container>
        {!props.nav && <Container flex={1}></Container>}
        {props.hide && <Container flex={1}></Container>}
        {!props.hide && props.nav && !props.general && (
          <Container className="breadcrum-nav">
            {/* <div className={activeState === 'details' ? 'active' : null} onClick={() => setActiveState('details')}>
                <h2>Details</h2>
            </div>
            <div className={activeState === 'roles' ? 'active' : null} onClick={() => setActiveState('roles')}>
                <h2>Roles</h2>
            </div>
            
             */}
            <div className={activeState === "payout" ? "active" : null} onClick={() => setActiveState("payout")}>
              <h2>Publication Details</h2>
            </div>
            <div className={activeState === "preference" ? "active" : null} onClick={() => setActiveState("preference")}>
              <h2>Publication Price</h2>
            </div>
            {/* <div className={activeState === 'more' ? 'active' : null} onClick={() => setActiveState('more')}>
                <h2>More Details</h2>
            </div> */}
          </Container>
        )}
        {props.nav && props.general && (
          <Container className="breadcrum-nav">
            <div className={activeState2 === "roles" ? "active" : null} onClick={() => setActiveState2("roles")}>
              <h2>Roles</h2>
            </div>
            <div className={activeState2 === "details" ? "active" : null} onClick={() => setActiveState2("details")}>
              <h2>Details</h2>
            </div>

            <div className={activeState2 === "payout" ? "active" : null} onClick={() => setActiveState2("payout")}>
              <h2>Payout Account</h2>
            </div>
            {/* <div className={activeState2 === 'more' ? 'active' : null} onClick={() => setActiveState2('more')}>
                <h2>More Details</h2>
            </div> */}
          </Container>
        )}
      </Container>
    </BreadcrumWrapper>
  );
};

export default Breadcrum;

const BreadcrumWrapper = style.div`
    // border: 1px solid red;
    margin: 20px 0;
    .breadcrum--wrapper {
        background: #fff;
        width: 100%;
        display: flex;

        .breadcrum--prev {
            h2 {
                font-size: 16px;
                margin: 0;
                color: #66738F;
            }
        }

        .breadcrum--title-box {
            display: flex;
            align-items: center;
            h2 {
                font-size: 16px;
                margin: 0;
                color: #66738F;
            }
        }
    }
`;
