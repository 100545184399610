import React, { createContext, useState, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import { getLocalItem, getToken } from '../components/helpers/authService';
import AppReducer from './Reducer';

export const AuthContext = createContext();
export let dispatcher; //for dispatching action outside of the store
export let appState;

export const AuthContextProvider = (props) => {
  const initiatialState = {
    loader: null,
    modals: null,
    stateUpdate: Math.random(),
    notifications: [],
  };

  const [state, dispatch] = useReducer(AppReducer, initiatialState);

  const [loading, setLoading] = useState(true);
  const [currentState, setCurrentState] = useState('');
  const [auth, setAuth] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [onBording, setOnBording] = useState(false);
  const [currentPublication, setCurrentPublication] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const history = useHistory();

  appState = state;
  dispatcher = dispatch;

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  console.log(userToken);

  const checkIfLoggedIn = async () => {
    let user = await getLocalItem('user');
    if (user) {
      let token = await getToken();
      let company = await getLocalItem('company');
      if (company) {
        console.log('dsds', JSON.parse(company).onBoardCount);

        if (JSON.parse(company).onBoardCount) {
          setOnBording(false);
        } else {
          setOnBording(true);
        }
      } else {
        // alert('no')
      }
      setUserToken(token);
      setAuth(true);
    }
    // history.push("/");
    // setAuth(false);
  };

  const changeCurrentState = (val) => {
    setCurrentState(val);
  };

  const changeLoading = (val) => {
    setLoading(val);
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        currentState,
        loading,
        auth,
        userToken,
        onBording,
        currentPublication,
        setCurrentPublication,
        setOnBording,
        setUserToken,
        setAuth,
        changeCurrentState,
        changeLoading,
        checkIfLoggedIn,
        dropdown,
        setDropdown,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
