export const Reload = () => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.85714 0C5.49317 0 4.18507 0.541835 3.22059 1.50631C2.25612 2.47078 1.71429 3.77889 1.71429 5.14286H0L2.22286 7.36572L2.26286 7.44571L4.57143 5.14286H2.85714C2.85714 2.93143 4.64571 1.14286 6.85714 1.14286C9.06857 1.14286 10.8571 2.93143 10.8571 5.14286C10.8571 7.35429 9.06857 9.14286 6.85714 9.14286C5.75429 9.14286 4.75429 8.69143 4.03429 7.96571L3.22286 8.77714C3.69909 9.25601 4.26542 9.63585 4.88918 9.89477C5.51294 10.1537 6.18178 10.2866 6.85714 10.2857C8.22111 10.2857 9.52922 9.74388 10.4937 8.77941C11.4582 7.81493 12 6.50683 12 5.14286C12 3.77889 11.4582 2.47078 10.4937 1.50631C9.52922 0.541835 8.22111 2.03247e-08 6.85714 0ZM6.28571 2.85714V5.71429L8.71429 7.15429L9.15429 6.41714L7.14286 5.22286V2.85714H6.28571Z" fill="white" />
  </svg>
);

export const ReloadCol = () => (
  <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2857 0C11.4441 0 8.71889 1.12882 6.70957 3.13814C4.70025 5.14746 3.57143 7.87268 3.57143 10.7143H0L4.63095 15.3452L4.71429 15.5119L9.52381 10.7143H5.95238C5.95238 6.10714 9.67857 2.38095 14.2857 2.38095C18.8929 2.38095 22.619 6.10714 22.619 10.7143C22.619 15.3214 18.8929 19.0476 14.2857 19.0476C11.9881 19.0476 9.90476 18.1071 8.40476 16.5952L6.71429 18.2857C7.70645 19.2833 8.88629 20.0747 10.1858 20.6141C11.4853 21.1535 12.8787 21.4303 14.2857 21.4286C17.1273 21.4286 19.8525 20.2997 21.8619 18.2904C23.8712 16.2811 25 13.5559 25 10.7143C25 7.87268 23.8712 5.14746 21.8619 3.13814C19.8525 1.12882 17.1273 4.23432e-08 14.2857 0ZM13.0952 5.95238V11.9048L18.1548 14.9048L19.0714 13.369L14.881 10.881V5.95238H13.0952Z" fill="black" />
  </svg>
);
