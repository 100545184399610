import React, { useState, useEffect, useContext } from "react";
// import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import "./login.css";
import {
  ValidateInput,
  ValidateEmail,
} from "../../components/helpers/validateInput";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { httpPost } from "../../components/helpers/httpMethods";
import { hideLoader, showLoader } from "../../components/helpers/loader";
import { setLocalItem } from "../../components/helpers/authService";
import { AppLogo } from "../../assets/svg";
import Logo from "../../assets/images/appLogo.png";
import swal from "sweetalert";
import AuthFunctions from "../../methods/AuthFunctions";
import { AuthContext } from "../../context/AuthContext";

export default function Login(props) {
  const { auth, loading, changeLoading } = useContext(AuthContext);

  useEffect(() => {
    if (auth) {
      props.history.push("/home");
      return changeLoading(false);
    }
    console.log(props);
  }, []);

  const { setAuth, setUserToken, setOnBording } = useContext(AuthContext);

  const [userAccount, setUserAccount] = useState({
    email: "",
    password: "",
  });

  const [setValidatedForm, setValidated] = useState(false);

  const [errorInput, setErrorInput] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateEmail(e.target.value);
      setErrorInput({ ...errorInput, email: testValid });
    }

    if (e.target.name === "password") {
      setUserAccount({
        ...userAccount,
        [e.target.name]: e.target.value,
      });
      let testValid = ValidateInput(e.target.value);
      setErrorInput({ ...errorInput, password: testValid });
    }
  };

  const [showPass, setShowPass] = useState(false);

  const toggleSeePasswordIcon = () => {
    setShowPass(!showPass);
  };

  return (
    <div className="login-wrap-v2">
      <div className="login-wrap-v2-col1">
        <AppLogo />
      </div>

      <div className="login-wrap-v2-col2">
        <div className="signUpOptonLogin">
          <p>
            Don’t have an account?{" "}
            <span>
              {" "}
              <Link to="/register">Sign up</Link>
            </span>
          </p>
        </div>

        <div className="mobileViewLogoV2">
          <img src={Logo} alt="" />
        </div>

        <div className="login-wrapv2-main">
          <h1 className="login-txt-header">Login</h1>
          <form>
            <div className="inputWrap-v2">
              <label>Email address</label>
              <input
                style={{ padding: "25px" }}
                type="text"
                placeholder="Enter email"
                name="email"
                onChange={handleChange}
                value={userAccount.email}
                required
              />
              <span
                style={{
                  color: errorInput.email === "Valid" ? "green" : "red",
                  fontStyle: "italic",
                  marginTop: "5px",
                }}
              >
                {errorInput.email}
              </span>
            </div>

            <div className="inputWrap-v2">
              <label>Password</label>
              <input
                style={{ padding: "25px" }}
                type="password"
                name="password"
                onChange={handleChange}
                placeholder="Enter password"
                value={userAccount.password}
                required
              />
              <span
                style={{
                  color: errorInput.password === "Valid" ? "green" : "red",
                  fontStyle: "italic",
                  marginTop: "5px",
                }}
              >
                {errorInput.password}
              </span>
            </div>

            <div className="forgotPasswordOptionv2">
              <p>
                {" "}
                <Link to="/reset">forgot password?</Link>
              </p>
            </div>

            <div className="loginBtnV2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  AuthFunctions.loginUser(
                    userAccount,
                    setOnBording,
                    setAuth,
                    props.history
                  );
                }}
              >
                Sign In
              </button>
            </div>

            <div className="haveanAccountV2Op">
              <p>
                {" "}
                New to TNS?
                <Link to="/register"> SIGN UP</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
